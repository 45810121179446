import React, { useState, useEffect, useContext } from "react";

import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,
  Input,
  Row,
  FormFeedback,
} from "reactstrap"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import { UserContext } from "contexts/user-context/user-context";

import * as Yup from "yup";
import { useFormik } from "formik";

const TwostepVerification = () => {

  //meta title
  document.title = "Two Step Verification | Evolue";

  const {
    getUser,
    userInfo,
    confirmEmail,
    resendEmailValidation
  } = useContext(UserContext);

  const [isLoadingBtn, setIsLoadingBtn] = useState(false)

  useEffect(() => {
    getUser()
  }, [])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      code: '',
    },
    validationSchema: Yup.object({
      code: Yup.string().required("Insira o código de verificação")
    }),
    onSubmit: (values) => {
      setIsLoadingBtn(true)
      confirmEmail(values.code)
        .then(
          setIsLoadingBtn(false)
        )
      setIsLoadingBtn(false)
    }
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mb-5 text-muted">
                <Link to="dashboard" className="d-block auth-logo">
                  <img
                    src={logodark}
                    alt=""
                    height="20"
                    className="auth-logo-dark mx-auto"
                  />
                  <img
                    src={logolight}
                    alt=""
                    height="20"
                    className="auth-logo-light mx-auto"
                  />
                </Link>
                <p className="mt-3">Investir nunca foi tão fácil</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Verifique seu e-mail</h4>
                        <p className="mb-5">
                          Insira o código enviado para{" "}
                          <span className="fw-semibold">
                            {userInfo?.email}
                          </span>
                        </p>

                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mb-3">
                            <Input
                              name="code"
                              className="form-control"
                              placeholder="Insira o código recebido"
                              type="code"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.code || ""}
                              invalid={
                                validation.touched.code && validation.errors.code ? true : false
                              }
                            />
                            {validation.touched.code && validation.errors.code ? (
                              <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className={`btn btn-primary btn-block ${isLoadingBtn ? 'loadingBtn' : ''}`}
                              type="submit"
                            >
                              {isLoadingBtn ? 'Confirmando' : 'Confirmar'}
                            </button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Não recebeu o código?{" "}
                  <Link className="fw-medium text-primary"
                    onClick={() => resendEmailValidation()}>
                    {" "}
                    Reenviar{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Evolue
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default TwostepVerification;

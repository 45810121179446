import React, { useContext, useEffect, useState } from "react";
import { Col, Card, CardBody, Table } from "reactstrap";
import ReactApexChart from "react-apexcharts";

//redux
import { useSelector, useDispatch } from "react-redux";

//actions
import { getTopSellingProduct } from "../../../../store/actions";
import { formatarNumeroComUnidades } from "helpers/formatNumbers";
import { AssetsContext } from "contexts/assets-context/assets-context";

const getChartOptions = index => {
  var options = {
    chart: { sparkline: { enabled: !0 } },
    dataLabels: { enabled: !1 },
    colors: ["#556ee6"],
    plotOptions: {
      radialBar: {
        hollow: { margin: 0, size: "60%" },
        track: { margin: 0 },
        dataLabels: { show: !1 },
      },
    },
  };
  switch (index) {
    case 1:
      options["colors"][0] = "#556ee6";
      break;
    case 2:
      options["colors"][0] = "#34c38f";
      break;
    case 3:
      options["colors"][0] = "#f46a6a";
      break;
    default:
      break;
  }

  return options;
};

const AcoesEmitidas = ({ shares }) => {

  const { assetInfo, tickerType } = useContext(AssetsContext);
  const [sellingData, setSellingData] = useState(null)

  useEffect(() => {
    if (tickerType === 'acoes') {
      setSellingData(
        [
          {
            name: `Total de Papeis`,
            desc: `${shares.numero_papeis_total}`,
            total: shares.total_free_float,
            value: shares.porcentagem_free_float_total
          },
          {
            name: `Ordinárias (ON)`,
            desc: `${shares.numero_papeis_on}`,
            total: shares.free_float_on,
            value: shares.porcentagem_free_float_on
          },
          {
            name: `Preferenciais (PN)`,
            desc: `${shares.numero_papeis_pn}`,
            total: shares.free_float_pn,
            value: shares.porcentagem_free_float_pn
          }
        ]
      )
    };
    if (assetInfo && tickerType === 'fiis') {
      setSellingData(
        [
          {
            name: `Total de Cotas`,
            desc: `${assetInfo.numero_papeis}`,
            total: null,
            value: null
          },
          {
            name: `Cotistas`,
            desc: `${assetInfo.cotistas}`,
            total: null,
            value: null
          }
        ]
      )
    }
  }, [assetInfo, tickerType])

  return (
    <React.Fragment>
      <Col >
        <Card>
          <CardBody>
            <div className="clearfix">

              <h4 className="card-title mb-4">{tickerType === 'acoes' ? 'Ações Emitidas' : 'Cotas Emitidas'}</h4>
              <p className="text-muted mb-0">{ }</p>
            </div>
            <div className="table-responsive mt-4">
              <Table className="table align-middle mb-0">
                <tbody>
                  {tickerType === 'acoes' && sellingData && sellingData
                    .filter(data => data.total !== 0 && data.value !== 0 && data.total !== null && data.value !== null && data.total !== undefined && data.value !== undefined)
                    .map((data, key) => {
                      const options = getChartOptions(key + 1);
                      return (
                        <tr key={key}>
                          <td>
                            <h5 className="font-size-14 mb-1">{data.name}</h5>
                            <p className="text-muted mb-0">{data.desc.replace(/(\d)(?=(\d{3})+$)/g, '$1.')}</p>
                            <p className="text-muted mb-0">{formatarNumeroComUnidades(data.desc)}</p>
                          </td>

                          <td>
                            <div id={`radialchart-${key + 1}`}>
                              <ReactApexChart
                                options={options}
                                series={[Number(data.value)]}
                                type="radialBar"
                                height={60}
                                width={60}
                                className="apex-charts"
                              />
                            </div>
                          </td>
                          <td>
                            <p className="text-muted mb-1">Free Float</p>
                            <h5 className="mb-0">{data.value} %</h5>
                          </td>
                        </tr>
                      );
                    })}
                    {tickerType === 'fiis' && sellingData && sellingData.map((data, key) => {
                      const options = getChartOptions(key + 1);
                      return (
                        <tr key={key}>
                          <td>
                            <h5 className="font-size-14 mb-1">{data.name}</h5>
                            <p className="text-muted mb-0">{data.desc.replace(/(\d)(?=(\d{3})+$)/g, '$1.')}</p>
                            <p className="text-muted mb-0">{formatarNumeroComUnidades(data.desc)}</p>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default AcoesEmitidas;

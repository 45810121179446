import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row, Label, Input, Form, Collapse, Container, Table } from 'reactstrap';
import { Link } from 'react-router-dom';

import { AssetsContext } from 'contexts/assets-context/assets-context';
import { formatCurrency } from 'helpers/formatCurrency';
import { PaginationContext } from 'contexts/pagination-context/pagination-context';

import { LayoutContext } from 'contexts/layout-context/layout-contex';


const AssetsData = () => {

    const {
        setTickerType,
        setSelectedTicker,
        setActiveTab,
        getAllAssetsByType,
        assets,
        assetFilter, setAssetFilter
    } = useContext(AssetsContext)

    const { pagina, setPagina } = useContext(PaginationContext)

    const [totalPaginas, setTotalPaginas] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [isTable, setIsTable] = useState(true)
    const [order, setOrder] = useState('asc')
    const [paramToRank, setParamToRank] = useState('')

    const toggleOrder = () => {
        if (order === 'desc') {
            setOrder('asc');
        } else {
            setOrder('desc');
        }
    };

    useEffect(() => {
        setOrder('asc');
    }, [paramToRank]);

    const mockParams = {
        country: "br",
        exchange: "bovespa",
        classe: "acoes",
        route: "assets"
    }

    useEffect(() => {
        getAllAssetsByType(order, (pagina - 1 || 0), paramToRank, assetFilter);
    }, [pagina, assetFilter, paramToRank, order]);

    useEffect(() => {
        if (assets) {
            setTotalPaginas(assets.paginas + 1);
            setTotalItems(assets.total);
        }
    }, [assets]);


    const { isMobile } = useContext(LayoutContext)

    const [logoClass, setLogoClass] = useState('avatar-sm rounded')
    const [fontSize, setFontSize] = useState('font-size-14')
    const [str, setStr] = useState(18)

    useEffect(() => {
        if (isMobile) {
            setLogoClass('avatar-xs rounded');
            setFontSize('font-size-12');
            setStr(9)
        } else {
            setLogoClass('avatar-sm rounded');
            setFontSize('font-size-14');
            setStr(18)
        }
    }, [isMobile])

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <Form>
                                <Row className="d-flex justify-content-end">
                                    <Col lg={8}>
                                        <div className="position-relative h-100 hstack gap-3">
                                            {/* <div onClick={() => { setIsTable(!isTable) }} className="btn btn-success h-100 w-100">
                                                <i className="bx bx-filter-alt align-middle"></i>{' '}
                                                {isTable ? 'Formato de Card' : 'Formato de Tabela'}
                                            </div> */}
                                            <div
                                                onClick={() => { setAssetFilter('ACAO') }}
                                                className={`btn btn-${assetFilter === 'ACAO' ? 'primary' : 'secondary'} h-100 w-100`}
                                            >
                                                <i className="bx bx-filter-alt align-middle"></i>{' '}
                                                Ações
                                            </div>
                                            <div
                                                onClick={() => { setAssetFilter('FII') }}
                                                className={`btn btn-${assetFilter === 'FII' ? 'primary' : 'secondary'} h-100 w-100`}
                                            >
                                                <i className="bx bx-filter-alt align-middle"></i>{' '}
                                                Fundos Imobiliários
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>


            <Row>
                {!isTable && assets && assets.ativos.map((item, key) => (
                    <Col xl={3} md={6} key={key} className="mb-3">
                        <Card className="mb-0" style={{ minHeight: '100%' }}>
                            <CardBody>
                                <div className="d-flex align-start mb-2">
                                    <div className="d-flex align-items-center flex-grow-1">
                                        <img src={item.image} alt="" className="avatar-xs rounded mb-2 me-2" />
                                        {" "}
                                        <div>
                                            <h5 className="fs-17 mb-0 ">
                                                <Link
                                                    onClick={() => {
                                                        setActiveTab('Resumo')
                                                        setSelectedTicker(item.symbol.toLowerCase());
                                                        setTickerType(item.classe && item.classe === 'ACAO' ? 'acoes' : 'fiis');
                                                    }}
                                                    to={`/${mockParams.route}/${mockParams.country}/${item.exchange ? item.exchange.toLowerCase() : mockParams.exchange}/${item.classe && item.classe === 'ACAO' ? 'acoes' : 'fiis'}/${item.symbol.toLowerCase()}`}
                                                    className="text-dark">
                                                    {item.symbol}
                                                </Link>
                                            </h5>
                                            <h6 className='mt-0 mb-2'>
                                                {formatCurrency(Number(item.cotacao), 'pt-BR', "BRL")}
                                            </h6>
                                        </div>
                                    </div>
                                    <div>
                                        <button type="button" className="btn btn-light btn-sm like-btn" /* onClick={(e) => activeBtn(e.target)} */>
                                            <i className="bx bx-heart"></i>
                                        </button>
                                    </div>

                                </div>
                                <h6 className="fs-17 mb-2" style={{ maxWidth: '95%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    {item.nome_longo}
                                </h6>
                                <ul className="list-inline mb-0">
                                    {/* <li className="list-item">
                                        <p className="text-muted fs-14 mb-1">{item.nome_longo}</p>
                                    </li>{" "} */}
                                    <li className="list-item">
                                        <p className="text-muted fs-14 mb-0"><i className="mdi mdi-map-marker"></i> {item.exchange}</p>
                                    </li>
                                    {/* <li className="list-item">
                                        <p className="text-muted fs-14 mb-0"><i className="uil uil-wallet"></i> $250 - $800 / month</p>
                                    </li> */}
                                </ul>
                                <div className="mt-3 hstack gap-2">
                                    <span className={`badge rounded-1 badge-soft-${Number(item.dividend_yeld) > 6 ? 'success' : 'danger'}`}>
                                        Yield
                                    </span>
                                    <span className="badge rounded-1 badge-soft-warning">Preço Justo</span>
                                    <span className="badge rounded-1 badge-soft-danger">Preço Teto</span>
                                </div>
                                <div className="d-flex align-start justify-content-between mt-3 hstack gap-2">
                                    <Link
                                        className="btn btn-soft-primary "
                                        onClick={() => {
                                            setActiveTab('Resumo')
                                            setSelectedTicker(item.symbol.toLowerCase());
                                            setTickerType(item.classe && item.classe === 'ACAO' ? 'acoes' : 'fiis');
                                        }}
                                        to={`/${mockParams.route}/${mockParams.country}/${item.exchange ? item.exchange.toLowerCase() : mockParams.exchange}/${item.classe && item.classe === 'ACAO' ? 'acoes' : 'fiis'}/${item.symbol.toLowerCase()}`}
                                    >
                                        Detalhes
                                    </Link>
                                    {/* <Link 
                                    to="#applyJobs" 
                                    onClick={() => setModal(true)} 
                                    className="btn btn-primary">
                                        <i className="bx bx-plus align-middle"></i> Registro
                                    </Link> */}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                ))}

                {isTable && assets &&
                    <React.Fragment>
                        <Container fluid>
                            <Row>
                                <Col lg="12">
                                    <div>
                                        <div className="table-responsive table-scroll">

                                            <Table className={`project-list-table table-nowrap align-middle table-sticky ${fontSize}`}>
                                                <thead className='head-sticky'>
                                                    <tr>
                                                        <th scope="col">
                                                            Papel
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className='user-select-none'
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                toggleOrder(),
                                                                    setParamToRank('')
                                                            }}
                                                        >
                                                            Ticker {' '}
                                                            {paramToRank === '' && <i className={`font-size-14 ${order === 'asc' ?
                                                                'bx bx-down-arrow-alt' :
                                                                'bx bx-up-arrow-alt'
                                                                }`}>
                                                            </i>}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className='user-select-none'
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                toggleOrder(),
                                                                    setParamToRank('cotacao')
                                                            }}
                                                        >
                                                            Cotação {' '}
                                                            {paramToRank === 'cotacao' && <i className={`font-size-14 ${order === 'asc' ?
                                                                'bx bx-down-arrow-alt' :
                                                                'bx bx-up-arrow-alt'
                                                                }`}>
                                                            </i>}
                                                        </th>

                                                        {assetFilter === 'ACAO' &&
                                                            <th
                                                                scope="col"
                                                                className='user-select-none'
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    toggleOrder(),
                                                                        setParamToRank('preco_teto_metodo_graham')
                                                                }}
                                                            >
                                                                Preço Justo{' '}
                                                                {paramToRank === 'preco_teto_metodo_graham' && <i className={`font-size-14 ${order === 'asc' ?
                                                                    'bx bx-down-arrow-alt' :
                                                                    'bx bx-up-arrow-alt'
                                                                    }`}>
                                                                </i>}
                                                            </th>}

                                                        <th
                                                            scope="col"
                                                            className='user-select-none'
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                toggleOrder(),
                                                                    setParamToRank('preco_teto')
                                                            }}
                                                        >
                                                            Preço Teto {' '}
                                                            {paramToRank === 'preco_teto' && <i className={`font-size-14 ${order === 'asc' ?
                                                                'bx bx-down-arrow-alt' :
                                                                'bx bx-up-arrow-alt'
                                                                }`}>
                                                            </i>}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className='user-select-none'
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                toggleOrder(),
                                                                    setParamToRank('yeld_medio')
                                                            }}
                                                        >
                                                            Yeld Médio{' '}
                                                            {paramToRank === 'yeld_medio' && <i className={`font-size-14 ${order === 'asc' ?
                                                                'bx bx-down-arrow-alt' :
                                                                'bx bx-up-arrow-alt'
                                                                }`}>
                                                            </i>}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className='user-select-none'
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                toggleOrder(),
                                                                    setParamToRank('dividend_yeld')
                                                            }}
                                                        >
                                                            Dividend Yield {' '}
                                                            {paramToRank === 'dividend_yeld' && <i className={`font-size-14 ${order === 'asc' ?
                                                                'bx bx-down-arrow-alt' :
                                                                'bx bx-up-arrow-alt'
                                                                }`}>
                                                            </i>}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className='user-select-none'
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                toggleOrder(),
                                                                    setParamToRank('preco_valor_patrimonial_acao')
                                                            }}
                                                        >
                                                            P/VP{' '}
                                                            {paramToRank === 'preco_valor_patrimonial_acao' && <i className={`font-size-14 ${order === 'asc' ?
                                                                'bx bx-down-arrow-alt' :
                                                                'bx bx-up-arrow-alt'
                                                                }`}>
                                                            </i>}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {assets && assets.ativos.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <Link
                                                                    onClick={() => {
                                                                        setActiveTab('Resumo')
                                                                        setSelectedTicker(item.symbol.toLowerCase());
                                                                        setTickerType(item.classe && item.classe === 'ACAO' ? 'acoes' : 'fiis');
                                                                    }}
                                                                    to={`/${mockParams.route}/${mockParams.country}/${item.exchange ? item.exchange.toLowerCase() : mockParams.exchange}/${item.classe && item.classe === 'ACAO' ? 'acoes' : 'fiis'}/${item.symbol.toLowerCase()}`}
                                                                    className="text-dark">
                                                                    <img
                                                                        src={item?.image}
                                                                        alt=""
                                                                        className={logoClass}
                                                                    />
                                                                </Link>
                                                            </td>
                                                            <td name="nome-simbolo">
                                                                <Link
                                                                    onClick={() => {
                                                                        setActiveTab('Resumo')
                                                                        setSelectedTicker(item.symbol.toLowerCase());
                                                                        setTickerType(item.classe && item.classe === 'ACAO' ? 'acoes' : 'fiis');
                                                                    }}
                                                                    to={`/${mockParams.route}/${mockParams.country}/${item.exchange ? item.exchange.toLowerCase() : mockParams.exchange}/${item.classe && item.classe === 'ACAO' ? 'acoes' : 'fiis'}/${item.symbol.toLowerCase()}`}
                                                                    className="text-dark">
                                                                    <h5 className={`text-truncate mb-1 ${fontSize}`}>
                                                                        {item?.symbol}
                                                                    </h5>
                                                                    <p className={`text-muted mb-0`}>
                                                                        {item && item.nome_longo &&
                                                                            item.nome_longo.substring(0, str - 3) +
                                                                            (item.nome_longo.length >= str ? ' ...' : '')
                                                                        }
                                                                    </p>
                                                                </Link>
                                                            </td>
                                                            <td name="cotacao">
                                                                <p className="text-muted mb-0">
                                                                    {item?.cotacao && formatCurrency(Number(item.cotacao), 'pt-BR', 'BRL')}
                                                                </p>
                                                            </td>
                                                            {assetFilter === 'ACAO' &&
                                                                <td name="preco_teto_metodo_graham">
                                                                    <p className="text-muted mb-0">
                                                                        {item?.preco_teto_metodo_graham && formatCurrency(Number(item.preco_teto_metodo_graham), 'pt-BR', 'BRL') || '--'}
                                                                    </p>
                                                                </td>
                                                            }
                                                            <td name="preco-teto">
                                                                <p className="text-muted mb-0">
                                                                    {item?.preco_teto && formatCurrency(Number(item.preco_teto), 'pt-BR', 'BRL') || '--'}
                                                                </p>
                                                            </td>
                                                            <td name="yield-medio">
                                                                <p className="text-muted mb-0">
                                                                    {item?.yeld_medio && Number(item.yeld_medio).toFixed(2) + '%'}
                                                                </p>
                                                            </td>
                                                            <td name="dividend-yield">
                                                                <p className="text-muted mb-0">
                                                                    {item?.dividend_yeld && Number(item.dividend_yeld).toFixed(2) + '%'}
                                                                </p>
                                                            </td>
                                                            <td name="p-vp">
                                                                <p className="text-muted mb-0">
                                                                    {item?.preco_valor_patrimonial_acao && Number(item.preco_valor_patrimonial_acao).toFixed(2)}
                                                                </p>
                                                            </td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>

                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col xs="12">
                                    <div className="text-center my-3">
                                        <Link to="#" className="text-success">
                                            <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                            Load more
                                        </Link>
                                    </div>
                                </Col>
                            </Row> */}
                        </Container>
                    </React.Fragment >
                }

            </Row>

            <Row className="justify-content-between align-items-center mt-3 mb-3">
                <div className="col-auto me-auto">
                    <p className="text-muted mb-0">{/* Mostrando <b>1</b> to <b>50</b> of */} <b>{totalItems}</b> registros</p>
                </div>
                <div className="col-auto">
                    <Card className="d-inline-block ms-auto mb-0">
                        <CardBody className="p-2">
                            <nav aria-label="Page navigation example" className="mb-0">
                                <ul className="pagination mb-0">
                                    <li className="page-item">
                                        <Link className={`page-link ${pagina === 1 ? 'disabled' : ''}`} to="#" onClick={() => {
                                            if (pagina > 1) {  // Verifica se não está na página 1
                                                setPagina(pagina - 1);
                                            }
                                        }}>
                                            <span aria-hidden="true">&laquo;</span>
                                        </Link>
                                    </li>
                                    {Array.from({ length: totalPaginas }, (_, index) => (
                                        <li key={index} className={`page-item ${pagina === index + 1 ? 'active' : ''}`}>
                                            <Link className="page-link" to="#" onClick={() => {
                                                if (pagina !== index + 1) {  // Verifica se não está na página atual
                                                    setPagina(index + 1);
                                                }
                                            }}>
                                                {index + 1}
                                            </Link>
                                        </li>
                                    ))}
                                    <li className="page-item">
                                        <Link className={`page-link ${pagina === totalPaginas ? 'disabled' : ''}`} to="#" onClick={() => {
                                            if (pagina < totalPaginas) {  // Verifica se não está na última página
                                                setPagina(pagina + 1);
                                            }
                                        }}>
                                            <span aria-hidden="true">&raquo;</span>
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </React.Fragment>
    );
};

export default AssetsData;
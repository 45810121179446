import { formatCurrency } from "helpers/formatCurrency";
import React, { useState, createContext, useEffect } from "react";
import api from '../../services/api';
import toast from "helpers/toast";

export const OptionContext = createContext();

const OptionProvider = ({ children }) => {

    const [opcoes, setOpcoes] = useState(null);
    const [assetType, setAssetType] = useState('opcoes');
    const [selectedAsset, setSelectedAsset] = useState('');
    const [extract, setExtract] = useState(null);
    const [heroInformation, setHeroInformation] = useState({
        notional: 0,
        premios: 0,
        rentabilidade: 0,
    })

    const getOpcoes = async (page) => {
        try {
            const response = await api.get(`/opcoes`); //await api.get(`/opcoes${page ? `?pagina=${page}` : '?pagina=0'}`);

            setExtract(response.data);

        } catch (err) {
            /* toast.warn("Não foi possíel recuperar os monstros da base de dados" + err.message); */
            console.error('erro aos buscar opções' + err)
        }
    };

    const registerOpcao = async (values, method) => {
        const requestMethod = method ? method : 'post';
        try {
            const response = await api[requestMethod](`/opcoes${requestMethod === 'put' ? `?id=${values.id}` : ''}`, values);
            requestMethod === 'post' ? toast.success('Registro efetuado') : toast.success('Alteração efetuada');
            getOpcoes();
            selectOption();

            return { success: true, data: response.data };

        } catch (err) {
            toast.fail(`Erro no registro: ${err}`)
            return { success: false, error: err.message };
        }
    };

    //MODO CORRETO DE CHAMAR OPCOES
    //TODO:
    //MUDAR PARA O GETOPCOES

    const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0;
    }

    const selectOption = async (tipo) => {

        const typeOperation = tipo ? `?Tipo=${tipo.toUpperCase()}` : '?Tipo=PUT'
        try {
            const response = await api.get(`/opcoes/extrato${typeOperation}`);

            if (isEmptyObject(response.data)) {
                const currentYear = new Date().getFullYear()
                setOpcoes({
                    [currentYear]: {}
                }/* null */);
            } else {
                setOpcoes(response.data);
            }


        } catch (err) {
            /* toast.warn("Não foi possíel recuperar os monstros da base de dados" + err.message); */
            console.error('erro aos buscar opções' + err)
        }
    };

    //DELETE

    const deleteRegisterById = async (assetType, id) => {
        try {
            await api.delete(`/${assetType}?id=${id}`) // opcoes | fiis

            getOpcoes(),
                selectOption();
            toast.success(`Registro excluído com sucesso`)

        } catch {
            toast.fail(`Erro ao deletar registro: ${err}`)
        }
    }

    return (
        <OptionContext.Provider value={{
            opcoes,
            setOpcoes,
            getOpcoes,
            registerOpcao,
            selectOption,
            selectedAsset,
            extract,
            setSelectedAsset,
            assetType,
            setAssetType,
            deleteRegisterById,
            heroInformation, setHeroInformation
        }}>
            {children}
        </OptionContext.Provider>
    );
};

export default OptionProvider;
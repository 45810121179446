import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { handleBrazilStringDate } from "helpers/formatDateTime";
import {
    Col,
    Row,
    Table,
    Modal,
    ModalBody,
    Badge
} from "reactstrap";
import { formatCurrency } from "helpers/formatCurrency";
import { TaxesContext } from "contexts/imposto-context/imposto-context";
import { formatData, formatISODateTime } from "helpers/formatDateTime";

export const NonDetailed = ({ pendences, fontSize }) => {

    if (!pendences || (typeof pendences === 'object' && Object.keys(pendences).length === 0) || pendences?.length) {
        return <div>
            <p>Não há impostos devidos</p>
        </div>
    }

    const {
        putTax,
        heroInformation,
        setHeroInformation
    } = useContext(TaxesContext);

    const [years, setYears] = useState([]);
    const [dates, setDates] = useState([]);
    const [selectedYear, setSelectedYear] = useState(pendences && Object.keys(pendences) ? Object.keys(pendences)[0] : null);
    const [selectedDate, setSelectedDate] = useState(pendences && pendences[selectedYear] ? Object.keys(pendences[selectedYear])[0] : null);
    const [impostosPendentes, setImpostosPendentes] = useState(pendences ? pendences[selectedYear]?.[selectedDate]?.impostos : null)

    const [toPayId, setToPayId] = useState(null);
    const [showPayModal, setShowPayModal] = useState(false);
    const [isPaying, setIsPaying] = useState(false);

    const togglePayModal = () => {
        setShowPayModal(!showPayModal);
    };

    const handlePutTax = (id) => {
        putTax(id)
    }

    const tableItens = {
        sigla: "Registro",
        negociacao: "Operação",
        resultado: "Resumo",
        custos: "Custos",
        impost_retido: "Imp. Retido",
        acao: "Situação"
    }

    const extractDatesByYear = (year) => {
        if (pendences && pendences[year]) {
            const dates = Object.keys(pendences[year]);
            setDates(dates);
            setSelectedDate(Object.keys(pendences[selectedYear])[0])
        }
    };

    useEffect(() => {
        if (pendences) {
            const years = Object.keys(pendences);
            setYears(years);
            setSelectedYear(years[0])

            const dates = Object.keys(pendences[years[0]])
            setDates(dates);
            setImpostosPendentes(pendences[years[0]]?.[dates[0]]?.impostos);

            const updatedHeroInformation = {
                ...heroInformation,
                totalImposto: pendences[years[0]]?.[dates[0]]?.totalImposto || 0,
                totalLucro: pendences[years[0]]?.[dates[0]]?.totalLucro || 0,
            };

            setHeroInformation(updatedHeroInformation);
        }
    }, [pendences]);


    useEffect(() => {
        if (selectedDate && selectedYear) {
            setImpostosPendentes(pendences[selectedYear]?.[selectedDate]?.impostos);

            const updatedHeroInformation = {
                ...heroInformation,
                totalImposto: pendences[selectedYear]?.[selectedDate]?.totalImposto || 0,
                totalLucro: pendences[selectedYear]?.[selectedDate]?.totalLucro || 0,
            };

            setHeroInformation(updatedHeroInformation);
        }
    }, [selectedYear, selectedDate])

    useEffect(() => {
        if (selectedYear) {
            extractDatesByYear(selectedYear)
        }
    }, [pendences, selectedYear])

    const icons = {
        pago: <Badge className="bg-success font-size-10">Pago</Badge>,
        pendentePgto: <Badge className="bg-warning font-size-10" style={{ color: '#000' }}>Pagamento Pendente</Badge>,
        pendenteComp: <Badge className="bg-success font-size-10">Compensar</Badge>,
        compensado: <Badge className="bg-success font-size-10" style={{ color: '#000' }}>Compensado</Badge>,
        prejuizo_compensado: <Badge className="bg-danger font-size-10" style={{ color: '#000' }}>Prejuízo compensado</Badge>,
        compensacao: <Badge className="bg-info font-size-10" style={{ color: '#000' }}>Compensação</Badge>,
        isento: <Badge className="bg-dark font-size-10">Isento</Badge>,
        atrasado: <Badge className="bg-danger font-size-10">Pendente</Badge>,
        dayTrade: <Badge className="bg-info font-size-10">Day Trade</Badge>,
        swingTrade: <Badge className="bg-warning font-size-10">Swing Trade</Badge>,
        exercicioOpcao: <Badge className="bg-dark font-size-10">Exercício de Opção</Badge>,
        acao: <Badge className="bg-dark font-size-10">Ação</Badge>,
        fii: <Badge className="bg-dark font-size-10">Fii</Badge>,
        opcao: <Badge className="bg-dark font-size-10">Opção</Badge>,
    }

    return (
        <React.Fragment>
            <Col className='d-flex me-3'>
                {years?.map((year) => (

                    <button
                        key={year}
                        className={`btn ${selectedYear === year ? 'btn-secondary' : 'btn-primary'} btn-sm m-2`}
                        onClick={() => { setSelectedYear(year) }}
                    >{year}</button>

                ))}
            </Col>

            <Col className='d-flex me-3'>
                {dates?.map((date) => (

                    <button
                        key={date}
                        className={`btn ${selectedDate === date ? 'btn-secondary' : 'btn-warning'} btn-sm m-2`}
                        onClick={() => { setSelectedDate(date) }}
                    >{handleBrazilStringDate(String(date)).replace(/-/g, "/")}</button>

                ))}
            </Col>

            {impostosPendentes && impostosPendentes.length > 0 ?
                <Row>
                    <Col lg="12">
                        <div>
                            <div className="table-responsive">
                                <Table className={`project-list-table table-nowrap align-middle table-sticky ${fontSize}`}>
                                    <thead>
                                        <tr>
                                            {Object.entries(tableItens).map(([key, item]) => (
                                                <th key={key} className="text-start">{item}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {impostosPendentes && impostosPendentes.map((item) => (
                                            <tr key={item.id}>
                                                <td scope="row">
                                                    <div className="text-muted">
                                                        <span>{item.sigla}</span>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            {item.tipoOperacao === "SWING_TRADE" && icons.swingTrade}
                                                            {item.tipoOperacao === "DAY_TRADE" && icons.swingTrade}
                                                            {item.tipoOperacao === "OPCAO" && icons.swingTrade}
                                                        </div>
                                                    </div>
                                                    <div className="text-muted">
                                                        <span>{`${formatData(formatISODateTime(item.data)).data}`}</span>
                                                    </div>
                                                </td>
                                                <td scope="row">
                                                    <div className="font-size-14"> {`Quantia: `}
                                                        <span className="text-muted">{item.quantidade}</span>
                                                    </div>
                                                    <div className="font-size-14"> {`Preço Médio: `}
                                                        <span className="text-muted">{formatCurrency(Number(item.preco_medio_ir), 'pt-BR', 'BRL')}</span>
                                                    </div>
                                                    <div className="font-size-14"> {`Negociação: `}
                                                        <span className="text-muted">{formatCurrency(Number(item.preco_pago), 'pt-BR', 'BRL')}</span>
                                                    </div>
                                                </td>
                                                <td scope="row">
                                                    <div className="font-size-14"> {`Volume: `}
                                                        <span className="text-muted">{formatCurrency(Number(item.preco_pago * item.quantidade), 'pt-BR', 'BRL')}</span>
                                                    </div>
                                                    <div className="font-size-14"> {`Resultado: `}
                                                        <span className="text-muted">{formatCurrency(Number(item.lucro_prejuizo), 'pt-BR', 'BRL')}</span>
                                                    </div>
                                                </td>
                                                <td scope="row">
                                                    <div className="font-size-14">
                                                        {
                                                            formatCurrency(
                                                                Number(item.corretagem) +
                                                                Number(item.emolumentos) +
                                                                Number(item.tarifas), 'pt-BR', 'BRL')
                                                        }
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="font-size-14">
                                                        {formatCurrency(Number(item.impost_retido), 'pt-BR', 'BRL')}
                                                    </div>
                                                </td>
                                                <td scope="row">
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            {item.acao === "PENDENTE_PAGAMENTO" && icons.pendentePgto}
                                                            {item.acao === "COMPENSADO" && item.tipoOperacao === "COMPENSADO" ? (
                                                                icons.prejuizo_compensado
                                                            ) : item.acao === "COMPENSADO" && (
                                                                icons.compensado
                                                            )}
                                                            {item.acao === "PAGO" && icons.pago}
                                                            {item.acao === "ISENTO" && icons.isento}
                                                        </div>
                                                    </div>
                                                    {/*   {
                                                                                                    item.data_atualizacao && <div className="text-muted">
                                                                                                        <span>{`${formatData(item.data_atualizacao).data}`}</span>
                                                                                                    </div>
                                                                                                } */}
                                                </td>
                                                {/* Botões */}
                                                <td style={{ width: "120px" }}>
                                                    {item.acao.includes("PENDENTE_PAGAMENTO") ? (
                                                        <button
                                                            className="btn btn-success btn-sm w-xs"
                                                            onClick={() => {
                                                                setToPayId(item.id);
                                                                togglePayModal();
                                                            }}
                                                        >
                                                            Pagamento
                                                        </button>
                                                    ) : item.acao.includes("PAGO") ? (
                                                        <button
                                                            className="btn btn-primary btn-sm w-xs"
                                                            onClick={() => {
                                                            }}
                                                            disabled
                                                        >
                                                            Pago
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-secondary btn-sm w-xs"
                                                            onClick={() => {
                                                            }}
                                                            disabled
                                                        >
                                                            Compensado
                                                        </button>
                                                    )}

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                            </div>
                        </div>
                    </Col>
                </Row>
                :
                <React.Fragment>
                    <div className="text-center">
                        <Col lg="12">
                            <p className="mt-5">Não há impostos devidos à exibir.</p>
                            <div className="flex-shrink-0 mb-4">
                                <Link to={'/assets'} className="btn btn-primary btn-sm w-xs">Ver ativos</Link>
                            </div>
                        </Col>
                    </div>
                </React.Fragment>
            }

            <Modal size="sm" isOpen={showPayModal} toggle={togglePayModal} centered={true}>
                <div className="modal-content">
                    <ModalBody className="px-4 py-5 text-center">
                        {/* <button type="button" onClick={onDeleteClick} className="btn-close position-absolute end-0 top-0 m-3"></button> */}
                        <div className="avatar-sm mb-4 mx-auto">
                            <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                                <i className="mdi mdi-account-cash-outline"></i>
                            </div>
                        </div>
                        <p className="text-muted font-size-16 mb-4">Tem certeza que deseja alterar esse registro para pago?</p>

                        <div className="hstack gap-2 justify-content-center mb-0">
                            <Row>
                                <Col>
                                    <button type="button" className="btn btn-info">
                                        <Link to={'https://sicalc.receita.economia.gov.br/sicalc/rapido/contribuinte'} target="_blanc" className="text-dark">
                                            Gerar Darf na Receita
                                        </Link>
                                    </button>
                                </Col>
                                <Col>
                                    <button type="button" className="btn btn-success" onClick={() => {
                                        try {
                                            setIsPaying(true)
                                            handlePutTax(toPayId).then(() => {
                                                setIsPaying(false);
                                                getTaxes()
                                                getPendences()
                                                togglePayModal();
                                            }).catch((err) => {
                                                setIsPaying(false)
                                            })
                                        } catch (error) {
                                            setIsPaying(false);
                                        }
                                    }}>
                                        {isPaying ? (
                                            <span>Mudando <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i></span>
                                        ) : (
                                            <span>Mudar para Pago <i className="bx bx-delete"></i></span>
                                        )}

                                    </button>
                                </Col>
                            </Row>
                        </div>
                        <div className="mt-4 mb-0">
                            <Row>
                                <Col>
                                    <button type="button" className="btn btn-secondary" style={{ width: '100%' }} onClick={() => { setToPayId(null), togglePayModal() }}>Sair</button>
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment >
    )
}
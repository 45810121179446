import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "contexts/auth-context/auth-context";

const NoAuthRouteMiddleware = (props) => {

  const { token } = useContext(AuthContext)

  const location = useLocation();

  const authUnassessibleRoutes = ["/login", "/register"];
  const isAuthUnassessibleRoutes = authUnassessibleRoutes.includes(location.pathname);

  if (token && isAuthUnassessibleRoutes) {
    return <Navigate to="/" />;
  }

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
};

export default NoAuthRouteMiddleware;

import React, { useContext, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from "reactstrap";
import classnames from "classnames";
import StocksList from "./stocks-list";
import { WalletContext } from "contexts/wallet-context/wallet-context";
import CadastroModal from "components/Modals/cadastro-modal/cadastro-modal";

const WalletActivities = () => {

  const {
    assetType, setAssetType
  } = useContext(WalletContext)

  const [activeTab, setActiveTab] = useState(assetType);
  const [modal, setModal] = useState(false);

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);

      switch (tab) {
        case "acoes":
          setAssetType('acoes');
          break;

        case "fiis":
          setAssetType('fiis');
          break;

        case "renda-fixa":
          // Lógica para quando o tab for "Renda Fixa"
          break;

        case "Tesouro":
          // Lógica para quando o tab for "Tesouro"
          break;

        default:
          setAssetType('acoes');
          break;
      }
    }
  };

  return (
    <>
      <Card>
        <CardBody>
          <div className="d-flex align-items-center">
            <h5 className="mb-0 card-title flex-grow-1 ">Registros</h5>
            <div className="flex-shrink-0">
              <Link to="#!" onClick={() => setModal(true)} className="btn btn-primary me-1 ">{'+ Registro'}</Link>
              {/* <Link to="#!" className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
              <UncontrolledDropdown className="dropdown d-inline-block me-1 ">
                <DropdownToggle type="menu" className="btn btn-success" id="dropdownMenuButton1">
                  <i className="mdi mdi-dots-vertical"></i></DropdownToggle>
                <DropdownMenu>
                  <li><DropdownItem href="#">Action</DropdownItem></li>
                  <li><DropdownItem href="#">Another action</DropdownItem></li>
                  <li><DropdownItem href="#">Something else here</DropdownItem></li>
                </DropdownMenu>
              </UncontrolledDropdown> */}
            </div>
          </div>
          <Container fluid>
            <ul className="nav nav-tabs nav-tabs-custom navbar-expand-lg flex-nowrap nav-justified custom-scrollbar mt-2"
              style={{
                whiteSpace: "normal",
                overflowX: "auto",
                overflowY: "hidden"
              }}>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "acoes",
                  })}
                  onClick={() => {
                    toggleTab("acoes");
                  }}
                >
                  Ações
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "fiis",
                  })}
                  onClick={() => {
                    toggleTab("fiis");
                  }}
                >
                  Fundos Imobiliários
                </NavLink>
              </NavItem>
              {/* <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "renda-fixa",
                })}
                onClick={() => {
                  toggleTab("renda-fixa");
                }}
              >
                Renda Fixa
              </NavLink>
            </NavItem> */}
            </ul>
          </Container>
          <div className="mt-0">
            <StocksList param={assetType} />
          </div>
        </CardBody>
      </Card>
      {/* <StocksList /> */}

      <CadastroModal isOpen={modal} toggle={toggle} />

    </>
  );
};

export default WalletActivities;

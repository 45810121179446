import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import withRouter from "components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import { UserContext } from "contexts/user-context/user-context";
import PhoneInput from "react-phone-number-input";
import { LayoutContext } from "contexts/layout-context/layout-contex";
import avatar1 from "../../assets/images/evolue-logo-menor.png"
import { Link } from "react-router-dom";
import { formatDataBarra } from "helpers/formatDateTime";

const UserProfile = () => {

  //meta title
  document.title = "Perfil | Evolue";

  const { getUser, userInfo, updateUser, cancelSignature } = useContext(UserContext);
  const { isMobile } = useContext(LayoutContext)

  const [error, setError] = useState('')
  const [success, setSuccess] = useState('Sucess exemplo')
  const [isLoadingCadastro, setIsLoadingCadastro] = useState(false)

  const [settingsMenu, setSettingsMenu] = useState(false)

  useEffect(() => {
    getUser()
  }, [])

  const [show, setShow] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const toggleDeleteModal = () => {
    setShow(!show);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      nome: userInfo && userInfo.nome || '',
      sobrenome: userInfo && userInfo.sobrenome || '',
      email: userInfo && userInfo.email || '',
      telefoneNumber: userInfo && '+' + userInfo.codigo_pais + userInfo.ddd + userInfo.telefone || '',
      codigo_pais: userInfo && userInfo.codigo_pais || '',
      ddd: userInfo && userInfo.ddd || '',
      telefone: userInfo && userInfo.telefone || '',
      documento: userInfo && userInfo.documento || '',
      tipo_documento: "cpf",
      tipo: "PESSOA_FISICA",
      rua: userInfo && userInfo.rua || '',
      numero: userInfo && userInfo.numero || '',
      complemento: userInfo && userInfo.complemento || '',
      cidade: userInfo && userInfo.cidade || '',
      bairro: userInfo && userInfo.bairro || '',
      estado: userInfo && userInfo.estado || '',
      pais: userInfo && userInfo.pais || '',
      cep: userInfo && userInfo.cep || ''
    },
    validationSchema: Yup.object({
      nome: Yup.string().required('O campo nome é obrigatório'),
      sobrenome: Yup.string().required('O campo sobrenome é obrigatório'),
      email: Yup.string().required("Insira seu e-mail")
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Insira um endereço de e-mail válido"
        ),
      telefoneNumber: Yup.string().required('O campo telefone é obrigatório'),
      codigo_pais: Yup.string().required('O campo código do país é obrigatório'),
      ddd: Yup.string().required('O campo DDD é obrigatório'),
      telefone: Yup.string().required('O campo telefone é obrigatório'),
      documento: Yup.string().required('O campo documento é obrigatório'),
      tipo_documento: Yup.string().required('O campo tipo de documento é obrigatório'),
      tipo: Yup.string().required('O campo tipo é obrigatório'),
      rua: Yup.string().required('O campo rua é obrigatório'),
      numero: Yup.string().required('O campo número é obrigatório'),
      complemento: Yup.string(),
      cidade: Yup.string().required('O campo cidade é obrigatório'),
      bairro: Yup.string().required('O campo bairro é obrigatório'),
      estado: Yup.string().required('O campo estado é obrigatório'),
      pais: Yup.string().required('O campo país é obrigatório'),
      cep: Yup.string().required('O campo CEP é obrigatório'),
    }),
    onSubmit: (values) => {
      setIsLoadingCadastro(true)
      const editedValues = {
        nome: values.nome.trim(),
        sobrenome: values.sobrenome.trim(),
        email: values.email.trim(),
        codigo_pais: values.codigo_pais,
        ddd: values.ddd,
        telefone: values.telefone,
        documento: values.documento.replace(/\D/g, '').trim(),
        tipo_documento: "CPF",
        tipo: "PESSOA_FISICA",
        rua: values.rua.trim(),
        numero: values.numero.replace(/\D/g, '').trim(),
        complemento: values.complemento.trim(),
        cidade: values.cidade.trim(),
        bairro: values.bairro.trim(),
        estado: values.estado.toUpperCase().replace(/\d/g, ''),
        pais: values.pais.toUpperCase().replace(/\d/g, ''),
        cep: values.cep.trim().replace(/\D/g, '')
      }
      updateUser(editedValues)
        .then((result) => {
          setIsLoadingCadastro(false)
        })
        .catch((err) => {
          setIsLoadingCadastro(false)
          console.error('Erro inesperado:', err.message);
        });
    }
  });

  const areValuesEqual = (initialValues, values) => {
    return JSON.stringify(initialValues) === JSON.stringify(values);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumb title="Usuário" breadcrumbItem="Perfil" /> */}

          <Row>
            <Col lg="12">
              {/* {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null} */}

              <Card>
                <CardBody>
                  <Row>
                    <Col lg="4">
                      <div className="d-flex">
                        <div className="me-3">
                          <img
                            src={avatar1}
                            alt=""
                            className="avatar-sm rounded-circle img-thumbnail"
                          />
                        </div>
                        <div className="flex-grow-1 align-self-center">
                          <div className="text-muted">

                            <h5 className="mb-1">{userInfo?.nome}</h5>
                            <p className="mb-2">{userInfo?.email} {
                              userInfo?.email_check ?
                                <button type="button" className="btn btn-light position-relative p-0 avatar-xs rounded-circle">
                                  <span className="avatar-title bg-transparent text-reset">
                                    <i className="bx bxs-badge-check text-info"></i>
                                  </span>
                                </button>
                                :
                                <button type="button" className="btn btn-light position-relative p-0 avatar-xs rounded-circle">
                                  <Link to="/auth-two-step-verification">
                                    <span className="avatar-title bg-transparent text-reset">
                                      <i className="bx bxs-info-circle text-warning"></i>
                                    </span>
                                  </Link>
                                </button>
                            }</p>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg="4" className="align-self-center">
                      {/* <div className="text-lg-center mt-4 mt-lg-0">
                        <Row>
                          <Col xs="4">
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Total Projects
                              </p>
                              <h5 className="mb-0">48</h5>
                            </div>
                          </Col>
                          <Col xs="4">
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Projects
                              </p>
                              <h5 className="mb-0">40</h5>
                            </div>
                          </Col>
                          <Col xs="4">
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Clients
                              </p>
                              <h5 className="mb-0">18</h5>
                            </div>
                          </Col>
                        </Row>
                      </div> */}
                    </Col>

                    <Col lg="4" className="d-lg-block">
                      {
                        userInfo?.status_pagamento !== "INATIVO" &&
                        (<div className="clearfix mt-4 mt-lg-0">
                          <Dropdown
                            isOpen={settingsMenu}
                            toggle={() => {
                              setSettingsMenu(!settingsMenu)
                            }}
                            className="float-end"
                          >
                            <DropdownToggle tag="button" className="btn btn-primary">
                              <i className="bx bxs-cog align-middle me-1" /> Opções
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem onClick={() => { toggleDeleteModal() }}>Cancelar Assinatura</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>)}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {userInfo && userInfo.data_cancelamento
            &&
            <Alert color="warning">{
              `Seu plano foi cancelado em ${formatDataBarra(userInfo.data_cancelamento)}, mas você ainda pode utilizar a plataforma até ${formatDataBarra(userInfo.data_fim_pagamento)}.
          Para continuar utilizando após esse período, assine um novo plano`}
            </Alert>}

          <h4 className="card-title mb-4">Editar dados</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                {error && error ? (
                  <Alert color="danger">{error}</Alert>
                ) : null}
                <div>
                  <Row>
                    <Col sm="6" md="3" className="mt-3" style={isMobile ? { maxWidth: '50%' } : {}}>
                      <Label className="form-label">Nome</Label>
                      <Input
                        name="nome"
                        type="text"
                        placeholder="Informe seu Nome"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.nome
                          //.replace(/\s/g, '') //Remove espaços
                          .replace(/\d/g, '') //Remove números
                          || ""}
                        invalid={validation.touched.nome && validation.errors.nome ? true : false}
                      />
                      {validation.touched.nome && validation.errors.nome ? (
                        <FormFeedback type="invalid">{validation.errors.nome}</FormFeedback>
                      ) : null}
                    </Col>
                    <Col sm="6" md="3" className="mt-3" style={isMobile ? { maxWidth: '50%' } : {}}>
                      <Label className="form-label">Sobrenome</Label>
                      <Input
                        name="sobrenome"
                        type="text"
                        placeholder="Informe seu Sobrenome"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.sobrenome || ""}
                        invalid={validation.touched.sobrenome && validation.errors.sobrenome ? true : false}
                      />
                      {validation.touched.sobrenome && validation.errors.sobrenome ? (
                        <FormFeedback type="invalid">{validation.errors.sobrenome}</FormFeedback>
                      ) : null}
                    </Col>
                    <Col sm="12" md="6" className="mt-3">
                      <Label className="form-label">Email</Label>
                      <Input
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Informe seu e-mail"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email ? true : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                      ) : null}
                    </Col>
                    <Col sm="6" md="6" className="mt-3" style={isMobile ? { maxWidth: '55%' } : {}}>
                      <Label className="form-label">Telefone</Label>
                      <PhoneInput
                        id="telefoneNumber"
                        name="telefoneNumber"
                        placeholder="Informe seu Telefone"
                        defaultCountry="BR"
                        value={validation.values.telefoneNumber}
                        onChange={(value) => {
                          validation.setFieldValue('telefoneNumber', value);
                          if (typeof value === 'string' && value.trim() !== '') {
                            const phoneNumber = parsePhoneNumberFromString(value, "BR");
                            if (phoneNumber && phoneNumber.formatNational) {
                              validation.setFieldValue('codigo_pais', `${phoneNumber.countryCallingCode}`);
                              const formattedNational = phoneNumber.formatNational().split(' ');
                              if (formattedNational.length === 2) {
                                validation.setFieldValue('ddd', formattedNational[0].replace(/[\(\)]/g, ''));
                                validation.setFieldValue('telefone', formattedNational[1].replace(/-/g, ''));
                              } else {
                                // Trate o caso em que não foi possível dividir o número nacional
                                validation.setFieldValue('ddd', '');
                                validation.setFieldValue('telefone', '');
                              }
                            } else {
                              // Limpe os campos, já que o valor não é válido
                              validation.setFieldValue('codigo_pais', '');
                              validation.setFieldValue('ddd', '');
                              validation.setFieldValue('telefone', '');
                            }
                          } else {
                            // Limpe os campos, já que o valor não é válido
                            validation.setFieldValue('codigo_pais', '');
                            validation.setFieldValue('ddd', '');
                            validation.setFieldValue('telefone', '');
                          }
                        }}
                        onBlur={validation.handleBlur}
                        className={`d-flex flex-wrap form-control ${validation.touched.telefoneNumber && validation.errors.telefoneNumber ? 'is-invalid' : ''
                          }`}
                      />
                      {validation.touched.telefoneNumber && validation.errors.telefoneNumber ? (
                        <FormFeedback type="invalid">{validation.errors.telefoneNumber}</FormFeedback>
                      ) : null}
                    </Col>
                    <Col sm="6" md="6" className="mt-3" style={isMobile ? { maxWidth: '45%' } : {}}>
                      <Label className="form-label">CPF</Label>
                      <Input
                        id="documento"
                        name="documento"
                        className="form-control"
                        placeholder=""
                        type="text"
                        maxLength="14"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.documento
                          .replace(/\s/g, '') //Remove espaços
                          .replace(/[^0-9]/g, '') //Remove letras e caracteres especiais
                          .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                          || ""}
                        invalid={
                          validation.touched.documento && validation.errors.documento ? true : false
                        }
                      />
                      {validation.touched.documento && validation.errors.documento ? (
                        <FormFeedback type="invalid">{validation.errors.documento}</FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                </div>
                <div /* className="mb-3" */>
                  <Row>
                    <Col md="5" className="mt-3" style={isMobile ? { maxWidth: '75%' } : {}}>
                      <Label className="form-label">Logradouro</Label>
                      <Input
                        name="rua"
                        type="text"
                        placeholder=""
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.rua || ""}
                        invalid={validation.touched.rua && validation.errors.rua ? true : false}
                      />
                      {validation.touched.rua && validation.errors.rua ? (
                        <FormFeedback type="invalid">{validation.errors.rua}</FormFeedback>
                      ) : null}
                    </Col>
                    <Col md="2" className="mt-3" style={isMobile ? { maxWidth: '25%' } : {}}>
                      <Label className="form-label">Número</Label>
                      <Input
                        name="numero"
                        type="text"
                        placeholder=""
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.numero
                          .replace(/[^0-9]/g, '') //Remove letras e caracteres especiais
                          || ""}
                        invalid={validation.touched.numero && validation.errors.numero ? true : false}
                      />
                      {validation.touched.numero && validation.errors.numero ? (
                        <FormFeedback type="invalid">{validation.errors.numero}</FormFeedback>
                      ) : null}
                    </Col>
                    <Col className="mt-3">
                      <Label className="form-label">Complemento</Label>
                      <Input
                        id="complemento"
                        name="complemento"
                        className="form-control"
                        placeholder=""
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.complemento || ""}
                        invalid={
                          validation.touched.complemento && validation.errors.complemento ? true : false
                        }
                      />
                      {validation.touched.complemento && validation.errors.complemento ? (
                        <FormFeedback type="invalid">{validation.errors.complemento}</FormFeedback>
                      ) : null}
                    </Col>
                    <Col className="mt-3">
                      <Label className="form-label">Cidade</Label>
                      <Input
                        id="cidade"
                        name="cidade"
                        className="form-control"
                        placeholder=""
                        type="cidade"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.cidade || ""}
                        invalid={
                          validation.touched.cidade && validation.errors.cidade ? true : false
                        }
                      />
                      {validation.touched.cidade && validation.errors.cidade ? (
                        <FormFeedback type="invalid">{validation.errors.cidade}</FormFeedback>
                      ) : null}
                    </Col>
                    <Col md="3" className="mt-3" style={isMobile ? { maxWidth: '50%' } : {}}>
                      <Label className="form-label">Bairro</Label>
                      <Input
                        id="bairro"
                        name="bairro"
                        className="form-control"
                        placeholder=""
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.bairro || ""}
                        invalid={
                          validation.touched.bairro && validation.errors.bairro ? true : false
                        }
                      />
                      {validation.touched.bairro && validation.errors.bairro ? (
                        <FormFeedback type="invalid">{validation.errors.bairro}</FormFeedback>
                      ) : null}
                    </Col>
                    <Col md="3" className="mt-3" style={isMobile ? { maxWidth: '50%' } : {}}>
                      <Label className="form-label">UF</Label>
                      <Input
                        id="estado"
                        name="estado"
                        className="form-control"
                        placeholder=""
                        type="text"
                        maxLength="2"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.estado.toUpperCase()
                          .replace(/\d/g, '') //Remove números
                          || ""}
                        invalid={
                          validation.touched.estado && validation.errors.estado ? true : false
                        }
                      />
                      {validation.touched.estado && validation.errors.estado ? (
                        <FormFeedback type="invalid">{validation.errors.estado}</FormFeedback>
                      ) : null}
                    </Col>
                    <Col md="3" className="mt-3" style={isMobile ? { maxWidth: '50%' } : {}}>
                      <Label className="form-label">País</Label>
                      <Input
                        id="pais"
                        name="pais"
                        className="form-control"
                        placeholder=""
                        type="text"
                        maxLength="2"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.pais.toUpperCase()
                          .replace(/\d/g, '') //Remove números
                          || ""}
                        invalid={
                          validation.touched.pais && validation.errors.pais ? true : false
                        }
                      />
                      {validation.touched.pais && validation.errors.pais ? (
                        <FormFeedback type="invalid">{validation.errors.pais}</FormFeedback>
                      ) : null}
                    </Col>
                    <Col md="3" className="mt-3" style={isMobile ? { maxWidth: '50%' } : {}}>
                      <Label className="form-label">CEP</Label>
                      <Input
                        id="cep"
                        name="cep"
                        className="form-control"
                        placeholder=""
                        type="text"
                        maxLength='9'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.cep
                          .replace(/[^0-9]/g, '') //Remove letras e caracteres especiais
                          .replace(/(\d{5})(\d{3})/, '$1-$2')
                          || ""}
                        invalid={
                          validation.touched.cep && validation.errors.cep ? true : false
                        }
                      />
                      {validation.touched.cep && validation.errors.cep ? (
                        <FormFeedback type="invalid">{validation.errors.cep}</FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                </div>

                <div className="mt-4 d-grid">
                  <button
                    className={`btn btn-primary btn-block `}
                    type="submit"
                    disabled={areValuesEqual(validation.initialValues, validation.values)}
                  >
                    {isLoadingCadastro ? 'Atualizando ' : 'Atualizar Cadastro'}
                    {isLoadingCadastro && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>}
                  </button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>

      <Modal size="sm" isOpen={show} toggle={toggleDeleteModal} centered={true}>
        <div className="modal-content">
          <ModalBody className="px-4 py-5 text-center">
            {/* <button type="button" onClick={onDeleteClick} className="btn-close position-absolute end-0 top-0 m-3"></button> */}
            <div className="avatar-sm mb-4 mx-auto">
              <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                <i className="mdi mdi-trash-can-outline"></i>
              </div>
            </div>
            <p className="text-muted font-size-16 mb-4">Tem certeza que deseja cancelar sua assinatura?</p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button type="button" className="btn btn-danger" onClick={() => {
                try {
                  setIsDeleting(true)
                  cancelSignature().then((result) => {
                    setIsDeleting(false);
                    toggleDeleteModal()
                  })
                    .catch((err) => {
                      setIsDeleting(false)
                      console.error('Erro inesperado:', err.message);
                    })

                } catch (error) {
                  console.error(error)
                }
              }}>
                {isDeleting ? (
                  <span>Deletando <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i></span>
                ) : (
                  <span>Cancelar <i className="bx bx-delete"></i></span>
                )}

              </button>

              <button type="button" className="btn btn-secondary" onClick={() => { toggleDeleteModal() }}>Sair</button>
            </div>
          </ModalBody>
        </div>
      </Modal>

    </React.Fragment>
  );
};

export default withRouter(UserProfile);

import { AssetsContext } from "contexts/assets-context/assets-context";
import { formatarNumeroComUnidades, formatarNumeroComUnidadesLetraUnica } from "helpers/formatNumbers";
import React, { useContext, useEffect, useState } from "react";

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import PayoutChart from "./Components/payoutChart";
import RevenueChart from "./Components/RevenueChart";
import DividaChart from "./Components/DividaChart";



const paragraph = 'mb-0 text-nowrap'
const td = 'mb-0 p-1'
const exib = false;


function TableCell({ label, cost, value, calcularDiferencaPercentual, index, dados }) {
  let valorAtual = dados[index][value];
  if (valorAtual === null) {
    valorAtual = "--";
  }

  let valorAnterior = index < dados.length - 1 ? dados[index + 1][value] : null;
  if (valorAnterior === null) {
    valorAnterior = "--";
  }

  let diferencaPercentual = "--"; // Valor padrão para quando não houver valor anterior

  if (valorAnterior !== "--" && valorAtual !== "--") {
    diferencaPercentual = calcularDiferencaPercentual(valorAtual, valorAnterior);
  }

  let badgeClass = 'bg-warning';

  if (!cost && diferencaPercentual.includes('-')) {
    badgeClass = 'bg-danger';
  } else if (cost && diferencaPercentual.includes('-')) {
    badgeClass = 'bg-success';
  } else if (cost && diferencaPercentual.includes('+')) {
    badgeClass = 'bg-danger';
  } else if (diferencaPercentual.includes('+')) {
    badgeClass = 'bg-success';
  }

  return (
    <td className={td}>
      <p className={paragraph}>
        {formatarNumeroComUnidadesLetraUnica(valorAtual)}
      </p>
      {exib && valorAnterior !== "--" && (
        <span
          style={{ width: '55px', textAlign: 'center', display: 'inline-block' }}
          className={`badge ${badgeClass} ms-1 align-center font-size-10`}>
          {diferencaPercentual}
        </span>
      )}
    </td>
  );
}

const Finances = () => {

  const {
    getPatrimonialBalance,
    patrimonialBalance,
    selectedTicker,
    getFinancialResult,
    getCashFlow,
    financialResult,
    cashFlow,
    getPayout
  } = useContext(AssetsContext);

  const [dataRange, setDataRange] = useState({
    inicial: null,
    final: null
  });

  const [tipo, setTipo] = useState('ANUAL')

  const [timeToFilter, setTimeToFilter] = useState({
    ano: 5,
    mes: 0,
    dia: 0
  });

  useEffect(() => {
    const agora = new Date();
    const dataFinal = new Date(agora);

    dataFinal.setDate(dataFinal.getDate() - timeToFilter.dia);
    dataFinal.setMonth(dataFinal.getMonth() - timeToFilter.mes);
    dataFinal.setFullYear(dataFinal.getFullYear() - timeToFilter.ano);

    const formattedInicial = `${dataFinal.getFullYear()}-${(dataFinal.getMonth() + 1).toString().padStart(2, '0')}-${dataFinal.getDate().toString().padStart(2, '0')}`;
    const formattedFinal = `${agora.getFullYear()}-${(agora.getMonth() + 1).toString().padStart(2, '0')}-${agora.getDate().toString().padStart(2, '0')}`;

    setDataRange({
      inicial: formattedInicial,
      final: formattedFinal
    });
  }, [timeToFilter]);

  useEffect(() => {
    if (dataRange.inicial && dataRange.final && tipo) {
      getPatrimonialBalance(dataRange.inicial, dataRange.final, tipo);
      getFinancialResult(dataRange.inicial, dataRange.final, tipo);
      getCashFlow(dataRange.inicial, dataRange.final, tipo);
      getPayout(dataRange.inicial, dataRange.final);
    }
  }, [dataRange, selectedTicker, tipo]);


  const extrairAno = (data) => {
    const partes = data.split("-");
    const ano = partes[2];
    return ano;
  }

  const trim = 'Últ. 4 Trim'

  function calcularDiferencaPercentual(valorAtual, valorAnterior) {

    if (valorAtual === 0 && valorAnterior === 0) {
      return '0.00%'
    }

    const diferenca = valorAtual - valorAnterior;
    const diferencaPercentual = (diferenca / valorAnterior) * 100;

    const sinal = diferencaPercentual >= 0 ? '+' : '-';

    return `${sinal} ${Math.abs(diferencaPercentual).toFixed(2)}%`;
  }

  return (
    <React.Fragment>
      <Row xl="12">
        <Col>
          <Row>
            <Col lg={12}>
              <PayoutChart />
            </Col>
            <Col lg={6}>
              <RevenueChart />
            </Col>
            <Col lg={6}>
              <DividaChart />
            </Col>

            <Col className="font-size-12" style={{ userSelect: 'none' }} xl={12}>

              <Card>
                <CardBody>
                  <CardTitle className="h4">Fluxo de Caixa</CardTitle>

                  <div className="table-responsive">
                    <Table className="table table-hover table-balance mb-0" style={{ textAlign: 'right' }}>

                      <thead className="">
                        <tr >
                          <th>{' '}</th>
                          {cashFlow && cashFlow.map((refer, index) => (
                            <th key={index}>{extrairAno(refer.data)}</th>
                          ))}
                        </tr>
                      </thead>

                      <tbody className="">
                        <tr style={{ fontWeight: 'bold' }}>
                          <td className={td}><p className={paragraph}>Saldo Final de Caixa</p></td>
                          {cashFlow && cashFlow.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Saldo Final de Caixa"
                              value="saldo_final_caixa_e_equivalentes"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={cashFlow} // Passa os dados aqui
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>Aplicações Financeiras</p></td>
                          {cashFlow && cashFlow.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Aplicações Fiananceiras"
                              value="aplicacoes_financeiras"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={cashFlow} // Passa os dados aqui
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>Lucro Líquido</p></td>
                          {cashFlow && cashFlow.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Lucro Líquido"
                              value="lucro_liquido"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={cashFlow} // Passa os dados aqui
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>Depr. e Amortização</p></td>
                          {cashFlow && cashFlow.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Depr. e Amortização"
                              value="depreciacao_amortizacao_exaustao"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={cashFlow} // Passa os dados aqui
                            />
                          ))}
                        </tr>

                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="h4">Resultado</CardTitle>

                  <div className="table-responsive">
                    <Table className="table table-hover table-balance mb-0" style={{ textAlign: 'right' }}>

                      <thead className="">
                        <tr >
                          <th>{' '}</th>
                          {financialResult && financialResult.map((refer, index) => (
                            <th key={index}>{extrairAno(refer.data)}</th>
                          ))}
                        </tr>
                      </thead>

                      <tbody className="">
                        <tr style={{ fontWeight: 'bold' }}>
                          <td className={td}><p className={paragraph}>Receitas e Despesas Op.</p></td>
                          {financialResult && financialResult.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Receitas e Despesas Operacionais"
                              value="receitas_despesas_operacionais"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={financialResult}
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>Resultado Financeiro</p></td>
                          {financialResult && financialResult.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Resultado Financeiro"
                              value="resultado_financeiro"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={financialResult}
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>Receita Líquida</p></td>
                          {financialResult && financialResult.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Receita Líquida"
                              value="receita_liquida"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={financialResult}
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>Lucro Bruto</p></td>
                          {financialResult && financialResult.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Lucro Bruto"
                              value="lucro_bruto"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={financialResult}
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>Custos</p></td>
                          {financialResult && financialResult.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Custos"
                              value="custos"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={financialResult}
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>Impostos</p></td>
                          {financialResult && financialResult.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Impostos"
                              value="impostos"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={financialResult}
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>Lucro Líquido</p></td>
                          {financialResult && financialResult.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Lucro Líquido"
                              value="lucro_liquido"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={financialResult}
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>Depreciação e Amortização</p></td>
                          {financialResult && financialResult.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Depreciação e Amortização"
                              value="depreciacao_amortizacao_exaustao"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={financialResult}
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>EBIT</p></td>
                          {financialResult && financialResult.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="EBIT"
                              value="ebit"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={financialResult}
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>EBITDA</p></td>
                          {financialResult && financialResult.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="EBITDA"
                              value="ebitda"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={financialResult}
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>ROE</p></td>
                          {financialResult && financialResult.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="ROE"
                              value="ROE"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={financialResult}
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>Margem EBITDA</p></td>
                          {financialResult && financialResult.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Margem EBITDA"
                              value="margem_ebitda"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={financialResult}
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>Margem EBIT</p></td>
                          {financialResult && financialResult.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Margem EBIT"
                              value="margem_ebit"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={financialResult}
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>Margem Líquida</p></td>
                          {financialResult && financialResult.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Margem Líquida"
                              value="margem_liquida"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={financialResult}
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>ROA</p></td>
                          {financialResult && financialResult.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="ROA"
                              value="ROA"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={financialResult}
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>Dívida Bruta</p></td>
                          {financialResult && financialResult.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Dívida Bruta"
                              value="divida_bruta"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={financialResult}
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>Dívida Líquida</p></td>
                          {financialResult && financialResult.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Dívida Líquida"
                              value="divida_liquida"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={financialResult}
                            />
                          ))}
                        </tr>

                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="h4">Balanço Patrimonial</CardTitle>

                  <div className="table-responsive">
                    <Table className="table table-hover table-balance mb-0" style={{ textAlign: 'right' }}>

                      <thead /* className="font-size-10 " */>
                        <tr >
                          <th>{' '}</th>
                          {patrimonialBalance && patrimonialBalance.map((refer, index) => (
                            <th key={index}>{extrairAno(refer.data)}</th>
                          ))}
                        </tr>
                      </thead>

                      <tbody /* className="font-size-11" */>
                        <tr style={{ fontWeight: 'bold' }}>
                          <td className={td}><p className={paragraph}>Ativo Total</p></td>
                          {patrimonialBalance && patrimonialBalance.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Ativo Total"
                              value="ativo_total"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={patrimonialBalance} // Passa os dados aqui
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>Ativo Circulante</p></td>
                          {patrimonialBalance && patrimonialBalance.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Ativo Circulante"
                              value="ativo_circulante"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={patrimonialBalance} // Passa os dados aqui
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>Ativo Não Circulante</p></td>
                          {patrimonialBalance && patrimonialBalance.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Ativo Não Circulante"
                              value="ativo_nao_circulante"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={patrimonialBalance} // Passa os dados aqui
                            />
                          ))}
                        </tr>

                        <tr style={{ fontWeight: 'bold' }}>
                          <td className={td}><p className={paragraph}>Passivo Total</p></td>
                          {patrimonialBalance && patrimonialBalance.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Passivo Total"
                              cost={true}
                              value="passivo_total"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={patrimonialBalance} // Passa os dados aqui
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>Passivo Circulante</p></td>
                          {patrimonialBalance && patrimonialBalance.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Passivo Circulante"
                              cost={true}
                              value="passivo_circulante"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={patrimonialBalance} // Passa os dados aqui
                            />
                          ))}
                        </tr>
                        <tr>
                          <td className={td}><p className={paragraph}>Passivo Não Circulante</p></td>
                          {patrimonialBalance && patrimonialBalance.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Passivo Não Circulante"
                              cost={true}
                              value="passivo_nao_circulante"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={patrimonialBalance} // Passa os dados aqui
                            />
                          ))}
                        </tr>

                        <tr style={{ fontWeight: 'bold' }}>
                          <td className={td}><p className={paragraph}>Patrimônio Líquido</p></td>
                          {patrimonialBalance && patrimonialBalance.map((refer, index) => (
                            <TableCell
                              key={index}
                              label="Patrimônio Líquido"
                              value="patrimonio_liquido_consolidado"
                              calcularDiferencaPercentual={calcularDiferencaPercentual}
                              index={index}
                              dados={patrimonialBalance} // Passa os dados aqui
                            />
                          ))}
                        </tr>

                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>

            </Col>
          </Row>
        </Col>
      </Row>

    </React.Fragment>
  )
}

export default Finances

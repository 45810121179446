import React, { useContext, useEffect, useState } from "react";
// import { Link, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import * as moment from "moment";
import {
  Card,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { WalletContext } from "contexts/wallet-context/wallet-context";

//Import Component
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import { formatCurrency } from "helpers/formatCurrency";
import { AssetsContext } from "contexts/assets-context/assets-context";


const StocksList = () => {

  //meta title
  document.title = "Stocks List";

  const mockParams = {
    country: "br",
    exchange: "bovespa",
    route: "assets"
  }

  const { setActiveTab } = useContext(AssetsContext)

  const {
    acoes, fiis, assetType, getFiis, getAcoes
  } = useContext(WalletContext);

  const [assets, setAssets] = useState(acoes)

  useEffect(() => {
    if (assetType === 'acoes') {
      setAssets(acoes)
    } else if (assetType === 'fiis') {
      setAssets(fiis)
    }
  }, [assetType, acoes, fiis]);

  useEffect(() => {
    getAcoes();
    getFiis();
  }, []);

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [stockList, setStockList] = useState([]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setStocks(null);
    } else {
      setModal(true);
    }
  };

  const formatData = (date) => {

    const padraoDataHora = /\b(\d{2}-\d{2}-\d{4})\s(\d{2}:\d{2}:\d{2})\b/;
    const resultado = date.match(padraoDataHora);

    if (resultado) {
      const data = resultado[1].replace(/(\d{2})-(\d{2})-(\d{4})/, "$1/$2/$3");
      const hora = resultado[2];
      return { data, hora };
    } else {
      return null;
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <div>
            <div className="table-responsive">

              <Table className="project-list-table table-nowrap align-middle table-sticky">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: "50px" }}>
                      Papel
                    </th>
                    <th scope="col"></th>
                    <th scope="col">Cotação</th>
                    <th scope="col">Preço Médio</th>
                    <th scope="col" className="text-end">Quantidade</th>
                    {/* <th scope="col">Cotação</th> */}
                    {/* <th scope="col">Preço Justo</th>
                    <th scope="col">Preço Teto</th>
                    <th scope="col">Preço Projetivo</th> */}
                    {/* <th scope="col">Preço Médio</th> */}
                    <th scope="col" className="text-end">Último Registro</th>
                    <th scope="col" className="text-end">Detalhes</th>
                  </tr>
                </thead>
                <tbody>
                  {assets?.dto?.length > 0 && assets.dto.map((asset, index) => (
                    <tr key={index}>
                      <td>
                        <Link
                          onClick={() => setActiveTab('Extrato')}
                          to={`/${mockParams.route}/${mockParams.country}/${mockParams.exchange}/${assetType.toLowerCase()}/${asset?.simbolo.toLowerCase()}`}

                        >
                          <img
                            src={asset?.logo}
                            alt=""
                            className="avatar-sm rounded"
                          />
                        </Link>
                      </td>
                      <td name="nome-simbolo">
                        <Link
                          onClick={() => setActiveTab('Extrato')}
                          to={`/${mockParams.route}/${mockParams.country}/${mockParams.exchange}/${assetType.toLowerCase()}/${asset?.simbolo.toLowerCase()}`}
                          className="text-dark"
                        >
                          <h5 className="text-truncate font-size-14 mb-1">
                            {asset?.simbolo}
                          </h5>
                          <p className="text-muted mb-0">
                            {asset?.nomeCompania.substring(0, 9 - 3) +
                              (asset?.nomeCompania.length >= 9 ? ' ...' : '')}
                          </p>
                        </Link>
                      </td>
                      <td name="cotacao">
                        <p className="text-muted mb-0">
                          {asset?.precoAtual && formatCurrency(Number(asset.precoAtual), 'pt-BR', 'BRL')}
                        </p>
                      </td>
                      <td name="preco-medio">
                        <p className="text-muted mb-0">
                          {Number(asset?.precoMedio).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                        </p>
                      </td>
                      <td name="quantidade-total">
                        <h5 className="text-truncate text-end font-size-14">
                          {asset?.quantidadeTotal}
                        </h5>
                        <p className="text-dark text-end mb-0">
                          {(asset?.quantidadeTotal * asset?.precoMedio).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                        </p>
                      </td>
                      {/* <td name="precoJustoGraham">
                        <p className="text-muted mb-0">
                          {asset?.precoJustoGraham}
                        </p>
                      </td>
                      <td name="preco-maximo">
                        <p className="text-muted mb-0">
                          {asset?.precoTeto}
                        </p>
                      </td>
                      <td name="preco-projetivo">
                        <p className="text-muted mb-0">
                          {asset?.precoProjetivo}
                        </p>
                      </td> */}
                      <td>
                        <p className="text-muted text-end mb-0">
                          {formatData(asset?.data).data}
                        </p>
                      </td>

                      <td>
                        <div className="text-end mt-3">
                          <Link
                            onClick={() => setActiveTab('Extrato')}
                            to={`/${mockParams.route}/${mockParams.country}/${mockParams.exchange}/${assetType.toLowerCase()}/${asset?.simbolo.toLowerCase()}`}
                            className="btn btn-primary btn-sm w-sm"
                          >
                            Extrato
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

            </div>
          </div>
        </Col>
      </Row>
      {/* <Row>
        <Col xs="12">
          <div className="text-center my-3">
            <Link to="#" className="text-success">
              <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
              Load more
            </Link>
          </div>
        </Col>
      </Row> */}
    </React.Fragment>
  );
};

export default withRouter(StocksList);

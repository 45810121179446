import React from 'react';
import ViewSDKClient from './Pdf/ViewSDKClient';

const PostDetail = ({selected}) => {
    document.title = "Fatos Relevantes | Evolue";

    const viewSDKClient = new ViewSDKClient();
    viewSDKClient.ready().then(() => {
        viewSDKClient.previewFile("pdf-div", {
            showAnnotationTools: false,
            showDownloadPDF: false,
            showPrintPDF: false
        }, selected);
    });

    return (
        <React.Fragment>
            {/* <div className="page-content"> */}
                <div id="pdf-div" className="full-window-div" />;
            {/* </div> */}
        </React.Fragment>
    );
}

export default PostDetail;
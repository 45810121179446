import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Col,
  Row,
  Table,
} from "reactstrap";
import { formatCurrency } from "helpers/formatCurrency";
import { AssetsContext } from "contexts/assets-context/assets-context";
import { OptionContext } from "contexts/options-context/options-context";
import { formatDataBarra, handleBrazilStringDate } from "helpers/formatDateTime";


const OptionList = () => {

  //meta title
  document.title = "Option List";

  const mockParams = {
    country: "br",
    exchange: "bovespa",
    route: "assets"
  }

  const { setActiveTab } = useContext(AssetsContext)

  const {
    opcoes,
    assetType,
    heroInformation,
    setHeroInformation
  } = useContext(OptionContext)

  const [years, setYears] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedYear, setSelectedYear] = useState(opcoes && Object.keys(opcoes) ? Object.keys(opcoes)[0] : null);
  const [selectedDate, setSelectedDate] = useState(opcoes && opcoes[selectedYear] ? Object.keys(opcoes[selectedYear])[0] : null);
  const [assets, setAssets] = useState(opcoes ? opcoes[selectedYear]?.[selectedDate]?.opcoes : null)


  const extractDatesByYear = (year) => {
    if (opcoes && opcoes[year]) {
      const dates = Object.keys(opcoes[year]);
      setDates(dates);
      setSelectedDate(Object.keys(opcoes[selectedYear])[0])
    }
  };

  useEffect(() => {
    if (opcoes) {
      const years = Object.keys(opcoes);
      setYears(years);
      setSelectedYear(years[0])

      const dates = Object.keys(opcoes[years[0]])
      setDates(dates);
      setAssets(opcoes[years[0]]?.[dates[0]]?.opcoes);

      const updatedHeroInformation = {
        ...heroInformation,
        notional: opcoes[years[0]]?.[dates[0]]?.totalNotional || 0,
        premios: opcoes[years[0]]?.[dates[0]]?.totalPremio || 0,
      };

      setHeroInformation(updatedHeroInformation);
    }
  }, [opcoes]);


  useEffect(() => {
    if (selectedDate && selectedYear) {
      setAssets(opcoes[selectedYear]?.[selectedDate]?.opcoes);

      const updatedHeroInformation = {
        ...heroInformation,
        notional: opcoes[selectedYear]?.[selectedDate]?.totalNotional || 0,
        premios: opcoes[selectedYear]?.[selectedDate]?.totalPremio || 0,
      };

      setHeroInformation(updatedHeroInformation);
    }
  }, [selectedYear, selectedDate])

  useEffect(() => {
    if (selectedYear) {
      extractDatesByYear(selectedYear)
    }
  }, [opcoes, selectedYear])

  return (
    <React.Fragment>

      <Col className='d-flex me-3'>
        {years?.map((year) => (

          <button
            key={year}
            className={`btn ${selectedYear === year ? 'btn-secondary' : 'btn-primary'} btn-sm m-2`}
            onClick={() => { setSelectedYear(year) }}
          >{year}</button>

        ))}
      </Col>

      <Col className='d-flex me-3'>
        {dates?.map((date) => (

          <button
            key={date}
            className={`btn ${selectedDate === date ? 'btn-secondary' : 'btn-warning'} btn-sm m-2`}
            onClick={() => { setSelectedDate(date) }}
          >{handleBrazilStringDate(String(date)).replace(/-/g, "/")}</button>

        ))}
      </Col>

      <Row>
        <Col lg="12">
          <div>
            <div className="table-responsive">

              <Table className="project-list-table table-nowrap align-middle table-sticky">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: "50px" }}>
                      Papel
                    </th>
                    <th scope="col"></th>
                    <th scope="col">Cotação</th>
                    <th scope="col">Strike</th>
                    <th scope="col" className="text-end">Quantia</th>
                    <th scope="col" className="text-end">Notional</th>
                    <th scope="col" className="text-end">Prêmio</th>
                    <th scope="col" className="text-end">Exercício</th>
                    <th scope="col" className="text-end">Detalhes</th>
                  </tr>
                </thead>
                <tbody>
                  {assets?.map((asset, index) => (
                    <tr key={index}>
                      <td>
                        <Link
                          onClick={() => setActiveTab('Extrato')}
                          to={`/${mockParams.route}/${mockParams.country}/${mockParams.exchange}/${assetType.toLowerCase()}/${asset?.simbolo.toLowerCase()}`}

                        >
                          <img
                            src={asset?.logo}
                            alt=""
                            className="avatar-sm rounded"
                          />
                        </Link>
                      </td>
                      <td name="nome-simbolo">
                        <h5 className="text-truncate font-size-14 mb-1">
                          {asset?.codigo}
                        </h5>
                        <Link
                          onClick={() => setActiveTab('Extrato')}
                          to={`/${mockParams.route}/${mockParams.country}/${mockParams.exchange}/${assetType.toLowerCase()}/${asset?.simbolo.toLowerCase()}`}
                          className="text-dark"
                        >
                          <p className="text-muted mb-0">
                            {asset?.simbolo.substring(0, 9 - 3) +
                              (asset?.simbolo.length >= 9 ? ' ...' : '')}
                          </p>
                        </Link>
                      </td>
                      <td name="cotacao">
                        <p className="text-muted mb-0">
                          {asset?.precoAtual && formatCurrency(Number(asset.precoAtual), 'pt-BR', 'BRL')}
                        </p>
                      </td>
                      <td name="preco-medio">
                        <p className="text-muted mb-0">
                          {Number(asset?.strike).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                        </p>
                      </td>
                      <td name="quantidade-total">
                        <h5 className="text-truncate text-end font-size-14">
                          {asset?.quantidade}
                        </h5>
                      </td>
                      <td name="notional">
                        <h5 className="text-truncate text-end font-size-14">
                          {(asset?.quantidade * asset?.strike).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                        </h5>
                      </td>
                      <td name="premio">
                        <h5 className="text-truncate text-end font-size-14">
                          {(asset?.quantidade * asset?.premio).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                        </h5>
                        <p className="text-dark text-end mb-0">
                          {(asset?.premio * 100 / asset.strike).toFixed(2) + `%`}
                        </p>
                      </td>
                      <td>
                        <p className="text-muted text-end mb-0">
                          {formatDataBarra(asset?.dataExercício)}
                        </p>
                      </td>

                      <td>
                        <div className="text-end mt-3">
                          <Link
                            onClick={() => setActiveTab('Extrato')}
                            to={`/${mockParams.route}/${mockParams.country}/${mockParams.exchange}/${assetType.toLowerCase()}/${asset?.simbolo.toLowerCase()}`}
                            className="btn btn-primary btn-sm w-sm"
                          >
                            Extrato
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withRouter(OptionList);

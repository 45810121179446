import React, { useState, createContext, useEffect } from "react";
import api from "services/api";
//import { toast } from 'react-toastify';

export const SearchContext = createContext();

const SearchProvider = ({ children }) => {

    const [ativos, setAtivos] = useState([])
    const [corretoras, setCorretoras] = useState([])

    const buscaAtivos = async (param) => {
        try {
            const response = await api.get(`/ativos/buscar-ticker${param ? `?ticker=${param}` : null}`);
            if (response.data === null) { return }

            setAtivos(response.data);

        } catch (err) {
            /* toast.warn("Não foi possíel recuperar os monstros da base de dados" + err.message); */
            console.error('erro aos buscar açoes' + err)
        }
    };

    const buscaCorretora = async (params) => {
        try {
            const response = await api.get(`/admin-corretoras${params ? `?iniciais=${params}` : null}`);

            if (response.data === null) { return }

            setCorretoras(response.data);

        } catch (err) {
            /* toast.warn("Não foi possíel recuperar os monstros da base de dados" + err.message); */
            console.error('erro aos buscar açoes' + err)
        }
    };

    const handleInputAtivosChange = (event) => {
        const { value } = event.target;

        // Chamando a função buscaCorretora quando o valor digitado for maior ou igual a 3 caracteres
        if (value.length >= 3) {
            buscaAtivos(value);
        }
    };

    const handleInputChange = (event) => {
        const { value } = event.target;

        // Chamando a função buscaCorretora quando o valor digitado for maior ou igual a 3 caracteres
        if (value.length >= 3) {
            buscaCorretora(value);
        }
    };

    return (
        <SearchContext.Provider value={{
            corretoras, ativos,
            handleInputChange,
            handleInputAtivosChange
        }}>
            {children}
        </SearchContext.Provider>
    );
};

export default SearchProvider;
import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../components/Common/ChartsDynamicColor";
import { WalletContext } from "contexts/wallet-context/wallet-context";
import { formatCurrency } from "helpers/formatCurrency";
import LogoB3White from "../../assets/images/logo_b3_white.png"
import { UserContext } from "contexts/user-context/user-context";

const WalletBalance = ({ dataColors }) => {

  const {
    getAcoes, acoes, getFiis, fiis
  } = useContext(WalletContext);

  const { importB3, getUser, userInfo } = useContext(UserContext)

  const [totalInvestido, setTotalInvestido] = useState(null)
  const [consolidado, setConsolidado] = useState(null)
  const [porcentagemAcoes, setPorcentagemAcoes] = useState(0);
  const [porcentagemFiis, setPorcentagemFiis] = useState(0);
  const [hasB3, setHasB3] = useState(false)
  const [pendingB3, setPendingB3] = useState(false)

  useEffect(() => {
    if (!userInfo) {
      getUser()
    }

    if (userInfo) {
      setHasB3((userInfo.autorizacao_b3 === null || userInfo.autorizacao_b3 === "NAO_AUTORIZADO") && userInfo.status_pagamento !== "INATIVO")
    }

    if (userInfo) {
      if (userInfo.autorizacao_b3 === "PENDENTE") {
        setPendingB3(true)
      }
    }

  }, [userInfo])

  useEffect(() => {
    getAcoes(),
      getFiis()
  }, [])

  useEffect(() => {
    if (acoes && fiis) {
      const totalAcoes = Number(acoes.totalPrecoMedio) ?? 0;
      const totalFiis = Number(fiis.totalPrecoMedio) ?? 0;
      const cotacaoAtualAcoes = Number(acoes.totalCotacaoAtual) ?? 0
      const cotacaoAtualFiis = Number(fiis.totalCotacaoAtual) ?? 0
      setTotalInvestido(Number(totalAcoes) + Number(totalFiis));
      setConsolidado(Number(cotacaoAtualAcoes) + Number(cotacaoAtualFiis));
      setPorcentagemAcoes((totalAcoes / (totalAcoes + totalFiis)) * 100);
      setPorcentagemFiis((totalFiis / (totalAcoes + totalFiis)) * 100);
    }
  }, [acoes, fiis]);

  const calculatePercentageDifference = (num1, num2) => {
    if (num1 === 0 || num2 === 0) return '0.00 %';

    const difference = ((num2 - num1) / num1) * 100;
    const sign = difference >= 0 ? '+' : '-';
    const formattedDifference = Math.abs(difference).toFixed(2);

    return `${sign} ${formattedDifference} %`;
  };

  const differencePercentage = calculatePercentageDifference(totalInvestido, consolidado);
  const badgeClass = differencePercentage.includes('-') ? 'mdi mdi-arrow-down text-danger' : 'mdi mdi-arrow-up text-success';

  const walletBalanceChartColors = getChartColorsArray(dataColors);

  const chartdata = [porcentagemAcoes.toFixed(0), porcentagemFiis.toFixed(0)]

  const walletOptions = {
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: "35%",
          background: "transparent",
          image: void 0,
        },
        track: {
          show: !0,
          startAngle: void 0,
          endAngle: void 0,
          background: "#f2f2f2",
          strokeWidth: "97%",
          opacity: 1,
          margin: 12,
          dropShadow: {
            enabled: !1,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5,
          },
        },
        dataLabels: {
          name: {
            show: !0,
            fontSize: "16px",
            fontWeight: 600,
            offsetY: -10,
          },
          value: {
            show: !0,
            fontSize: "14px",
            offsetY: 4,
            formatter: function (e) {
              return e + "%";
            },
          },
        },
      },
    },
    stroke: {
      lineCap: "round",
    },
    colors: walletBalanceChartColors,
    labels: ["Ações", "FIIs"],
    legend: { show: !1 },
  };

  return (
    <React.Fragment>
      <Col xl="12">
        <Card className="fix-heigth">
          <CardBody>
            <div className="d-flex align-items-center space-between">
              <h4 className="card-title">Saldo da Carteira</h4>
              {hasB3 &&
                <div className="flex-shrink-0 text-end">
                  <Link
                    onClick={() => { importB3() }}
                    to={process.env.REACT_APP_B3_URL}
                    className="btn btn-primary btn-sm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="mt-1">Importar Dados</span>
                    <img src={LogoB3White} className="ms-2" style={{ width: '25px', height: "20px" }} />
                  </Link>
                </div>
              }
              {pendingB3 &&
                <div className="flex-shrink-0 text-end">
                  <Link
                    to=""
                    className="btn btn-secondary btn-sm"
                    disabled
                  >
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    <span className="mt-1">Importando</span>
                    <img src={LogoB3White} className="ms-2" style={{ width: '25px', height: "20px" }} />
                  </Link>
                </div>
              }
            </div>

            <Row>
              <Col lg="4">
                <div className="mt-4">
                  <p>Saldo Consolidado</p>
                  <h4>{(consolidado
                    ? formatCurrency(
                      (consolidado), 'pt-BR', 'BRL') : 'R$ 0,00')}
                  </h4>
                  <p className="text-muted mb-4">
                    {" "}
                    {differencePercentage}{" "}
                    <i className={`${badgeClass} ms-1`} />
                  </p>

                  <Row>
                    <Col xs="6">
                      <div>
                        <p className="mb-2">Total Investido</p>
                        <h5>{(totalInvestido
                          ? formatCurrency(
                            (totalInvestido), 'pt-BR', 'BRL') : 'R$ 0,00')}</h5>
                      </div>
                    </Col>
                    {/* <Col xs="6">
                      <div>
                        <p className="mb-2">Variável</p>
                        <h5>$ {Expense}</h5>
                      </div>
                    </Col> */}
                  </Row>

                  <div className="mt-4">
                    <Link to="/wallet" className="btn btn-primary btn-sm">
                      Ir para carteira <i className="mdi mdi-arrow-right ms-1" />
                    </Link>
                  </div>
                </div>
              </Col>

              <Col lg="5" sm="6">
                <div>
                  <div id="wallet-balance-chart">
                    <ReactApexChart
                      options={walletOptions}
                      series={chartdata}
                      type="radialBar"
                      height={300}
                      className="apex-charts"
                    />
                  </div>
                </div>
              </Col>

              <Col lg="3" sm="6" className="align-self-center">
                <div>
                  <p className="mb-2">
                    <i className="mdi mdi-circle align-middle font-size-10 me-2 text-primary" />{" "}
                    Ações
                  </p>
                  <h5>
                    {(acoes
                      ? formatCurrency(
                        (Number(acoes.totalCotacaoAtual)), 'pt-BR', 'BRL') : 'R$ 0,00')}
                  </h5>
                </div>

                <div className="mt-4 pt-2">
                  <p className="mb-2">
                    <i className="mdi mdi-circle align-middle font-size-10 me-2 text-warning" />{" "}
                    FIIs
                  </p>
                  <h5>
                    {(fiis
                      ? formatCurrency(
                        (Number(fiis.totalCotacaoAtual)), 'pt-BR', 'BRL') : 'R$ 0,00')}
                  </h5>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default WalletBalance;

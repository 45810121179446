import { AssetsContext } from "contexts/assets-context/assets-context";
import { formatCurrency } from "helpers/formatCurrency";
import { formatarNumeroComUnidades } from "helpers/formatNumbers";
import React, { useState, useEffect, useContext } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

const CompanyInfo = () => {
  const sm = 3;

  const {
    acao, assetInfo, getAssetInfo, tickerType
  } = useContext(AssetsContext);

  const mockDataFii = {
    "symbol": "MXRF11",
    "classe": "FII",
    "name": "FII MAXI REN",
    "nome_longo": "MAXI RENDA FUNDO INVESTIMENTO IMOBILIARIO FII",
    "documento": "97.521.225/0001-25",
    "segmento": "Híbrido",
    "valor_mercado": "2418624394.10",
    "patrimonio_liquido_consolidado": 2592330747.84,

    "publico_alvo": "INVESTIDORES EM GERAL",
    "numero_papeis": 226039663,
    "fundo_exclusivo": "N",
    "cotistas_vinculo_familiar": "N",
    "mandato": "Híbrido",
    "tipo_gestao": "Ativa",
    "encerramento_exercicio_social": "31/12",
    "entidade_administradora_bvmf": "S",
    "entidade_administradora_cetip": "N",
    "nome_administrador": "BTG PACTUAL SERVICOS FINANCEIROS S/A DTVM",
    "cotistas": 908249,
    "valor_patrimonial_cotas_mensal": 10
  }

  if (tickerType === 'fiis') {
    return (
      <React.Fragment>
        <CardBody className="border-bottom">
          <div>
            <div>
              <h5 >{acao.nome_longo}</h5>
              <p className="text-muted mb-1">{acao.nome_empresa}</p>
              <p className="text-muted mb-1">{acao.documento}</p>
              {/* <p className="text-muted mb-0">{acao.descricao}</p> */}
              <p className="text-muted mb-3">Administrador: {acao.nome_administrador}</p>
              <Row>
                <Col>
                  <Card style={{ minHeight: '80%', maxHeight: '80%' }}>
                    <CardBody className="p-1 m-1">
                      <div className="d-flex flex-wrap">
                        <div className="me-3 text-center">
                          <p className={`text-muted text-center mb-2`}>
                            {'Gestão'}
                          </p>
                          <h6 className={`mb-0 `}>
                            {`${acao.tipo_gestao}`}
                          </h6>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card style={{ minHeight: '80%', maxHeight: '80%' }}>
                    <CardBody className="p-1 m-1">
                      <div className="d-flex flex-wrap">
                        <div className="me-3 text-center">
                          <p className={`text-muted mb-2`}>
                            {'Público Alvo'}
                          </p>
                          <h6 className={`mb-0 `}>
                            {`${acao.publico_alvo.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())}`}
                          </h6>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card style={{ minHeight: '80%', maxHeight: '80%' }}>
                    <CardBody className="p-1 m-1">
                      <div className="d-flex text-center flex-wrap">
                        <div className="me-3">
                          <p className={`text-muted mb-2`}>
                            {'Duração'}
                          </p>
                          <h6 className={`mb-0 `}>
                            {`${acao.prazo_duracao ? acao.prazo_duracao : 'Indeterminado'}`}
                          </h6>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </CardBody>
      </React.Fragment>
    )
  }



  return (
    <React.Fragment>
      <CardBody className="border-bottom">
        <div>
          <div>
            <h5 >{acao.nome_longo}</h5>
            <p className="text-muted mb-1">{acao.nome_empresa}</p>
            <p className="text-muted mb-1">{acao.documento}</p>
            {/* <p className="text-muted mb-0">{acao.descricao}</p> */}
            <p className="text-muted mb-3">{acao.sobre}</p>
            <Row>
              <Col>
                <Card style={{ minHeight: '80%', maxHeight: '80%' }}>
                  <CardBody className="p-1 m-1">
                    <div className="d-flex flex-wrap">
                      <div className="me-3 text-center">
                        <p className={`text-muted text-center mb-2`}>
                          {'Negociação'}
                        </p>
                        <h6 className={`mb-0 `}>
                          {`${acao.exchange}`}
                        </h6>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card style={{ minHeight: '80%', maxHeight: '80%' }}>
                  <CardBody className="p-1 m-1">
                    <div className="d-flex flex-wrap">
                      <div className="me-3 text-center">
                        <p className={`text-muted mb-2`}>
                          {'Região'}
                        </p>
                        <h6 className={`mb-0 `}>
                          {`${acao.regiao}`}
                        </h6>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card style={{ minHeight: '80%', maxHeight: '80%' }}>
                  <CardBody className="p-1 m-1">
                    <div className="d-flex text-center flex-wrap">
                      <div className="me-3 text-center">
                        <p className={`text-muted mb-2`}>
                          {'Moeda'}
                        </p>
                        <h6 className={`mb-0 `}>
                          {`${acao.moeda}`}
                        </h6>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </CardBody>
    </React.Fragment>
  )
}

export default CompanyInfo;
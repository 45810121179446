import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "contexts/auth-context/auth-context";
import { UserContext } from "contexts/user-context/user-context";

const Logout = () => {
  const { setToken, setRefresh, setUserStatus } = useContext(AuthContext)
  const { setUserInfo } = useContext(UserContext)
  const history = useNavigate();

  useEffect(() => {
    try {
      localStorage.removeItem('@user');
      setToken(null);
      localStorage.removeItem('@refreshToken');
      setRefresh(null);
      localStorage.removeItem('@status');
      setUserStatus(null);
      setUserInfo(null);

      history('/login');
      /* window.location.reload() */
    } catch (error) {
    }
  }, []);

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);
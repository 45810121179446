import React, { useState, createContext, useEffect } from "react";
//import { toast } from 'react-toastify';

export const LocaleContext = createContext();

const LocaleProvider = ({ children }) => {

    const locales = {
        brazil: {
            currency: 'BRL',
            locale: 'pt-BR',
            symbol: 'R$'
        },
        us: {
            currency: 'USD',
            locale: 'en-US',
            symbol: 'U$'
        }
    }

    const [localeOptions, setLocaleOptions] = useState(locales.brazil);
    const [currencyDetail, setCurrencyDetails] = useState(
        {
            currency: 'USD',
            value: 5.40,
            lastUpdate: '2023-01-01 10:23:59'
        }
    );

    return (
        <LocaleContext.Provider value={{
            localeOptions,
            setLocaleOptions
        }}>
            {children}
        </LocaleContext.Provider>
    );
};

export default LocaleProvider;
import { AssetsContext } from "contexts/assets-context/assets-context";
import React, { useContext } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

const Setorizacao = () => {

    const { acao } = useContext(AssetsContext)

    const normalizedData = acao && [
        {
            name: 'Setor',
            item: acao.setor,
            class: 'number',
            balizador: 10,
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'range',
            min: 0.01
        },
        {
            name: 'Subsetor',
            item: acao.subsetor,
            class: 'number',
            balizador: 1.5,
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'menos'
        },
        {
            name: 'Segmento',
            item: acao.segmento,
            class: 'percent',
            balizador: 100,
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'range',
            min: 25
        }
    ]

    return (
        <React.Fragment>
            <Row>
                {normalizedData.map((item, index) => (
                    <Col key={index} >
                        <Card style={{minHeight: '80%', maxHeight: '80%'}}>
                            <CardBody className="p-1 m-1">
                                <div className="d-flex flex-wrap">
                                    <div className="me-3">
                                        <p className={`text-muted mb-2`}>
                                            {item.name}
                                        </p>
                                        <h6 className={`mb-0 `}>
                                            {`${item.item}`}
                                        </h6>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
        </React.Fragment>
    )
}

export default Setorizacao;
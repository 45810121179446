import { formatCurrency } from "helpers/formatCurrency";
import React, { useState, createContext, useEffect } from "react";
//import { toast } from 'react-toastify';
import api from '../../services/api';

export const RankingContext = createContext();

const RankingProvider = ({ children }) => {
    
    const [ranking, setRanking] = useState(null);

    const getRankingByAssetType = async (tipoAtivo) => {
        try {
            const response = await api.get(`/ativos/buscar-rankings?tipoAtivo=${tipoAtivo || 'ACAO'}`);

            if (response.data === null) { return }

            setRanking(response.data);

        } catch (err) {
            /* toast.warn("Não foi possíel recuperar os monstros da base de dados" + err.message); */
            console.error('erro aos buscar rankings' + err)
        }
    };

    return (
        <RankingContext.Provider value={{
            getRankingByAssetType,
            ranking, setRanking
        }}>
            {children}
        </RankingContext.Provider>
    );
};

export default RankingProvider;
import { AuthContext } from "contexts/auth-context/auth-context";
import { PlanContext } from "contexts/plans-context/plans-context";
import React, { useEffect, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";

const Authmiddleware = (props) => {

  const { token, userStatus } = useContext(AuthContext)
  const { selectedPlan } = useContext(PlanContext)

  const location = useLocation();

  const authUnpaidRoutes = ["/purchase-plan", "/profile", "/plans"];
  const isUnpaidRoutes = authUnpaidRoutes.includes(location.pathname);

  useEffect(() => { }, [userStatus, token]);

  if (!token) {

    if (selectedPlan) {
      return (
        <Navigate to={{ pathname: "/register", state: { from: props.location } }} />
      );
    }

    else {
      return (
        <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
      );
    }
  }

  if (token && userStatus === 'INATIVO' && !isUnpaidRoutes) {
    return (
      <Navigate to={{ pathname: "/plans", state: { from: props.location } }} />
    );
  }

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
};

export default Authmiddleware;

import { AssetsContext } from "contexts/assets-context/assets-context";
import React, { useState, useEffect, useContext } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import Bonificacoes from "./Components/bonificacoes";
import DividendChart from "./Components/DividendChart";
import GrupamentosDesdobramentos from "./Components/grupamento-desdobramento";
import ProventosAnuais from "./Components/ProventosAnuais";
import ProventosLista from "./Components/ProventosLista";

const Proventos = () => {

    const {
        getProventosByTicker,
    } = useContext(AssetsContext);

    const [dataRange, setDataRange] = useState({
        inicial: null,
        final: null
    });

    useEffect(() => {
        const agora = new Date();
        const anoAtual = agora.getFullYear();
        const primeiroDiaAnoMenosCinco = new Date(anoAtual - 5, 0, 1);
        const ultimoDiaAnoCorrente = new Date(anoAtual, 11, 31);

        const formattedInicial = `${primeiroDiaAnoMenosCinco.getFullYear()}-${(primeiroDiaAnoMenosCinco.getMonth() + 1).toString().padStart(2, '0')}-${primeiroDiaAnoMenosCinco.getDate().toString().padStart(2, '0')}`;
        const formattedFinal = `${ultimoDiaAnoCorrente.getFullYear()}-${(ultimoDiaAnoCorrente.getMonth() + 1).toString().padStart(2, '0')}-${ultimoDiaAnoCorrente.getDate().toString().padStart(2, '0')}`;

        setDataRange({
            inicial: formattedInicial,
            final: formattedFinal
        });
    }, []);

    useEffect(() => {
        if (dataRange.inicial && dataRange.final) {
            getProventosByTicker(dataRange.inicial, dataRange.final)
        }
    }, [dataRange])

    //Altura do Card

    const [firstCardHeight, setFirstCardHeight] = useState(0);

    useEffect(() => {
        const firstCard = document.getElementById('firstCard');
        if (firstCard) {
            const height = firstCard.clientHeight;
            setFirstCardHeight(height);
        }
    }, []);

    const secondCardStyle = {
        height: firstCardHeight + 'px',
    };

    return (
        <React.Fragment>
            <Row style={{ display: "flex" }}>
                <Col lg={8}>
                    <Card id="firstCard">
                        <CardBody className="p-1">
                            <DividendChart />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card style={secondCardStyle}>
                        <CardBody>
                            <ProventosAnuais />
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row style={{ display: "flex" }}>
                <Col lg={6}>
                    <Card>
                        <CardBody>
                            <GrupamentosDesdobramentos />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card>
                        <CardBody>
                            <Bonificacoes />
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <ProventosLista />
        </React.Fragment>
    )
}

export default Proventos;
import React, { useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { differenceInMilliseconds, formatDistanceStrict, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

//i18n
import { withTranslation } from "react-i18next";
import { UserContext } from "contexts/user-context/user-context";

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const {
    getNotifications,
    notifications, setNotifications,
    changeNotification,
    page, setPage, noMoreNotifications
  } = useContext(UserContext)

  useEffect(() => {
    getNotifications(page)
  }, [])

  const handleLoadMore = () => {
    setPage(page + 1); // Calcular a próxima página
    getNotifications(page);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          {
            notifications && notifications.length > 0 && (
              notifications.some(notification => !notification.status_leitura) ? (
                <i className="bx bx-bell bx-tada" />
              ) : (
                <i className="bx bx-bell" />
              )
            )
          }
          {
            notifications && notifications.length > 0 && (
              notifications.some(notification => !notification.status_leitura) && (
                <span className="badge bg-danger rounded-pill">
                  {notifications.filter(notification => !notification.status_leitura).length}
                </span>
              )
            )
          }
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              {/* <div className="col-auto">
                <a href="#" className="small">
                  {" "}
                  {props.t("View All")}
                </a>
              </div> */}
            </Row>
          </div>

          <SimpleBar style={{ height: "50%" }}>
            {notifications &&
              notifications.length > 0 &&
              notifications.map((item, index) => (
                <div key={item.id || index}
                  className={`${!item.status_leitura ? 'bg-soft bg-info' : ''}`}>
                  <Link
                    to={item.link || ''}
                    className={`text-reset notification-item ${!item.status_leitura ? 'bg-info' : ''}`}
                    onClick={() => {
                      // Verifique se a notificação não está marcada como lida antes de atualizar o estado
                      if (!item.status_leitura) {
                        // Marque a notificação como lida localmente
                        const updatedNotifications = [...notifications];
                        updatedNotifications[index] = { ...item, status_leitura: true };
                        setNotifications(updatedNotifications);

                        changeNotification(item.id);
                        getNotifications(page);
                      }
                    }}
                  >
                    <div className="d-flex bg-soft">
                      <div className="avatar-xs me-3 rounded-circle">
                        <span className="avatar-title bg-primary rounded-circle font-size-14">
                          <i className="bx bx-message-dots" />
                        </span>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mt-0 mb-1"
                          style={
                            {
                              maxWidth: '230px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                            }
                          }>
                          {item.titulo}
                        </h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1"
                            style={
                              {
                                maxWidth: '230px',
                                maxHeight: '40px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'wrap'
                              }
                            }
                          >
                            {item.mensagem}
                          </p>
                          <CalculaDataCadastro dataCadastro={item.data_cadastro} />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            {!noMoreNotifications ? <div className="btn btn-sm btn-link font-size-14 text-center" onClick={() => { handleLoadMore() }}>
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{props.t("Ver Mais")}</span>
            </div>
              :
              <div className="btn btn-sm font-size-14 text-center" >
                <i className="bx bx-info-circle me-1"></i> <span key="t-no-more">{props.t("Isso é Tudo")}</span>
              </div>}
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

function CalculaDataCadastro({ dataCadastro }) {
  // Parse da data de notificação para um objeto Date
  const dataNotificacao = parseISO(dataCadastro);

  // Data atual
  const dataAtual = new Date();

  // Calcula a diferença em milissegundos entre as duas datas
  const diferencaEmMilissegundos = differenceInMilliseconds(dataAtual, dataNotificacao);

  // Função para calcular a diferença formatada com localização pt-BR
  const calcularDiferencaFormatada = () => {
    return formatDistanceStrict(dataNotificacao, dataAtual, { locale: ptBR });
  };

  return (
    <div>
      <p className="mb-0">
        <i className="mdi mdi-clock-outline" />{' '}
        {calcularDiferencaFormatada()}
      </p>
    </div>
  );
}

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};
import toast from "helpers/toast";
import React, { useState, createContext, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import api from '../../services/api';
import { AuthContext } from "contexts/auth-context/auth-context";

export const UserContext = createContext();

const UserProvider = ({ children }) => {

  const navigate = useNavigate();

  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0); // Número da página atual
  const [noMoreNotifications, setNoMoreNotifications] = useState(false); // Flag para indicar que não há mais notificações

  const [userInfo, setUserInfo] = useState(null);

  const [phoneInfo, setPhoneInfo] = useState(
    { codigo_pais: "55", ddd: "", telefone: "" }
  )

  const [addressInfo, setAddressInfo] = useState(
    { cidade: '', rua: '', numero: '', bairro: '', complemento: '', país: 'BR', estado: '', cep: '' }
  );

  useEffect(() => {
    if (userInfo) {
      localStorage.setItem('@status', userInfo.status_pagamento);
    }
  }, [userInfo])

  const getUser = async () => {
    try {
      const response = await api.get('user');
      setUserInfo(response.data)

    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 400:
            // Tratamento para erro de requisição inválida (Bad Request)
            //setError("Sua requisição é inválida. Contate o suporte!");
            break;
          /* case 401:
            // Tratamento para erro de autenticação (Unauthorized)
            setError("Sua senha está incorreta ou este não é seu e-mail cadastrado!");
            break;
          case 403:
            // Tratamento para erro de acesso proibido (Forbidden)
            setError("Você não tem permissão para acessar");
            break;
          case 404:
            // Tratamento para erro de recurso não encontrado (Not Found)
            setError("Erro 404: Recurso não encontrado.");
            break; */
          case 500:
            // Tratamento para erro interno do servidor (Internal Server Error)
            //setError("Erro interno do servidor.");
            break;
          default:
            // Tratamento para outros erros não especificados acima
            setError("Contate o suporte. Erro desconhecido:", error.response.status);
        }
      } else {
        // Tratamento para erros que não sejam de requisição HTTP

        setError("Contate o suporte. Erro desconhecido:", error);
      }

    }
  };

  const registerUser = async (values) => {
    try {

      const response = await api.post(`user`, values)
      return response;

    } catch (error) {
      setError(error);
    }
  }

  const updateUser = async (values) => {
    try {
      const response = await api.put(`user`, values)
      toast.success('Usuário atualizado com sucesso')
      return { success: true, data: response.data };
    } catch (error) {
      toast.fail('Erro ao atualizar usuário' + error.message)
      setError(error);
    }
  }

  const recoverPassword = async (email) => {
    try {
      await api.post(`user/esqueci-senha?email=${email}`)
    } catch (error) {
      setError(error);
    }
  }

  const getNotifications = async (pageToFetch) => {
    try {
      const response = await api.get(`notificacoes?pagina=${pageToFetch}`);
      const newNotifications = response.data;
      if (newNotifications.length > 0) {
        // Concatenar as novas notificações com as existentes
        setNotifications((prevNotifications) => [...prevNotifications, ...newNotifications]);
        setPage(pageToFetch + 1); // Incrementar o número da próxima página
      } else {
        // Não há mais notificações para mostrar
        setNoMoreNotifications(true);
      }
    } catch (error) {
      setError(error);
    }
  };

  const changeNotification = async (id) => {
    try {
      await api.put(`notificacoes?id_notificacao=${id}&status_leitura=true`)
      getNotifications(0)
    } catch (error) {
      setError(error);
    }
  }

  const registerSignature = async (values, plan) => {
    try {
      const response = await api.post(`pagamentos?plano-id=${plan}`, values)
      toast.success('Plano ativado')
      return response.data
    } catch (error) {
      toast.fail('Erro ao processar pagamento' + error)
      setError(error);
    }
  }

  const cancelSignature = async () => {
    try {
      await api.delete(`pagamentos`)
      toast.warning('Plano de assinatura cancelado')
      navigate('/')
    } catch (error) {
      toast.fail('Erro ao cancelar assinatura')
      setError("Não foi possível cancelar a assinatura: ", error);
    }
  }

  const confirmEmail = async (value) => {
    try {
      await api.post(`user/verifica-token-email?tokenEmail=${value}`)
      toast.success('E-mail verificado')
      navigate('/profile')
    } catch (error) {
      toast.fail('Erro ao processar validação de e-mail' + error)
      setError(error);
    }
  }

  const resendEmailValidation = async () => {
    try {
      await api.post(`user/reenvia-token-email`)
      toast.success('E-mail reenviado')
    } catch (error) {
      toast.fail('Erro ao processar o reenvio' + error)
      setError(error);
    }
  }

  const importB3 = async () => {
    try {
      await api.put(`b3`)

      toast.success('Sucesso: Em até 48 horas seus dados estarão disponíveis')

      await getUser()

    } catch (error) {
      toast.fail('Erro ao processar' + error)
      setError(error);
    }
  }


  return (
    <UserContext.Provider value={{
      userInfo,
      setUserInfo,
      phoneInfo,
      setPhoneInfo,
      addressInfo,
      setAddressInfo,
      getUser,
      registerUser,
      updateUser,
      recoverPassword,
      getNotifications,
      notifications,
      setNotifications,
      registerSignature,
      changeNotification,
      page, setPage, noMoreNotifications,
      cancelSignature,
      confirmEmail,
      resendEmailValidation,
      importB3
    }}>
      {children}
    </UserContext.Provider>
  )

};

export default UserProvider;
import { AuthContext } from 'contexts/auth-context/auth-context'
import { PlanContext } from 'contexts/plans-context/plans-context'
import PropTypes from 'prop-types'
import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col } from "reactstrap"

const CardPricing = ({ item }) => {

  const { setSelectedPlan } = useContext(PlanContext)
  const { token, userStatus } = useContext(AuthContext)

  const isDisabled =
    userStatus === item.userStatus ||
    (userStatus === "PLANO_INTERMEDIARIO" && item.userStatus !== "PLANO_MASTER") ||
    (userStatus === "PLANO_MASTER");

  return (
    <React.Fragment>
      <Col xl="3" md="6">
        <Card className="plan-box">
          <CardBody className="p-4" style={isDisabled ? { backgroundColor: '#80808054' } : null}>
            <div className="d-flex">
              <div className="flex-grow-1">
                <h5>{item.title}</h5>
                <p className="text-muted">{item.description}</p>
              </div>
              <div className="flex-shrink-0 ms-3">
                <i
                  className={"bx " + item.icon + " h1 text-primary"} style={{ lineHeight: 1 }}
                />
              </div>
            </div>
            <div className="py-3">
              <h2>{/* 
                <sup>
                  <small>$</small>
                </sup>{" "} */}
                {item.price}/{" "}
                <span className="font-size-13">{item.duration}</span>
              </h2>
            </div>
            {isDisabled ?
              <div className="text-center plan-btn">
                <Link style={{ backgroundColor: '#808080', borderColor: '#808080' }}
                  to={'#'}
                  className="btn btn-success btn-sm waves-effect waves-light "
                >
                  Adquirido
                </Link>
              </div>
              :
              <div className="text-center plan-btn"
                onClick={() => {
                  setSelectedPlan(item)
                }}
              >
                <Link
                  to={token ? item.link : '/register'}
                  className="btn btn-success btn-sm waves-effect waves-light "
                >
                  Escolher Plano
                </Link>
              </div>
            }

            <div style={{ marginTop: "10px" }}>
              <p className={`text-center ${item.garantia.collor}`} style={{ margin: "0px" }}>
                <i className={`${item.garantia.icon}  ${item.garantia.collor}`} />
                {" "}
                <span>{item.garantia.title}</span>
              </p>
            </div>

            <div className="plan-features mt-4">
              {item.features.map((feature, key) => (
                <p key={"_feature_" + key}>
                  <i className={`bx 
                  ${feature.icon ?? 'bx-checkbox-square'} 
                  ${feature.collor ?? 'text-primary'}
                  me-2`}
                  />{" "}
                  <span style={feature.icon ? { fontWeight: 600 } : null}>{feature.title}</span>
                </p>
              ))}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

CardPricing.propTypes = {
  item: PropTypes.object
}

export default CardPricing

import React, { useEffect, useContext, useState } from "react";
import CardPricing from "pages/Utility/card-pricing";
import { Container, Row, Col } from "reactstrap";
import { PlanContext } from "contexts/plans-context/plans-context";
import { Link } from "react-router-dom";
import { AuthContext } from "contexts/auth-context/auth-context";

const Plans = () => {

    const { plan, pricings, getPaymentPlan } = useContext(PlanContext);
    const { token, userStatus } = useContext(AuthContext)

    const [showHome, setShowHome] = useState(false)

    useEffect(() => {
        if (!plan) {
            getPaymentPlan()
        }
    }, [])

    useEffect(() => {
        setShowHome(token && (userStatus && userStatus !== "INATIVO"))
    }, [token, userStatus])

    return (
        <React.Fragment>
            {showHome &&
                (<div className="home-btn d-none d-sm-block">
                    <Link to="/" className="text-dark">
                        <i className="bx bx-home h2" />
                    </Link>
                </div>)}
            <div className="page-content" style={{ padding: '3.5%' }}>
                <Container fluid>

                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <div className="text-center mb-5">
                                <h4>Plano Premium</h4>
                                <p className="text-muted">
                                    Escolha seu plano para ter o acesso desejado.
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        {pricings.map((item, key) => (
                            <CardPricing item={item} key={"_pricing_" + key} />
                        ))}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Plans;
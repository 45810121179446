import { AssetsContext } from "contexts/assets-context/assets-context";
import React, { useContext } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getClassByValue } from "../Helper/helper-fundamentals";

const Fundamentals = () => {

    const {
        acao
    } = useContext(AssetsContext);

    const normalizedData = acao && [
        {
            name: 'P/L',
            item: acao.preco_lucro,
            class: 'number',
            balizador: 10,
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'range',
            min: 0.01
        },
        {
            name: 'P/VP',
            item: acao.preco_valor_patrimonial_acao,
            class: 'number',
            balizador: 1.5,
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'menos'
        },
        {
            name: 'Payout',
            item: acao.payout,
            class: 'percent',
            balizador: 100,
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'range',
            min: 25
        }
    ]

    return (
        <React.Fragment>
            <div className="d-flex">
                <h4 className="card-title mb-4 flex-grow-1">Resumo Fundamentalistas</h4>
            </div>
            <Row>
                {normalizedData.map((item, index) => (
                    <Col key={index}>
                        <Card>
                            <CardBody className="p-1 m-1">
                                <div className="d-flex flex-wrap">
                                    <div className="me-3">
                                        <p className={`text-muted mb-2`}>
                                            <i className={`mdi mdi-circle align-middle font-size-10 me-1 ${getClassByValue(item.item, item.balizador, item.min, item.melhor)}`} />
                                            {item.name}
                                        </p>
                                        <h5 className={`mb-0 `}>
                                            {`${Number(item.item).toFixed(2)}${item.class === 'percent' ? '%' : ''}`}
                                        </h5>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
        </React.Fragment>
    )
}

export default Fundamentals;
import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardBody,
  Table,
  Badge,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
//Simple bar
import SimpleBar from "simplebar-react"
import { Link, useParams } from "react-router-dom";
import { formatCurrency } from "helpers/formatCurrency";
import { formatData, formatDataBarra, formatDataYYYYMMDD } from "helpers/formatDateTime";
import { AssetsContext } from "contexts/assets-context/assets-context";

import { LayoutContext } from "contexts/layout-context/layout-contex";

const ProventosLista = () => {

  const {
    proventos,
  } = useContext(AssetsContext);

  const { isMobile } = useContext(LayoutContext);

  const agora = new Date();

  const [str, setStf] = useState(7);
  const [popoverdismiss, setPopoverdismiss] = useState(false);
  const [p_m, setP_M] = useState('');
  const [fontSize, setFontSize] = useState('');

  useEffect(() => {
    if (isMobile) {
      setStf(7);
      setP_M('ps-2 pe-1 ms-1 me-1')
      setFontSize('font-size-10')
    }
    else {
      setStf(18)
      setP_M('')
      setFontSize('')
    }
  }, [isMobile])

  return (
    <React.Fragment>
      <Col xl="12" style={{ display: "flex", flexDirection: "column" }}>
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Proventos Históricos</h4>
            <SimpleBar style={{ maxHeight: "50%", minHeight: "30%" }}>
              <div className="table-responsive table-sticky">
                <Table className={`table table-nowrap align-middle mb-0 ${fontSize}`}>
                  <thead>
                    <tr>
                      <th scope="col" className={`${p_m}`}>Tipo</th>
                      <th scope="col" className={`${p_m}`}>Data Com</th>
                      <th scope="col" className={`${p_m}`}>Pagamento</th>
                      <th scope="col" className={`${p_m}`}>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {proventos && proventos.map((provento, index) => (
                      <tr key={index} >
                        <th scope="row" className={`${p_m}`}>
                          <div className="d-flex align-items-center">
                            <div
                              className={`avatar-xs me-2 rounded-circle`}
                              style={isMobile ? { maxWidth: '1.5rem', maxHeight: '1.5rem' } : {}}>
                              <span
                                className={
                                  `avatar-title 
                              rounded-circle 
                              bg-soft bg-${provento.tipo_pagamento === "DIVIDENDO" ? 'success' : 'primary'} 
                              text-${provento.tipo_pagamento === "DIVIDENDO" ? 'success' : 'primary'}
                              font-size-16`
                                }
                              >
                                <i className={"bx bx-dollar-circle"} />
                              </span>
                            </div>
                            <span
                              className={`
                            ${formatDataYYYYMMDD(provento.data_com) > agora ? "text-success" :
                                  formatDataYYYYMMDD(provento.data_pagamento) > agora ? "text-warning" : ' '
                                }
                          `}
                            >{provento.tipo_pagamento.substring(0, str - 3) +
                              (provento.tipo_pagamento.length >= str ? ' ...' : '')}</span>
                          </div>
                        </th>
                        <td
                          className={`${p_m}
                            ${formatDataYYYYMMDD(provento.data_com) > agora ? "text-success" :
                              formatDataYYYYMMDD(provento.data_pagamento) > agora ? "text-warning" : ' '
                            }
                          `}
                        >
                          <div className="mb-1">
                            {formatDataBarra(provento.data_com)}
                          </div>
                        </td>
                        <td className={`${p_m}
                            ${formatDataYYYYMMDD(provento.data_com) > agora ? "text-success" :
                            formatDataYYYYMMDD(provento.data_pagamento) > agora ? "text-warning" : ' '
                          }
                          `}
                        >
                          <div className="mb-1">
                            {formatDataBarra(provento.data_pagamento)}
                          </div>

                        </td>
                        <td className={`${p_m}
                            ${formatDataYYYYMMDD(provento.data_com) > agora ? "text-success" :
                                  formatDataYYYYMMDD(provento.data_pagamento) > agora ? "text-warning" : ' '
                                }
                          `}>
                          <div className="mb-1 align-middle">
                            {provento.valorDesdobramentoGrupamento && provento.valor !== provento.valorDesdobramentoGrupamento ? (
                              <p className={`m-0 p-0`}>
                                {formatCurrency(provento.valorDesdobramentoGrupamento, 'pt-BR', 'BRL', 4, 4)}
                                <button
                                  id={`Popoverdismiss${index}`}
                                  className="btn bx bxs-info-circle text-warning cursor-pointer"
                                  onClick={() => {
                                    setPopoverdismiss(!popoverdismiss);
                                  }}
                                  onMouseEnter={() => setPopoverdismiss(true)}
                                  onMouseLeave={() => setPopoverdismiss(false)}
                                >
                                </button>
                                <UncontrolledPopover trigger="focus" target={`Popoverdismiss${index}`} placement="top">
                                  <PopoverHeader>{'Desdobramento ou Grupamento'}</PopoverHeader>
                                  <PopoverBody>
                                    {`O valor distribuído foi alterado em razão de um 
                                        desdobramento ou grupamento de ${formatCurrency(provento.valor, 'pt-BR', 'BRL', 4, 4)} 
                                        para ${formatCurrency(provento.valorDesdobramentoGrupamento, 'pt-BR', 'BRL', 4, 4)}`}
                                  </PopoverBody>
                                </UncontrolledPopover>
                              </p>
                            ) : (
                              formatCurrency(provento.valor, 'pt-BR', 'BRL', 4, 4)
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </SimpleBar>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment >
  );
}

export default ProventosLista;
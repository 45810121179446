import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
    Badge,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
} from "reactstrap";

import { AssetsContext } from "contexts/assets-context/assets-context";

const ProjectStatus = ({ status }) => {
    switch (status) {
        case "Pending":
            return <Badge className="bg-warning"> {status} </Badge>;

        case "Delay":
            return <Badge className="bg-danger"> {status} </Badge>;

        case "Completed":
            return <Badge className="bg-success"> {status} </Badge>;

        default:
            return <Badge className="bg-success"> {status} </Badge>;
    }
};

const RankinDetalhado = () => {

    document.title = "Ranking Detalhado | Evolue";

    const { detailedRanking, getDetailedRanking, reference } = useContext(AssetsContext);

    const tipo = 'ACAO';

    useEffect(() => {
        if (tipo && reference.id) {
            getDetailedRanking(tipo, reference.id)
        }
    }, [reference])



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Row>
                        <Col lg="12">
                            <div >
                                <div className="table-responsive">
                                    <Table className="project-list-table table-nowrap align-middle table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ width: "100px" }}>
                                                    {' '}
                                                </th>
                                                <th scope="col">Ativo</th>
                                                <th scope="col">Preço</th>
                                                <th scope="col">{reference.tab_total}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {detailedRanking && detailedRanking.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <img
                                                            src={item.image}
                                                            alt=""
                                                            className="avatar-sm"
                                                        />
                                                    </td>
                                                    <td>
                                                        <h5 className="text-truncate font-size-14">
                                                            {item.symbol}
                                                        </h5>
                                                        <p className="text-muted mb-0">
                                                            {item.nome_longo}
                                                        </p>
                                                    </td>
                                                    <td> {item.cotacao}</td>
                                                    <td>
                                                        {item[reference.item_referencia]}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>

                   {/*  <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                    Load more
                                </Link>
                            </div>
                        </Col>
                    </Row> */}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(RankinDetalhado);

import toast from "helpers/toast";
import React, { useState, createContext } from "react";
import api from '../../services/api';

export const PlanContext = createContext();

const PlanProvider = ({ children }) => {

    const [selectedPlan, setSelectedPlan] = useState(null);
    const [plan, setPlan] = useState(null
        /* [
            {
                ativo: false,
                data_atualizacao: "",
                data_criacao: "",
                descricao: "",
                esquema_precificacao: "",
                intervalo_cobranca: 0,
                metodo_pagamento: "",
                moeda: "",
                nome: "",
                parcelamento: 0,
                plano_id: "",
                preco: 0,
                quantidade: 0,
                recorrencia: "",
                tipo_cobranca: "",
                url: ""
            }
        ] */
    )

    const getPaymentPlan = async () => {
        try {
            const response = await api.get(`pagamentos/plano`)
            setPlan(response.data)
        } catch (error) {
            toast.fail(error);
        }
    }

    const planIndexMap = {};
    const pricings = []

    if (plan) {
        plan.forEach((item, index) => {
            planIndexMap[item.plano_id] = index;
        });

        pricings.push({
            id: plan[planIndexMap[plan[0].plano_id]].plano_id,
            title: "Básico",
            description: "Tudo o que você precisa para começar a lucrar",
            icon: "bx-file-find",
            price: (Number(plan[0].preco) / 100 / 12).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            duration: "mês x 12",
            garantia: { title: "Cancelamento Grátis 30 dias", icon: 'bx bxs-badge-check', collor: 'text-warning' },
            link: "/purchase-plan",
            features: [
                { title: "Análise de Ações e FIIs" },
                { title: "Controle de Carteira" },
                { title: "Importação de Carteira da B3" },
                { title: "Filtros por Indicadores" },
                { title: "Favoritos" },
                { title: "Suporte por Whatsapp" }
            ],
            props: plan[planIndexMap[plan[0].plano_id]],
            userStatus: "PLANO_BASICO"
        });

        pricings.push({
            id: plan[planIndexMap[plan[1].plano_id]].plano_id,
            title: "Melhorado",
            description: "Tudo o que você precisa para ir além e lucrar mais",
            icon: "bx-analyse",
            price: (Number(plan[1].preco) / 100 / 12).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            duration: "mês x 12",
            garantia: { title: "Cancelamento Grátis 30 dias", icon: 'bx bxs-badge-check', collor: 'text-warning' },
            link: "/purchase-plan",
            features: [
                { title: "Tudo do plano anterior", icon: 'bx bxs-badge-check', collor: 'text-success' },
                { title: "Módulo de Opções" },
                { title: "Rankings" },
                { title: "Próximas Data-Com" },
                { title: "Direito de Votação para Novas Funcionalidades" },
            ],
            props: plan[planIndexMap[plan[1].plano_id]],
            userStatus: "PLANO_INTERMEDIARIO"
        },);

        pricings.push({
            id: plan[planIndexMap[plan[2].plano_id]].plano_id,
            title: "Master",
            description: "Tudo para lucrar sem dor de cabeça com os impostos",
            icon: "bx-crown",
            price: (Number(plan[2].preco) / 100 / 12).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            duration: "mês x 12",
            garantia: { title: "Cancelamento Grátis 30 dias", icon: 'bx bxs-badge-check', collor: 'text-warning' },
            link: "/purchase-plan",
            features: [
                { title: "Tudo dos planos anteriores", icon: 'bx bxs-badge-check', collor: 'text-success' },
                { title: "Módulo de Impostos" },
                { title: "Cálculo de DARF Mensal" },
            ],
            props: plan[planIndexMap[plan[2].plano_id]],
            userStatus: "PLANO_MASTER"
        });
    }

    return (
        <PlanContext.Provider value={{
            pricings,
            getPaymentPlan,
            plan, setPlan,
            selectedPlan, setSelectedPlan
        }}>
            {children}
        </PlanContext.Provider>
    )
};

export default PlanProvider;
import { AssetsContext } from "contexts/assets-context/assets-context";
import { formatCurrency } from "helpers/formatCurrency";
import { formatarNumeroComUnidades } from "helpers/formatNumbers";
import React, { useState, useEffect, useContext } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

const DadosCompanhia = () => {
    const sm = 3;

    const {
        acao,
        assetInfo,
        getAssetInfo,
        tickerType
    } = useContext(AssetsContext);

    useEffect(() => {
        getAssetInfo()
    }, [])

    const normalizedData = assetInfo && [
        {
            name: 'Valor de Mercado',
            item: assetInfo.valor_mercado,
            balizador: '',
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: ''
        },
        {
            name: 'Valor de Firma',
            item: 0,
            balizador: '',
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: ''
        },
        {
            name: 'Ativos',
            item: assetInfo.ativo_total,
            balizador: '',
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: ''
        },
        {
            name: 'Ativo Circulante',
            item: assetInfo.ativo_circulante,
            balizador: '',
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: ''
        },
        {
            name: 'Patrimônio Líquido',
            item: assetInfo.patrimonio_liquido_consolidado,
            balizador: '',
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: ''
        },
        {
            name: 'Dívida Bruta',
            item: assetInfo.divida_bruta,
            balizador: '',
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: ''
        },
        {
            name: 'Dívida Líquida',
            item: assetInfo.divida_liquida,
            balizador: '',
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: ''
        },
        {
            name: 'Disponibilidade',
            item: assetInfo.disponibilidade,
            balizador: '',
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: ''
        },
    ]

    const fiiData = assetInfo && [
        {
            name: 'Valor de Mercado',
            item: assetInfo.valor_mercado,
            balizador: '',
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: ''
        },
        {
            name: 'Patrimônio Líquido',
            item: assetInfo.patrimonio_liquido_consolidado,
            balizador: '',
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: ''
        }
    ]

    return (
        <React.Fragment>
            <CardBody>
                <div className="d-flex">
                    <h4 className="card-title mb-4 flex-grow-1">{tickerType === 'acoes' ? 'Finanças da Empresa' : 'Finanças do Fundo'}</h4>
                </div>
                <Row>
                    {assetInfo && (tickerType === 'acoes' ? normalizedData : fiiData).map((item, index) => (
                        <Col key={index} sm={sm}>
                            <div>
                                <p className="text-muted mb-2">{item.name}</p>
                                <h5>{`${'R$ '}`}{formatarNumeroComUnidades(Number(item.item))}</h5>
                                <p className="d-flex nowrap">{formatCurrency(Number(item.item), 'pt-BR', 'BRL')}</p>

                            </div>
                        </Col>
                    ))}
                </Row>
            </CardBody>
        </React.Fragment>
    )
}

export default DadosCompanhia;
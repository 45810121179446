import { formatCurrency } from "helpers/formatCurrency";
import React, { useState, createContext, useEffect } from "react";
//import { toast } from 'react-toastify';
import api from '../../services/api';
import toast from "helpers/toast";
import fiiImage from '../../assets/images/fii-image.png'

export const WalletContext = createContext();

const WalletProvider = ({ children }) => {

    const [fiis, setFiis] = useState(null)
    const [options, setOptions] = useState(null)
    const [stocks, setStocks] = useState(null)
    const [acoes, setAcoes] = useState(null);
    const [assetType, setAssetType] = useState('acoes');
    const [selectedAsset, setSelectedAsset] = useState('');
    const [extract, setExtract] = useState([]);
    const [dividend, setDividend] = useState([]);
    const [fiisDividendTotal, setFiisDividendTotal] = useState(0);
    const [acoesDividendTotal, setAcoesDividendTotal] = useState(0);

    const getAcoes = async (page) => {
        try {
            const response = await api.get(`/acoes${page ? `?pagina=${page}` : '?pagina=0'}`);

            if (response.data === null) { return }

            setAcoes(response.data);

        } catch (err) {
            /* toast.warn("Não foi possíel recuperar os monstros da base de dados" + err.message); */
            console.error('erro aos buscar açoes' + err)
        }
    };

    const registerAcao = async (values, method) => {
        const requestMethod = method ? method : 'post';
        try {
            const response = await api[requestMethod](`/acoes${requestMethod === 'put' ? `?id=${values.id}` : ''}`, values);
            requestMethod === 'post' ? toast.success('Registro efetuado') : toast.success('Alteração efetuada');
            getAcoes();
            selectStock();
            selectProventos();

            return { success: true, data: response.data };

        } catch (err) {
            toast.fail(`Erro no registro: ${err}`)
            return { success: false, error: err.message };
        }
    };

    const getFiis = async (page) => {
        try {
            const response = await api.get(`/fiis${page ? `?pagina=${page}` : '?pagina=0'}`);

            if (response.data === null) { return }

            setFiis(response.data);

        } catch (err) {
            /* toast.warn("Não foi possíel recuperar os monstros da base de dados" + err.message); */
            console.error('erro aos buscar açoes' + err)
        }
    };

    const registerFii = async (values, method) => {
        const requestMethod = method ? method : 'post';
        try {
            const response = await api[requestMethod](`/fiis${requestMethod === 'put' ? `?id=${values.id}` : ''}`, values);
            requestMethod === 'post' ? toast.success('Registro efetuado') : toast.success('Alteração efetuada');

            getFiis();
            selectFii();
            selectProventosFii();

            return { success: true, data: response.data };

        } catch (err) {
            toast.fail(`Erro no registro: ${err}`)
            return { success: false, error: err.message };
        }
    };

    const selectStock = async () => {
        try {
            const response = await api.get(`/acoes/extrato${selectedAsset.length > 0 ? `?ticker=${selectedAsset.toUpperCase()}` : null}`);

            if (response.data === null) { return }

            setExtract(response.data);

        } catch (err) {
            /* toast.warn("Não foi possíel recuperar os monstros da base de dados" + err.message); */
            console.error('erro aos buscar açoes' + err)
        }
    };

    const selectProventos = async () => {
        try {
            const response = await api.get(`/acoes/dividendos${selectedAsset.length > 0 ? `?ticker=${selectedAsset.toUpperCase()}` : null}`);

            if (response.data === null) { return }

            setDividend(response.data);

        } catch (err) {
            /* toast.warn("Não foi possíel recuperar os monstros da base de dados" + err.message); */
            console.error('erro aos buscar açoes' + err)
        }
    };

    const selectFii = async () => {
        try {
            const response = await api.get(`/fiis/extrato${selectedAsset.length > 0 ? `?ticker=${selectedAsset.toUpperCase()}` : null}`);

            if (response.data === null) { return }

            setExtract(response.data);

        } catch (err) {
            /* toast.warn("Não foi possíel recuperar os monstros da base de dados" + err.message); */
            console.error('erro aos buscar açoes' + err)
        }
    };

    const selectProventosFii = async () => {
        try {
            const response = await api.get(`/fiis/dividendos${selectedAsset.length > 0 ? `?ticker=${selectedAsset.toUpperCase()}` : null}`);

            if (response.data === null) { return }

            setDividend(response.data);

        } catch (err) {
            /* toast.warn("Não foi possíel recuperar os monstros da base de dados" + err.message); */
            console.error('erro aos buscar açoes' + err)
        }
    };

    const getAcoesTotalDividend = async () => {
        try {
            const response = await api.get(`/acoes/dividendos-total`);

            if (response.data === null) { return }

            setAcoesDividendTotal(response.data);

        } catch (err) {
            /* toast.warn("Não foi possíel recuperar os monstros da base de dados" + err.message); */
            console.error('erro aos buscar açoes' + err)
        }
    };

    const getFiisTotalDividend = async () => {
        try {
            const response = await api.get(`/fiis/dividendos-total`);

            if (response.data === null) { return }

            setFiisDividendTotal(response.data);

        } catch (err) {
            /* toast.warn("Não foi possíel recuperar os monstros da base de dados" + err.message); */
            console.error('erro aos buscar açoes' + err)
        }
    };

    //DELETE

    const deleteRegisterById = async (assetType, id) => {
        try {
            await api.delete(`/${assetType}?id=${id}`) // acoes | fiis

            assetType === 'acoes' ? (getAcoes(), selectStock(), selectProventos()) : (getFiis(), selectFii(), selectProventosFii());
            toast.success(`Registro excluído com sucesso`)

        } catch {
            toast.fail(`Erro ao deletar registro: ${err}`)
        }
    }

    return (
        <WalletContext.Provider value={{
            acoes,
            setAcoes,
            getAcoes,
            registerAcao,
            selectStock,
            selectProventos,
            selectedAsset,
            extract,
            dividend,
            setSelectedAsset,
            fiis,
            getFiis,
            registerFii,
            assetType,
            setAssetType,
            selectFii,
            selectProventosFii,
            deleteRegisterById,
            getAcoesTotalDividend, getFiisTotalDividend, fiisDividendTotal, acoesDividendTotal
        }}>
            {children}
        </WalletContext.Provider>
    );
};

export default WalletProvider;
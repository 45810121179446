import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import withRouter from "components/Common/withRouter";
import "assets/scss/datatables.scss";

//Import Breadcrumb
import WalletActivities from "./OptionWallet/optionActivities";

//redux & context
import { formatCurrency } from "helpers/formatCurrency";
import { OptionContext } from "contexts/options-context/options-context";
import { AuthContext } from "contexts/auth-context/auth-context";
import PayWall from "components/PayWall/PayWall";

const Option = () => {

  //meta title
  document.title = "Option ";

  const { userStatus } = useContext(AuthContext)

  const {
    extract,
    heroInformation,
  } = useContext(OptionContext)

  const [totalNotional, setTotalNotional] = useState(null)
  const [totalPremios, setTotalPremios] = useState(null)
  const [rentabilidade, setRentabilidade] = useState(0);

  useEffect(() => {
    const notional = heroInformation.notional ?? 0;
    const premios = heroInformation.premios ?? 0
    setTotalNotional(Number(notional));
    setTotalPremios(Number(premios));
    setRentabilidade(CalcRentabilidade(premios, notional))
  }, [extract, heroInformation]);

  const CalcRentabilidade = (totalPremios, totalNotional) => {
    // Verifica se valorTotal não é zero para evitar divisão por zero
    if (totalNotional !== 0) {
      // Calcula o percentual
      const percentual = (totalPremios / totalNotional) * 100;
      // Formata o percentual com duas casas decimais
      const percentualFormatado = percentual.toFixed(2) + '%';
      return percentualFormatado;
    } else {
      // Se o valor total for zero, retorna uma mensagem indicando que não é possível calcular o percentual
      return 'Não é possível calcular o percentual quando o valor total é zero.';
    }
  }

  const userStatusPaywall = ["PLANO_INTERMEDIARIO", "PLANO_MASTER"]

  if (!userStatus || !userStatusPaywall.includes(userStatus.replace(/["']/g, ""))) {
    return <PayWall nivel={["Melhorado", "Master"]} />;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs title="Option" breadcrumbItem="Option" /> */}
          <Row>
            {/* <Col xl="4">
                <WalletStats
                  wallet={wallet}
                  isMenu={isMenu}
                  toggleMenu={toggleMenu}
                />
              </Col> */}
            {/* <Col xl="8"> */}
            <Col xl="12">
              <Row>
                <Col sm="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="me-3 align-self-center">
                          <i className="mdi mdi-alpha-n-circle-outline h2 text-warning mb-0" />
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-2">Notional</p>
                          <h5 className="mb-0">
                            {(totalNotional
                              ? formatCurrency(
                                (totalNotional), 'pt-BR', 'BRL') : 'R$ 0,00')}
                          </h5>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="me-3 align-self-center">
                          <i className="mdi mdi-currency-usd-circle-outline h2 text-info mb-0" />
                        </div>
                        <div className="flex-grow-1">
                          <div className="d-flex">
                            <p className="text-muted mb-2">Prêmios</p>
                            {
                              /* totalNotional && totalPremios &&
                              <span className={`badge ${badgeClass} ms-2`}>
                                {differencePercentage}
                              </span> */
                            }
                          </div>

                          <h5 className="mb-0">
                            {(totalPremios
                              ? formatCurrency(
                                (totalPremios), 'pt-BR', 'BRL') : 'R$ 0,00')}
                          </h5>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="me-3 align-self-center">
                          <i className="mdi mdi-brightness-percent h2 text-info mb-0" />
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-2">Rentabilidade</p>
                          <h5 className="mb-0">
                            {totalNotional && totalPremios ? rentabilidade : '0 %'}
                          </h5>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* <WalletOverView wallet={wallet} /> */}
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <WalletActivities />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Option.propTypes = {
  wallet: PropTypes.any,
  onGetWallet: PropTypes.func,
};

export default withRouter(Option);

import React, { useState, useEffect, useContext } from 'react';
import ReactEcharts from "echarts-for-react";
import { Card, CardBody } from 'reactstrap';
import { formatDataYYYYMMDD } from "helpers/formatDateTime";
import getChartColorsArray from "../../../../components/Common/ChartsDynamicColor";
import { AssetsContext } from 'contexts/assets-context/assets-context';
import { formatarNumeroComUnidadesLetraUnica } from 'helpers/formatNumbers';

const LineBar = ({ seriesData, dataColors }) => {
    const spineareaChartColors = getChartColorsArray(dataColors);

    const options = {
        grid: {
            /* zlevel: 0,
            x: 80,
            x2: 50,
            y: 30,
            y2: 30, */
            borderWidth: 0,
            backgroundColor: "rgba(0,0,0,0)",
            borderColor: "rgba(0,0,0,0)",
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
                crossStyle: {
                    color: "#999",
                },
            },
        },
        toolbox: {
            orient: "center",
            left: 0,
            top: 20,
            feature: {
                dataView: { show: false, readOnly: false, title: "Data View" },
                magicType: { show: false, type: ["line", "bar"], title: { line: "For line chart", bar: "For bar chart" } },
                restore: { show: false, title: "restore" },
                saveAsImage: { show: false, title: "Download Image" },
            },
        },
        color: spineareaChartColors,
        legend: {
            data: ["Dívida", "Caixa e Equivalentes",],
            textStyle: {
                color: ["#8791af"],
            },
        },
        xAxis: [
            {
                type: "category",
                data: seriesData.label,
                axisPointer: {
                    type: "shadow",
                },
                axisLine: {
                    lineStyle: {
                        color: "#8791af",
                    },
                },
            },
        ],
        yAxis: [
            {
                type: "value",
                axisLine: {
                    lineStyle: {
                        color: '#8791af'
                    },
                },
                splitLine: {
                    lineStyle: {
                        color: "rgba(166, 176, 207, 0.1)"
                    }
                },
                axisLabel: {
                    formatter: function (value) {
                        return formatarNumeroComUnidadesLetraUnica(value);
                    }
                }
            },
            {
                type: "value",
                axisLine: {
                    lineStyle: {
                        color: '#8791af'
                    },
                },
                splitLine: {
                    lineStyle: {
                        color: "rgba(166, 176, 207, 0.1)"
                    }
                },
                axisLabel: {
                    formatter: '{value}%'
                }
            },
        ],
        series: [
            {
                name: 'Dívida',
                type: 'bar',
                data: seriesData.divida_bruta,
            },
            {
                name: 'Caixa e Equivalentes',
                type: 'bar',
                data: seriesData.saldo_final_caixa_e_equivalentes,
            },
        ],
        textStyle: {
            color: ["#74788d"],
        },
    }


    return (
        <React.Fragment>
            <ReactEcharts style={{ height: "350px" }} option={options} />
        </React.Fragment>
    );
};

const DividaChart = () => {
    const { financialResult, cashFlow } = useContext(AssetsContext);

    const [chartData, setChartData] = useState({
        divida_bruta: [],
        saldo_final_caixa_e_equivalentes: [],
        label: [],
    });

    useEffect(() => {
        if (cashFlow && financialResult) {
            const mergedData = cashFlow.map(cashFlowItem => {
                const matchingFinancialResult = financialResult.find(financialResultItem => financialResultItem.data === cashFlowItem.data);

                if (matchingFinancialResult) {
                    return {
                        data: cashFlowItem.data,
                        saldo_final_caixa_e_equivalentes: cashFlowItem.saldo_final_caixa_e_equivalentes,
                        divida_bruta: matchingFinancialResult.divida_bruta,
                    };
                }

                return null;
            }).filter(item => item !== null);

            const formattedData = {
                saldo_final_caixa_e_equivalentes: mergedData.map(item => item.saldo_final_caixa_e_equivalentes),
                divida_bruta: mergedData.map(item => item.divida_bruta),
                label: mergedData.map(item => String(formatDataYYYYMMDD(item.data).getFullYear())),
            };

            // Ordenar os arrays com base nas datas
            const sortedIndices = formattedData.label.map((_, index) => index).sort((a, b) => {
                const dateA = new Date(formattedData.label[a]);
                const dateB = new Date(formattedData.label[b]);
                return dateA - dateB;
            });

            formattedData.saldo_final_caixa_e_equivalentes = sortedIndices.map(index => formattedData.saldo_final_caixa_e_equivalentes[index]);
            formattedData.divida_bruta = sortedIndices.map(index => formattedData.divida_bruta[index]);
            formattedData.label = sortedIndices.map(index => formattedData.label[index]);

            setChartData(formattedData);
        }
    }, [cashFlow, financialResult]);

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="d-sm-flex flex-wrap">
                        <h4 className="card-title mb-4">Endividamento e Cobertura</h4>
                        {/* Restante do componente... */}
                    </div>
                    <LineBar
                        seriesData={chartData}
                        dataColors='["#f06292", "#00bcd4"]'
                        dir="ltr"
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default DividaChart;
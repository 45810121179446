export const formatarNumeroComUnidades = (numero) => {
    const numeroAbsoluto = Math.abs(numero);

    if (numeroAbsoluto >= 1_000_000_000_000) {
        const trilhoes = (numero / 1_000_000_000_000).toFixed(2).replace(/\.00$/, '');
        return `${trilhoes} ${trilhoes === '1' ? 'Trilhão' : 'Trilhões'}`;
    } else if (numeroAbsoluto >= 1_000_000_000) {
        const bilhoes = (numero / 1_000_000_000).toFixed(2).replace(/\.00$/, '');
        return `${bilhoes} ${bilhoes === '1' ? 'Bilhão' : 'Bilhões'}`;
    } else if (numeroAbsoluto >= 1_000_000) {
        const milhoes = (numero / 1_000_000).toFixed(2).replace(/\.00$/, '');
        return `${milhoes} ${milhoes === '1' ? 'Milhão' : 'Milhões'}`;
    } else if (numeroAbsoluto >= 1_000) {
        const milhares = (numero / 1_000).toFixed(2).replace(/\.00$/, '');
        return `${milhares} ${milhares === '1' ? 'Mil' : 'Mil'}`;
    } else {
        return numero.toString();
    }
};

export const formatarNumeroComUnidadesLetraUnica = (numero) => {
    const numeroAbsoluto = Math.abs(numero);

    if (numeroAbsoluto >= 1_000_000_000_000) {
        return (numero / 1_000_000_000_000).toFixed(2).replace(/\.00$/, '') + ' T';
    } else if (numeroAbsoluto >= 1_000_000_000) {
        return (numero / 1_000_000_000).toFixed(2).replace(/\.00$/, '') + ' B';
    } else if (numeroAbsoluto >= 1_000_000) {
        return (numero / 1_000_000).toFixed(2).replace(/\.00$/, '') + ' M';
    } else if (numeroAbsoluto >= 1_000) {
        return (numero / 1_000).toFixed(2).replace(/\.00$/, '') + ' k';
    } else {
        return numero.toString();
    }
};
import React, { useContext, useState, useEffect } from "react";
import { AssetsContext } from "contexts/assets-context/assets-context";
import { Card, CardBody, Col, Row } from "reactstrap";
import CandleChart from "./Components/CandleChart";
import ResumoWidget from "./Components/resumo-widget";
import Posts from "./Components/Posts";


const Resumo = () => {

    const {
        acao, cotacaoHistorica
    } = useContext(AssetsContext);

    const [firstCardHeight, setFirstCardHeight] = useState(0);

    useEffect(() => {
        const firstCard = document.getElementById('firstCard');
        if (firstCard) {
            const height = firstCard.clientHeight;
            setFirstCardHeight(height);
        }
    }, [cotacaoHistorica]);

    const secondCardStyle = {
        height: firstCardHeight + 'px',
    };


    return (
        <React.Fragment>
            {/* <Row>
                <ResumoWidget />
            </Row> */}
            <Row style={{ display: "flex" }}>

                <Col lg={8}>
                    <Card id="firstCard" style={{minHeight: '250px'}}>
                        <CardBody>
                            <CandleChart />
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card style={secondCardStyle}>
                        <CardBody>
                            <Posts />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Resumo;
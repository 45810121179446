import React, { useContext, useEffect, useRef, useState } from "react"
import toast from "helpers/toast";
import {
  Container,
  Row,
  Col,
  Table,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Form,
  FormGroup,
  Label,
  CardBody,
  CardTitle,
  CardSubtitle,
  Alert,
  FormFeedback,
  Modal,
} from "reactstrap";
import PhoneInput from "react-phone-number-input"
import { parsePhoneNumberFromString } from "libphonenumber-js";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { UserContext } from "contexts/user-context/user-context"
import { Link, useNavigate } from "react-router-dom";
import { LayoutContext } from "contexts/layout-context/layout-contex";
import { AuthContext } from "contexts/auth-context/auth-context";
import { PlanContext } from "contexts/plans-context/plans-context";


const PurchasePlan = () => {

  //meta title
  document.title = "Pagamento | Evolue";

  const navigate = useNavigate();

  const { plan, selectedPlan, getPaymentPlan } = useContext(PlanContext)
  const { registerSignature, getUser, userInfo, updateUser } = useContext(UserContext);
  const { isMobile } = useContext(LayoutContext);
  const { userStatus, setUserStatus } = useContext(AuthContext)

  const buttonRef = useRef(null);

  const [activeTab, setactiveTab] = useState("1")
  /* const [isLoadingBtn, setIsLoadingBtn] = useState(false) */
  const [error, setError] = useState('')
  const [mesmoEndereco, setMesmoEndereco] = useState(true)
  const [isLoadingCadastro, setIsLoadingCadastro] = useState(false)
  const [isLoadingCard, setIsLoadingCard] = useState(false)
  const [isLoadingEndereco, setIsLoadingEndereco] = useState(false)

  const [varyingModal, setVaryingModal] = useState(false);
  function tog_varyingModal() {
    setVaryingModal(!varyingModal);
  }

  useEffect(() => {
    if (!userInfo) {
      getUser()
    }
    if (!plan) {
      getPaymentPlan()
    }
  }, [])

  //TODO: verificar necessidade disso...
  useEffect(() => {
    if (userInfo) {
      localStorage.setItem('@status', userInfo.status_pagamento);
      setUserStatus(userInfo.status_pagamento)
    }
  }, [userInfo])

  useEffect(() => {
    if (!selectedPlan) {
      navigate("/plans")
    }
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      nome: userInfo && userInfo.nome || '',
      sobrenome: userInfo && userInfo.sobrenome || '',
      email: userInfo && userInfo.email || '',
      telefoneNumber: userInfo && '+' + userInfo.codigo_pais + userInfo.ddd + userInfo.telefone || '',
      codigo_pais: userInfo && userInfo.codigo_pais || '',
      ddd: userInfo && userInfo.ddd || '',
      telefone: userInfo && userInfo.telefone || '',
      documento: userInfo && userInfo.documento || '',
      tipo_documento: "cpf",
      tipo: "PESSOA_FISICA",
      rua: userInfo && userInfo.rua || '',
      numero: userInfo && userInfo.numero || '',
      complemento: userInfo && userInfo.complemento || '',
      cidade: userInfo && userInfo.cidade || '',
      bairro: userInfo && userInfo.bairro || '',
      estado: userInfo && userInfo.estado || '',
      pais: userInfo && userInfo.pais || '',
      cep: userInfo && userInfo.cep || ''
    },
    validationSchema: Yup.object({
      nome: Yup.string().required('O campo nome é obrigatório'),
      sobrenome: Yup.string().required('O campo sobrenome é obrigatório'),
      email: Yup.string().required("Insira seu e-mail")
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Insira um endereço de e-mail válido"
        ),
      telefoneNumber: Yup.string().required('O campo telefone é obrigatório'),
      codigo_pais: Yup.string().required('O campo código do país é obrigatório'),
      ddd: Yup.string().required('O campo DDD é obrigatório'),
      telefone: Yup.string().required('O campo telefone é obrigatório'),
      documento: Yup.string().required('O campo documento é obrigatório'),
      tipo_documento: Yup.string().required('O campo tipo de documento é obrigatório'),
      tipo: Yup.string().required('O campo tipo é obrigatório'),
      rua: Yup.string().required('O campo rua é obrigatório'),
      numero: Yup.string().required('O campo número é obrigatório'),
      complemento: Yup.string(),
      cidade: Yup.string().required('O campo cidade é obrigatório'),
      bairro: Yup.string().required('O campo bairro é obrigatório'),
      estado: Yup.string().required('O campo estado é obrigatório'),
      pais: Yup.string().required('O campo país é obrigatório'),
      cep: Yup.string().required('O campo CEP é obrigatório'),
    }),
    onSubmit: (values) => {
      setIsLoadingCadastro(true)
      const editedValues = {
        nome: values.nome.trim(),
        sobrenome: values.sobrenome.trim(),
        email: values.email.trim(),
        codigo_pais: values.codigo_pais,
        ddd: values.ddd,
        telefone: values.telefone,
        documento: values.documento.replace(/\D/g, '').trim(),
        tipo_documento: "CPF",
        tipo: "PESSOA_FISICA",
        rua: values.rua.trim(),
        numero: values.numero.replace(/\D/g, '').trim(),
        complemento: values.complemento.trim(),
        cidade: values.cidade.trim(),
        bairro: values.bairro.trim(),
        estado: values.estado.toUpperCase().replace(/\d/g, ''),
        pais: values.pais.toUpperCase().replace(/\d/g, ''),
        cep: values.cep.trim().replace(/\D/g, '')
      }
      updateUser(editedValues)
        .then((result) => {
          getUser()
          setIsLoadingCadastro(false)
        })
        .catch((err) => {
          setIsLoadingCadastro(false)
          console.error('Erro inesperado:', err.message);
        });
    }
  });

  const areValuesEqual = (initialValues, values) => {
    return JSON.stringify(initialValues) === JSON.stringify(values);
  };

  const validationCard = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      ano_vencimento: '',
      mes_vencimento: '',
      codigo_seguranca: "",
      nome_cartao: "",
      numero_cartao: "",
      vencimentoNaoFormatado: '',
      rua: (validation && validation.values.rua || '').trim(),
      numero: (validation && validation.values.numero || '').replace(/\D/g, '').trim(),
      complemento: (validation && validation.values.complemento || '').trim(),
      cidade: (validation && validation.values.cidade || '').trim(),
      bairro: (validation && validation.values.bairro || '').trim(),
      estado: (validation && validation.values.estado || '').toUpperCase().replace(/\d/g, ''),
      pais: (validation && validation.values.pais || '').toUpperCase().replace(/\d/g, ''),
      cep: (validation && validation.values.cep || '').trim().replace(/\D/g, '')
    },

    validationSchema: Yup.object({
      nome_cartao: Yup.string().required("Número Requerido"),
      numero_cartao: Yup.string()
        .required('O número do cartão de crédito é obrigatório')
        .test('validacao-cartao', 'Número de cartão de crédito inválido', function (value) {
          if (value === undefined) {
            return false; // Valor é undefined, considerar inválido
          }

          // Remove todos os espaços em branco do valor
          const cleanedValue = value.replace(/\s/g, '');

          // Verifica se o valor consiste em exatamente 16 dígitos numéricos
          return /^[0-9]{16}$/.test(cleanedValue);
        }),
      codigo_seguranca: Yup.string()
        .required('O código de segurança é obrigatório')
        .matches(/^[0-9]{3}$/, 'Código de segurança inválido. Deve ter 3 dígitos numéricos.'),

      vencimentoNaoFormatado: Yup.string()
        .required('A data de vencimento é obrigatória')
        .test('validade', 'Formato de data inválido (MM/AA)', function (value) {
          // Use uma expressão regular para verificar o formato MM/AA
          return /^(0[1-9]|1[0-2])\/\d{2}$/.test(value);
        }),
      rua: Yup.string().required('O campo rua é obrigatório'),
      numero: Yup.string().required('O campo número é obrigatório'),
      complemento: Yup.string(),
      cidade: Yup.string().required('O campo cidade é obrigatório'),
      bairro: Yup.string().required('O campo bairro é obrigatório'),
      estado: Yup.string().required('O campo estado é obrigatório'),
      pais: Yup.string().required('O campo país é obrigatório'),
      cep: Yup.string().required('O campo CEP é obrigatório'),
    }),

    onSubmit: (values) => {
      setIsLoadingCard(true)

      const toSubmit = {
        numero_cartao: values.numero_cartao.replace(/\s/g, ''),
        nome_cartao: values.nome_cartao,
        mes_vencimento: values.mes_vencimento,
        ano_vencimento: values.ano_vencimento,
        codigo_seguranca: values.codigo_seguranca,

        bairro: (values.bairro ? values.bairro : userInfo.bairro).trim(),
        cep: (values.cep ? values.cep : userInfo.cep).trim().replace(/\D/g, ''),
        cidade: (values.cidade ? values.cidade : userInfo.cidade).trim(),
        complemento: (values.complemento ? values.complemento : userInfo.complemento).trim(),
        estado: (values.estado ? values.estado : userInfo.estado).toUpperCase().replace(/\d/g, ''),
        numero: (values.numero ? values.numero : userInfo.numero).replace(/\D/g, '').trim(),
        pais: (values.pais ? values.pais : userInfo.pais).toUpperCase().replace(/\d/g, ''),
        rua: (values.rua ? values.rua : userInfo.rua).trim()
      }
      registerSignature(toSubmit, selectedPlan.id)
        .then((values) => {
          getUser()
          setTimeout(() => {
            setIsLoadingCard(false)
            setUserStatus(values.status_pagamento)
            navigate('/')
          }, 1000);
        })
        .catch((err) => {
          setIsLoadingCard(false)
          console.error('Erro inesperado:', err.message);
        });
    }
  });

  const validateSpecificFields = () => {
    const fieldsToValidate = [
      'bairro',
      'cep',
      'cidade',
      'estado',
      'numero',
      'pais',
      'rua',
    ];

    const errors = {};

    // Marque os campos como "tocados" manualmente
    fieldsToValidate.forEach((fieldName) => {
      validationCard.setFieldTouched(fieldName, true);
      const fieldValue = validationCard.values[fieldName];
      if (!fieldValue) {
        errors[fieldName] = 'Campo obrigatório';
      }
      // Adicione outras validações específicas, se necessário
    });

    // Atualize os erros no objeto de erros do Formik
    validationCard.setErrors(errors);

    // Verifique se há erros
    const hasErrors = Object.keys(errors).length > 0;
    return !hasErrors; // Retorna true se não houver erros, caso contrário, retorna false
  };

  const validateCadastroSpecificFields = () => {
    const fieldsToValidate = [
      'telefoneNumber',
      'documento',
      'bairro',
      'cep',
      'cidade',
      'estado',
      'numero',
      'pais',
      'rua',
    ];

    const errors = {};

    // Marque os campos como "tocados" manualmente
    fieldsToValidate.forEach((fieldName) => {
      validation.setFieldTouched(fieldName, true);
      const fieldValue = validation.values[fieldName];
      if (!fieldValue) {
        errors[fieldName] = 'Campo obrigatório';
      }
      // Adicione outras validações específicas, se necessário
    });

    // Atualize os erros no objeto de erros do Formik
    validation.setErrors(errors);

    // Verifique se há erros
    const hasErrors = Object.keys(errors).length > 0;
    return !hasErrors; // Retorna true se não houver erros, caso contrário, retorna false
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Plano" linkTitle="/plans" breadcrumbItem="Pagamento" />

          <div className="checkout-tabs">
            <Row>
              <Col lg="8" sm="8">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <div>
                        {/* <CardTitle>Informações Cadastrais</CardTitle>
                        <p className="card-title-desc mb-1">
                          Preencha todas as informações abaixo
                        </p> */}
                        <div
                          className="alert alert-success text-center mt-1"
                          role="alert"
                        >
                          Teste por 30 dias. O cancelamento do plano até o 30º dia garante o ressarcimento completo.
                        </div>
                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <div>
                            <Row>
                              <Col sm="6" md="3" className="mt-3" style={isMobile ? { maxWidth: '50%' } : {}}>
                                <Label className="form-label">Nome</Label>
                                <Input
                                  name="nome"
                                  type="text"
                                  placeholder="Informe seu Nome"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.nome
                                    //.replace(/\s/g, '') //Remove espaços
                                    .replace(/\d/g, '') //Remove números
                                    || ""}
                                  invalid={validation.touched.nome && validation.errors.nome ? true : false}
                                />
                                {validation.touched.nome && validation.errors.nome ? (
                                  <FormFeedback type="invalid">{validation.errors.nome}</FormFeedback>
                                ) : null}
                              </Col>
                              <Col sm="6" md="3" className="mt-3" style={isMobile ? { maxWidth: '50%' } : {}}>
                                <Label className="form-label">Sobrenome</Label>
                                <Input
                                  name="sobrenome"
                                  type="text"
                                  placeholder="Informe seu Sobrenome"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.sobrenome || ""}
                                  invalid={validation.touched.sobrenome && validation.errors.sobrenome ? true : false}
                                />
                                {validation.touched.sobrenome && validation.errors.sobrenome ? (
                                  <FormFeedback type="invalid">{validation.errors.sobrenome}</FormFeedback>
                                ) : null}
                              </Col>
                              <Col sm="12" md="6" className="mt-3">
                                <Label className="form-label">Email</Label>
                                <Input
                                  id="email"
                                  name="email"
                                  className="form-control"
                                  placeholder="Informe seu e-mail"
                                  type="email"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email || ""}
                                  invalid={
                                    validation.touched.email && validation.errors.email ? true : false
                                  }
                                />
                                {validation.touched.email && validation.errors.email ? (
                                  <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                ) : null}
                              </Col>
                              <Col sm="6" md="6" className="mt-3" style={isMobile ? { maxWidth: '55%' } : {}}>
                                <Label className="form-label">Telefone</Label>
                                <PhoneInput
                                  id="telefoneNumber"
                                  name="telefoneNumber"
                                  placeholder="Informe seu Telefone"
                                  defaultCountry="BR"
                                  value={validation.values.telefoneNumber}
                                  onChange={(value) => {
                                    validation.setFieldValue('telefoneNumber', value);
                                    if (typeof value === 'string' && value.trim() !== '') {
                                      const phoneNumber = parsePhoneNumberFromString(value, "BR");
                                      if (phoneNumber && phoneNumber.formatNational) {
                                        validation.setFieldValue('codigo_pais', `${phoneNumber.countryCallingCode}`);
                                        const formattedNational = phoneNumber.formatNational().split(' ');
                                        if (formattedNational.length === 2) {
                                          validation.setFieldValue('ddd', formattedNational[0].replace(/[\(\)]/g, ''));
                                          validation.setFieldValue('telefone', formattedNational[1].replace(/-/g, ''));
                                        } else {
                                          // Trate o caso em que não foi possível dividir o número nacional
                                          validation.setFieldValue('ddd', '');
                                          validation.setFieldValue('telefone', '');
                                        }
                                      } else {
                                        // Limpe os campos, já que o valor não é válido
                                        validation.setFieldValue('codigo_pais', '');
                                        validation.setFieldValue('ddd', '');
                                        validation.setFieldValue('telefone', '');
                                      }
                                    } else {
                                      // Limpe os campos, já que o valor não é válido
                                      validation.setFieldValue('codigo_pais', '');
                                      validation.setFieldValue('ddd', '');
                                      validation.setFieldValue('telefone', '');
                                    }
                                  }}
                                  onBlur={validation.handleBlur}
                                  className={`d-flex flex-wrap form-control ${validation.touched.telefoneNumber && validation.errors.telefoneNumber ? 'is-invalid' : ''
                                    }`}
                                />
                                {validation.touched.telefoneNumber && validation.errors.telefoneNumber ? (
                                  <FormFeedback type="invalid">{validation.errors.telefoneNumber}</FormFeedback>
                                ) : null}
                              </Col>
                              <Col sm="6" md="6" className="mt-3" style={isMobile ? { maxWidth: '45%' } : {}}>
                                <Label className="form-label">CPF</Label>
                                <Input
                                  id="documento"
                                  name="documento"
                                  className="form-control"
                                  placeholder=""
                                  type="text"
                                  maxLength="14"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.documento
                                    .replace(/\s/g, '') //Remove espaços
                                    .replace(/[^0-9]/g, '') //Remove letras e caracteres especiais
                                    .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                                    || ""}
                                  invalid={
                                    validation.touched.documento && validation.errors.documento ? true : false
                                  }
                                />
                                {validation.touched.documento && validation.errors.documento ? (
                                  <FormFeedback type="invalid">{validation.errors.documento}</FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                          <div /* className="mb-3" */>
                            <Row>
                              <Col md="5" className="mt-3" style={isMobile ? { maxWidth: '75%' } : {}}>
                                <Label className="form-label">Logradouro</Label>
                                <Input
                                  name="rua"
                                  type="text"
                                  placeholder=""
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.rua || ""}
                                  invalid={validation.touched.rua && validation.errors.rua ? true : false}
                                />
                                {validation.touched.rua && validation.errors.rua ? (
                                  <FormFeedback type="invalid">{validation.errors.rua}</FormFeedback>
                                ) : null}
                              </Col>
                              <Col md="2" className="mt-3" style={isMobile ? { maxWidth: '25%' } : {}}>
                                <Label className="form-label">Número</Label>
                                <Input
                                  name="numero"
                                  type="text"
                                  placeholder=""
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.numero
                                    .replace(/[^0-9]/g, '') //Remove letras e caracteres especiais
                                    || ""}
                                  invalid={validation.touched.numero && validation.errors.numero ? true : false}
                                />
                                {validation.touched.numero && validation.errors.numero ? (
                                  <FormFeedback type="invalid">{validation.errors.numero}</FormFeedback>
                                ) : null}
                              </Col>
                              <Col className="mt-3">
                                <Label className="form-label">Complemento</Label>
                                <Input
                                  id="complemento"
                                  name="complemento"
                                  className="form-control"
                                  placeholder=""
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.complemento || ""}
                                  invalid={
                                    validation.touched.complemento && validation.errors.complemento ? true : false
                                  }
                                />
                                {validation.touched.complemento && validation.errors.complemento ? (
                                  <FormFeedback type="invalid">{validation.errors.complemento}</FormFeedback>
                                ) : null}
                              </Col>
                              <Col className="mt-3">
                                <Label className="form-label">Cidade</Label>
                                <Input
                                  id="cidade"
                                  name="cidade"
                                  className="form-control"
                                  placeholder=""
                                  type="cidade"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.cidade || ""}
                                  invalid={
                                    validation.touched.cidade && validation.errors.cidade ? true : false
                                  }
                                />
                                {validation.touched.cidade && validation.errors.cidade ? (
                                  <FormFeedback type="invalid">{validation.errors.cidade}</FormFeedback>
                                ) : null}
                              </Col>
                              <Col md="3" className="mt-3" style={isMobile ? { maxWidth: '50%' } : {}}>
                                <Label className="form-label">Bairro</Label>
                                <Input
                                  id="bairro"
                                  name="bairro"
                                  className="form-control"
                                  placeholder=""
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.bairro || ""}
                                  invalid={
                                    validation.touched.bairro && validation.errors.bairro ? true : false
                                  }
                                />
                                {validation.touched.bairro && validation.errors.bairro ? (
                                  <FormFeedback type="invalid">{validation.errors.bairro}</FormFeedback>
                                ) : null}
                              </Col>
                              <Col md="3" className="mt-3" style={isMobile ? { maxWidth: '50%' } : {}}>
                                <Label className="form-label">UF</Label>
                                <Input
                                  id="estado"
                                  name="estado"
                                  className="form-control"
                                  placeholder=""
                                  type="text"
                                  maxLength="2"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.estado.toUpperCase()
                                    .replace(/\d/g, '') //Remove números
                                    || ""}
                                  invalid={
                                    validation.touched.estado && validation.errors.estado ? true : false
                                  }
                                />
                                {validation.touched.estado && validation.errors.estado ? (
                                  <FormFeedback type="invalid">{validation.errors.estado}</FormFeedback>
                                ) : null}
                              </Col>
                              <Col md="3" className="mt-3" style={isMobile ? { maxWidth: '50%' } : {}}>
                                <Label className="form-label">País</Label>
                                <Input
                                  id="pais"
                                  name="pais"
                                  className="form-control"
                                  placeholder=""
                                  type="text"
                                  maxLength="2"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.pais.toUpperCase()
                                    .replace(/\d/g, '') //Remove números
                                    || ""}
                                  invalid={
                                    validation.touched.pais && validation.errors.pais ? true : false
                                  }
                                />
                                {validation.touched.pais && validation.errors.pais ? (
                                  <FormFeedback type="invalid">{validation.errors.pais}</FormFeedback>
                                ) : null}
                              </Col>
                              <Col md="3" className="mt-3" style={isMobile ? { maxWidth: '50%' } : {}}>
                                <Label className="form-label">CEP</Label>
                                <Input
                                  id="cep"
                                  name="cep"
                                  className="form-control"
                                  placeholder=""
                                  type="text"
                                  maxLength='9'
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.cep
                                    .replace(/[^0-9]/g, '') //Remove letras e caracteres especiais
                                    .replace(/(\d{5})(\d{3})/, '$1-$2')
                                    || ""}
                                  invalid={
                                    validation.touched.cep && validation.errors.cep ? true : false
                                  }
                                />
                                {validation.touched.cep && validation.errors.cep ? (
                                  <FormFeedback type="invalid">{validation.errors.cep}</FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                          </div>

                          <div className="mt-4 d-grid">
                            <button
                              className={`btn btn-primary btn-block `}
                              ref={buttonRef}
                              type="submit"
                              disabled={areValuesEqual(validation.initialValues, validation.values)}
                            >
                              {isLoadingCadastro ? 'Atualizando ' : 'Atualizar Cadastro'}
                              {isLoadingCadastro && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>}
                            </button>
                          </div>
                        </Form>
                      </div>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="4" sm="4">
                <Card>
                  <CardBody className="bg-primary bg-soft" style={{ minHeight: '100%' }}>
                    <TabContent>
                      <div>
                        <Row>
                          <Col xs={7}>
                            <h5 className="mt-1 mb-3 text-muted font-size-15">
                              Cartão de Crédito
                            </h5>
                          </Col>
                          <Col xs={5}>
                            <h5 className="mt-1 mb-3 text-muted font-size-15 float-end">
                              {(Number(selectedPlan?.props?.preco) / 100 / 12).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} / mês
                            </h5>
                          </Col>
                        </Row>

                        <Row className={isMobile ? 'font-size-10' : 'font-size-12'}>
                          {/* <Col xs={1}>
                            <i className="bx bx-buildings me-2 " />
                          </Col> */}
                          <Col xs={8}>
                            <span>
                              {userInfo &&
                                <>
                                  <p className="mb-0">
                                    {`
                                    ${validationCard.values.rua !== undefined ? validationCard.values.rua : validation.values.rua || ''}
                                    ${validationCard.values.rua || validation.values.rua ? ',' : ''}
                                    ${validationCard.values.numero !== undefined ? validationCard.values.numero.replace(/\D/g, '') : validation.values.numero.replace(/\D/g, '') || ''}
                                    ${validationCard.values.numero || validation.values.numero ? ',' : ''}
                                    ${validationCard.values.complemento !== undefined ? validationCard.values.complemento : validation.values.complemento || ''}
                                    `}
                                  </p>
                                  <p className="mb-0">
                                    {`
                                    ${validationCard.values.cidade !== undefined ? validationCard.values.cidade : validation.values.cidade || ''}
                                    ${validationCard.values.cidade || validation.values.cidade ? ',' : ''}
                                    ${validationCard.values.bairro !== undefined ? validationCard.values.bairro : validation.values.bairro || ''}
                                    ${validationCard.values.bairro || validation.values.bairro ? ',' : ''}
                                    ${validationCard.values.estado !== undefined ? validationCard.values.estado.toUpperCase().replace(/\d/g, '') : validation.values.estado.toUpperCase().replace(/\d/g, '') || ''}
                                    ${validationCard.values.estado || validation.values.estado ? ',' : ''}
                                    ${validationCard.values.pais !== undefined ? validationCard.values.pais.toUpperCase().replace(/\d/g, '') : validation.values.pais.toUpperCase().replace(/\d/g, '') || ''}
                                    `}
                                  </p>
                                  <p>
                                    {(validationCard.values.cep ? validationCard.values.cep : validation.values.cep || '').replace(/\D/g, '').replace(/(\d{5})(\d{3})/, '$1-$2')}
                                  </p>
                                </>
                              }
                            </span>
                          </Col>
                          <Col xs={4} className="float-end">
                            <Link to={'#!'} onClick={() => {
                              tog_varyingModal()
                            }}>
                              <p className="mb-0 float-end">
                                Mudar End. <i className="bx bx-revision"></i>
                              </p>
                            </Link>
                          </Col>
                        </Row>
                        <div className="p-3 border">
                          <Form
                            className="form-horizontal"
                            onSubmit={(e) => {
                              e.preventDefault();

                              if (!areValuesEqual(validation.initialValues, validation.values)) {
                                toast.fail('Existem dados alterados no cadastros que não foram atualizados. Confira os dados e clique em "Atualizar Cadastro"')
                                return
                              }

                              if (!validateCadastroSpecificFields()) {
                                return false
                              }

                              else if (!validateSpecificFields()) {
                                tog_varyingModal()
                              }

                              validationCard.handleSubmit();
                              return false;
                            }}
                          >
                            <FormGroup className="mb-0">
                              <Label className="form-label">Número do Cartão</Label>
                              <Input
                                name="numero_cartao"
                                type="text"
                                maxLength={19}
                                placeholder="0000 0000 0000 0000"
                                onChange={validationCard.handleChange}
                                onBlur={validationCard.handleBlur}
                                value={validationCard.values.numero_cartao
                                  .replace(/\D/g, '')
                                  .replace(/(\d{4})(?=\d)/g, '$1 ')
                                  .trim()
                                  || ""}
                                invalid={validationCard.touched.numero_cartao && validationCard.errors.numero_cartao ? true : false}
                              />
                              {validationCard.touched.numero_cartao && validationCard.errors.numero_cartao ? (
                                <FormFeedback type="invalid">{validationCard.errors.numero_cartao}</FormFeedback>
                              ) : null}
                            </FormGroup>
                            <Row>
                              <Col lg="12">
                                <FormGroup className="mt-1 mb-0">
                                  <Label className="form-label">Nome no Cartão</Label>
                                  <Input
                                    name="nome_cartao"
                                    type="text"
                                    placeholder="Nome no Cartão"
                                    onChange={validationCard.handleChange}
                                    onBlur={validationCard.handleBlur}
                                    value={validationCard.values.nome_cartao
                                      .replace(/\d/g, '')
                                      || ""}
                                    invalid={validationCard.touched.nome_cartao && validationCard.errors.nome_cartao ? true : false}
                                  />
                                  {validationCard.touched.nome_cartao && validationCard.errors.nome_cartao ? (
                                    <FormFeedback type="invalid">{validationCard.errors.nome_cartao}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6" style={isMobile ? { maxWidth: '50%' } : {}}>
                                <FormGroup className=" mt-1 mb-0">
                                  <Label className="form-label">Validade</Label>
                                  <Input
                                    name="vencimentoNaoFormatado"
                                    type="text"
                                    maxLength={5}
                                    placeholder="MM/AA"
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      const cleanedValue = value.replace(/\D/g, '');

                                      if (cleanedValue.length === 4) {
                                        // Se o valor tiver 4 dígitos, adicione a barra automaticamente
                                        const formattedValue = cleanedValue.replace(/(\d{2})(\d{2})/, '$1/$2');
                                        validationCard.setFieldValue('vencimentoNaoFormatado', formattedValue);

                                        // Separe e defina os campos mes_vencimento e ano_vencimento
                                        const [mes, ano] = formattedValue.split('/');
                                        validationCard.setFieldValue('mes_vencimento', mes);
                                        validationCard.setFieldValue('ano_vencimento', `${ano}`);
                                      } else {
                                        validationCard.setFieldValue('vencimentoNaoFormatado', cleanedValue);
                                      }
                                    }}
                                    onBlur={validationCard.handleBlur}
                                    value={validationCard.values.vencimentoNaoFormatado || ""}
                                    invalid={validationCard.touched.vencimentoNaoFormatado && validationCard.errors.vencimentoNaoFormatado ? true : false}
                                  />
                                  {validationCard.touched.vencimentoNaoFormatado && validationCard.errors.vencimentoNaoFormatado ? (
                                    <FormFeedback type="invalid">{validationCard.errors.vencimentoNaoFormatado}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6" style={isMobile ? { maxWidth: '50%' } : {}}>
                                <FormGroup className="mt-1 mb-0">
                                  <Label className="form-label">Código CVV</Label>
                                  <Input
                                    name="codigo_seguranca"
                                    type="text"
                                    maxLength={3}
                                    placeholder="CVV"
                                    onChange={validationCard.handleChange}
                                    onBlur={validationCard.handleBlur}
                                    value={validationCard.values.codigo_seguranca.replace(/\D/g, '') || ""}
                                    invalid={validationCard.touched.codigo_seguranca && validationCard.errors.codigo_seguranca ? true : false}
                                  />
                                  {validationCard.touched.codigo_seguranca && validationCard.errors.codigo_seguranca ? (
                                    <FormFeedback type="invalid">{validationCard.errors.codigo_seguranca}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>

                            <div className="mt-3 mb-2 d-grid">
                              {error && error ? (
                                <Alert color="danger">{error}</Alert>
                              ) : null}
                              <button
                                className={`btn btn-success btn-block`}
                                type="submit"
                                disabled={areValuesEqual(validationCard.initialValues, validationCard.values)}
                              >
                                {isLoadingCard ? 'Assinando ' : 'Assinar'}
                                {isLoadingCard && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>}
                              </button>
                            </div>
                          </Form>
                        </div>
                      </div>

                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>









      <Modal
        isOpen={varyingModal}
        toggle={() => {
          tog_varyingModal()
        }}
        centered
        backdrop={'static'}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">Endereço do Cartão de Crédito </h5>
        </div>
        <div className="modal-body">
          <Form
            className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              return false;
            }}
          >
            <div /* className="mb-3" */>
              <Row>
                <Col md="9" className="mt-3" style={isMobile ? { maxWidth: '75%' } : {}}>
                  <Label className="form-label">Logradouro</Label>
                  <Input
                    name="rua"
                    type="text"
                    placeholder=""
                    onChange={validationCard.handleChange}
                    onBlur={validationCard.handleBlur}
                    value={validationCard.values.rua || ""}
                    invalid={validationCard.touched.rua && validationCard.errors.rua ? true : false}
                  />
                  {validationCard.touched.rua && validationCard.errors.rua ? (
                    <FormFeedback type="invalid">{validationCard.errors.rua}</FormFeedback>
                  ) : null}
                </Col>
                <Col className="mt-3" style={isMobile ? { maxWidth: '25%' } : {}}>
                  <Label className="form-label">Número</Label>
                  <Input
                    name="numero"
                    type="text"
                    placeholder=""
                    onChange={validationCard.handleChange}
                    onBlur={validationCard.handleBlur}
                    value={validationCard.values.numero
                      .replace(/[^0-9]/g, '') //Remove letras e caracteres especiais
                      || ""}
                    invalid={validationCard.touched.numero && validationCard.errors.numero ? true : false}
                  />
                  {validationCard.touched.numero && validationCard.errors.numero ? (
                    <FormFeedback type="invalid">{validationCard.errors.numero}</FormFeedback>
                  ) : null}
                </Col>
              </Row>

              <Row>
                <Col md="6" className="mt-3" style={isMobile ? { maxWidth: '50%' } : {}}>
                  <Label className="form-label">Complemento</Label>
                  <Input
                    id="complemento"
                    name="complemento"
                    className="form-control"
                    placeholder=""
                    type="text"
                    onChange={validationCard.handleChange}
                    onBlur={validationCard.handleBlur}
                    value={validationCard.values.complemento || " "}
                    invalid={
                      validationCard.touched.complemento && validationCard.errors.complemento ? true : false
                    }
                  />
                  {validationCard.touched.complemento && validationCard.errors.complemento ? (
                    <FormFeedback type="invalid">{validationCard.errors.complemento}</FormFeedback>
                  ) : null}
                </Col>
                <Col md="6" className="mt-3" style={isMobile ? { maxWidth: '50%' } : {}}>
                  <Label className="form-label">Cidade</Label>
                  <Input
                    id="cidade"
                    name="cidade"
                    className="form-control"
                    placeholder=""
                    type="cidade"
                    onChange={validationCard.handleChange}
                    onBlur={validationCard.handleBlur}
                    value={validationCard.values.cidade || ""}
                    invalid={
                      validationCard.touched.cidade && validationCard.errors.cidade ? true : false
                    }
                  />
                  {validationCard.touched.cidade && validationCard.errors.cidade ? (
                    <FormFeedback type="invalid">{validationCard.errors.cidade}</FormFeedback>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col md="5" className="mt-3" style={isMobile ? { maxWidth: '50%' } : {}}>
                  <Label className="form-label">Bairro</Label>
                  <Input
                    id="bairro"
                    name="bairro"
                    className="form-control"
                    placeholder=""
                    type="text"
                    onChange={validationCard.handleChange}
                    onBlur={validationCard.handleBlur}
                    value={validationCard.values.bairro || ""}
                    invalid={
                      validationCard.touched.bairro && validationCard.errors.bairro ? true : false
                    }
                  />
                  {validationCard.touched.bairro && validationCard.errors.bairro ? (
                    <FormFeedback type="invalid">{validationCard.errors.bairro}</FormFeedback>
                  ) : null}
                </Col>
                <Col md="2" className="mt-3" style={isMobile ? { maxWidth: '50%' } : {}}>
                  <Label className="form-label">UF</Label>
                  <Input
                    id="estado"
                    name="estado"
                    className="form-control"
                    placeholder=""
                    type="text"
                    maxLength="2"
                    onChange={validationCard.handleChange}
                    onBlur={validationCard.handleBlur}
                    value={validationCard.values.estado.toUpperCase()
                      .replace(/\d/g, '') //Remove números
                      || ""}
                    invalid={
                      validationCard.touched.estado && validationCard.errors.estado ? true : false
                    }
                  />
                  {validationCard.touched.estado && validationCard.errors.estado ? (
                    <FormFeedback type="invalid">{validationCard.errors.estado}</FormFeedback>
                  ) : null}
                </Col>
                <Col md="2" className="mt-3" style={isMobile ? { maxWidth: '50%' } : {}}>
                  <Label className="form-label">País</Label>
                  <Input
                    id="pais"
                    name="pais"
                    className="form-control"
                    placeholder=""
                    type="text"
                    maxLength="2"
                    onChange={validationCard.handleChange}
                    onBlur={validationCard.handleBlur}
                    value={validationCard.values.pais.toUpperCase()
                      .replace(/\d/g, '') //Remove números
                      || ""}
                    invalid={
                      validationCard.touched.pais && validationCard.errors.pais ? true : false
                    }
                  />
                  {validationCard.touched.pais && validationCard.errors.pais ? (
                    <FormFeedback type="invalid">{validationCard.errors.pais}</FormFeedback>
                  ) : null}
                </Col>
                <Col md="3" className="mt-3" style={isMobile ? { maxWidth: '50%' } : {}}>
                  <Label className="form-label">CEP</Label>
                  <Input
                    id="cep"
                    name="cep"
                    className="form-control"
                    placeholder=""
                    type="text"
                    maxLength='9'
                    onChange={validationCard.handleChange}
                    onBlur={validationCard.handleBlur}
                    value={validationCard.values.cep
                      .replace(/[^0-9]/g, '') //Remove letras e caracteres especiais
                      .replace(/(\d{5})(\d{3})/, '$1-$2')
                      || ""}
                    invalid={
                      validationCard.touched.cep && validationCard.errors.cep ? true : false
                    }
                  />
                  {validationCard.touched.cep && validationCard.errors.cep ? (
                    <FormFeedback type="invalid">{validationCard.errors.cep}</FormFeedback>
                  ) : null}
                </Col>
              </Row>
            </div>

            <Row>
              <Col>
                <div className="mt-4 d-grid">
                  <button
                    className={`btn btn-light btn-block `}
                    type=""
                    onClick={() => {
                      setVaryingModal(false);
                      validationCard.setValues({
                        ...validationCard.values,
                        rua: userInfo && userInfo.rua || '',
                        numero: userInfo && userInfo.numero || '',
                        complemento: userInfo && userInfo.complemento || '',
                        cidade: userInfo && userInfo.cidade || '',
                        bairro: userInfo && userInfo.bairro || '',
                        estado: userInfo && userInfo.estado || '',
                        pais: userInfo && userInfo.pais || '',
                        cep: userInfo && userInfo.cep || ''
                      });
                    }}
                  >
                    {'Cancelar'}
                  </button>
                </div>
              </Col>
              <Col>
                <div className="mt-4 d-grid">
                  <button
                    className={`btn btn-primary btn-block `}
                    type=""
                    onClick={() => {
                      setIsLoadingEndereco(true)
                      if (validateSpecificFields()) {
                        setVaryingModal(false);
                      }
                      setIsLoadingEndereco(false)
                    }}
                  >
                    {isLoadingEndereco ? 'Confirmando ' : 'Confirmar'}
                    {isLoadingEndereco && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>

      </Modal>

    </React.Fragment>
  )
}

export default PurchasePlan

import React, { useState, useContext } from "react"
import { Container, Row, Col, Button, Label, TabPane, Input, InputGroup, Card } from "reactstrap";

import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import WalletBalance from "./wallet-balance";

import './posfabs.css';
import SocialSource from "pages/Dashboard/SocialSource";
import { Link } from "react-router-dom";
import { LayoutContext } from "contexts/layout-context/layout-contex";
import { AssetsContext } from "contexts/assets-context/assets-context";


const Home = () => {

    const { isMobile } = useContext(LayoutContext);
    const { setTickerType, setAssetFilter } = useContext(AssetsContext);

    const links = [
        {
            name: 'Carteira',
            linkTo: '/wallet',
            icon: 'mdi mdi-bitcoin',
            color: 'text-warning',
            clickFunction: () => { }
        },
        {
            name: 'Ações',
            linkTo: '/assets',
            icon: 'mdi mdi-bitcoin',
            color: 'text-warning',
            clickFunction: () => { setTickerType('acoes'); setAssetFilter('ACAO') }
        },
        {
            name: 'Fiis',
            linkTo: '/assets',
            icon: 'mdi mdi-bitcoin',
            color: 'text-warning',
            clickFunction: () => { setTickerType('fiis'); setAssetFilter('FII') }
        },
        /* {
            name: 'Rankings',
            linkTo: '/ranking',
            icon: 'mdi mdi-bitcoin',
            color: 'text-warning',
            clickFunction: () => { }
        }, */
         {
            name: 'Opções',
            linkTo: '/options-market',
            icon: 'mdi mdi-bitcoin',
            color: 'text-warning',
            clickFunction: () => { }
        },
        {
            name: 'Impostos',
            linkTo: '/taxes-resume',
            icon: 'mdi mdi-bitcoin',
            color: 'text-warning',
            clickFunction: () => { }
        },
        {
            name: 'Perfil',
            linkTo: '/profile',
            icon: 'mdi mdi-bitcoin',
            color: 'text-warning',
            clickFunction: () => { }
        }
    ]

    //meta title
    document.title = "Home | Evolue";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* <Row>
                        <Col xl="4">

                            <Card className="overflow-hidden">
                                <WelcomeComp params={3} />
                            </Card>
                        </Col>
                        <Col xl="4">
                            <SocialSource />
                        </Col>
                        <Col xl="4" >
                            <MonthlyEarning />
                        </Col>
                    </Row> */}
                    {!isMobile &&
                        <Row>
                            <div className="mb-2">
                                <Row>
                                    {links.map((item, index) => (
                                        <Col key={index} xs="6" sm="4" md="3" lg="2">
                                            <Link to={item.linkTo} className="text-dark" onClick={item.clickFunction}>
                                                <div className="mb-3">
                                                    <Label className="card-radio-label mb-2">
                                                        <div className="card-radio">
                                                            <div className="d-flex flex-column align-items-center">
                                                                <i className={`${item.icon} font-size-24 ${item.color} align-middle me-2`} />{" "}
                                                                <span>{item.name}</span>
                                                            </div>
                                                        </div>
                                                    </Label>
                                                </div>
                                            </Link>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </Row>}
                    {isMobile &&
                        <div className="mb-2">
                            <div className="d-flex overflow-auto custom-scrollbar"> {/* added custom-scrollbar */}
                                {links.map((item, index) => (
                                    <div key={index} className="flex-shrink-0 pe-3" style={{ minWidth: '100px' }}>
                                        <Link to={item.linkTo} className="text-dark" onClick={item.clickFunction}>
                                            <div className="mb-3">
                                                <Label className="card-radio-label mb-1">
                                                    <div className="card-radio">
                                                        <div className="d-flex flex-column align-items-center">
                                                            <i className={`${item.icon} font-size-24 ${item.color} align-middle`} />{" "}
                                                            <span>{item.name}</span>
                                                        </div>
                                                    </div>
                                                </Label>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                    <Row>
                        <Col xl="12" className="mb-4">
                            <Row>
                                <WalletBalance dataColors='["--bs-primary", "--bs-warning", "--bs-info"]' />
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Home

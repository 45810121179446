export const getClassByValue = (value, balizador, min, melhor) => {
    const tolerance = 0.1; // Tolerância de 10%

    if (!melhor === 'menos' && value <= 0) {
        return 'text-danger'; // Valor menor ou igual a zero, sempre 'danger'
    }

    if (melhor === 'mais') {
        if (value > balizador * (1 + tolerance)) {
            return 'text-success';
        } else if (value >= balizador * (1 - tolerance)) {
            return 'text-warning';
        } else {
            return 'text-danger';
        }
    } else if (melhor === 'menos') {
        if (value < balizador * (1 - tolerance)) {
            return 'text-success';
        } else if (value <= balizador * (1 + tolerance)) {
            return 'text-warning';
        } else {
            return 'text-danger';
        }
    } else if (melhor === 'range') {
        if (balizador === null) {
            if (value >= min) {
                return 'text-success';
            } else {
                return 'text-danger';
            }
        }
        if (value >= min && value <= balizador) {
            return 'text-success';
        } else {
            return 'text-danger';
        }
    }
};
import React, { useState, createContext, useEffect } from "react";

export const LayoutContext = createContext();

const LayoutProvider = ({ children }) => {

    const [isMobile, setIsMobile] = useState(false);
    const [windowSize, setWindowSize] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setWindowSize(width);
            setIsMobile(width <= 768); // Define como móvel se a largura for menor ou igual a 768px
        };

        // Chama handleResize inicialmente e adiciona o ouvinte de evento para redimensionamento
        handleResize();
        window.addEventListener("resize", handleResize);

        // Remove o ouvinte de evento ao desmontar o componente
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <LayoutContext.Provider value={{
            isMobile, setIsMobile,
            windowSize, setWindowSize
        }}>
            {children}
        </LayoutContext.Provider>
    );
};

export default LayoutProvider;
import React, { useContext, useState, useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";
import api from '../../services/api';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { parsePhoneNumber } from "react-phone-number-input";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import './phone.css';
import toast from "helpers/toast";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/evolue-logo-menor.png";
import { UserContext } from "contexts/user-context/user-context";
import { AuthContext } from "contexts/auth-context/auth-context";
import { PlanContext } from "contexts/plans-context/plans-context";

const Register = props => {

  //meta title
  document.title = "Register | Evolue";

  const navigate = useNavigate();

  const { setToken, setRefresh, setUserStatus } = useContext(AuthContext)

  const [isLoadingBtn, setIsLoadingBtn] = useState(false)
  const [error, setError] = useState('')
  const [passwordShow, setPasswordShow] = useState(false);
  const [buttonStyle, setButtonStyle] = useState({});

  const {
    registerUser
  } = useContext(UserContext);

  const { selectedPlan } = useContext(PlanContext)

  const userCad = async (values) => {
    setError('')
    setIsLoadingBtn(true)
    try {
      const response = await registerUser(values);

      let getToken = JSON.stringify(response.headers.authorization);
      localStorage.setItem('@user', JSON.parse(getToken));
      setToken(getToken)

      let getRefresh = JSON.stringify(response.headers['refresh-token']);
      localStorage.setItem('@refreshToken', JSON.parse(getRefresh));
      setRefresh(getRefresh)

      localStorage.setItem('@status', 'INATIVO');
      setUserStatus('INATIVO')

      setIsLoadingBtn(false)
      toast.success(`${response.data}` || 'Cadastro realizado com sucesso');

      if (selectedPlan) {
        navigate('/purchase-plan');
      }
      else {
        navigate('/plans');
      }

    } catch (error) {
      setIsLoadingBtn(false)
      if (error.response) {
        switch (error.response.status) {
          case 400:
            // Tratamento para erro de requisição inválida (Bad Request)
            setError("Sua requisição é inválida. Contate o suporte!");
            break;
          case 401:
            // Tratamento para erro de autenticação (Unauthorized)
            setError("Sua senha está incorreta ou este não é seu e-mail cadastrado!");
            break;
          case 403:
            // Tratamento para erro de acesso proibido (Forbidden)
            setError("Você não tem permissão para acessar");
            break;
          case 404:
            // Tratamento para erro de recurso não encontrado (Not Found)
            setError("Erro 404: Recurso não encontrado.");
            break;
          case 409:
            // Tratamento para erro de recurso não encontrado (Not Found)
            setError("Usuário já cadastrado. Faça login");
            break;
          case 500:
            // Tratamento para erro interno do servidor (Internal Server Error)
            setError("Erro interno do servidor. Houve um erro inesperado, contate o suporte!");
            break;
          default:
            // Tratamento para outros erros não especificados acima
            setError("Contate o suporte. Erro desconhecido:", error.response.status);
        }
      } else {
        setError("Contate o suporte. Erro desconhecido:", error);
      }
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      nome: '',
      sobrenome: '',
      telefoneNumber: '',
      codigo_pais: '',
      ddd: '',
      telefone: '',
      senha: '',
      checkSenha: '',
    },
    validationSchema: Yup.object({
      nome: Yup.string().required("Por favor, insira seu Nome"),
      sobrenome: Yup.string().required("Por favor, insira seu Sobrenome"),
      email: Yup.string().required("Insira seu e-mail")
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Insira um endereço de e-mail válido"
        ),
      telefoneNumber: Yup.string().required("Por favor, cadastre uma Telefone"),
      senha: Yup.string()
        .required("Insira sua senha: sua senha contém no mínimo 8 caracteres e pelo menos uma letra maiúscula, uma minúscula e um número.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&*()-+=!])(?!.*\s).{8,}$/,
          "A senha deve conter no mínimo 8 caracteres, pelo menos uma letra maiúscula, uma minúscula, um caractere especial e um número."
        )
        .test("no-leading-trailing-spaces", "A senha não pode conter espaços no início ou no final", (value) => {
          // Verifica se a senha não contém espaços no início ou no final
          return !/^\s|\s$/.test(value);
        }),
      checkSenha: Yup.string()
        .oneOf([Yup.ref('senha'), null], 'As senhas devem ser iguais')
        .required('Por favor, confirme sua senha'),
    }),
    onSubmit: (values) => {
      const register = {
        email: values.email.trim(),
        nome: values.nome.trim(),
        sobrenome: values.sobrenome.trim(),
        codigo_pais: values.codigo_pais,
        ddd: values.ddd/* .replace(/[\(\)]/g, '') */,
        telefone: values.telefone/* .replace(/-/g, '') */,
        senha: values.senha.trim(),
      };
      userCad(register)
    }
  });

  useEffect(() => {
    const updatedStyle = {
      zIndex: 999,
      position: "absolute",
      top: 0,
      right: validation.touched.password && validation.errors.password ? 20 : 0,
      padding: "10px",
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
    };

    setButtonStyle(updatedStyle);
  }, [validation.touched.password, validation.errors.password]);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Crie Sua Conta</h5>
                        <p>Encontre as melhores oportunidades de investimento.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        {/* <span className="avatar-title rounded-circle bg-light"> */}
                        <img
                          src={logoImg}
                          alt=""
                          className="avatar-title rounded-circle"
                          height="34"
                        />
                        {/* </span> */}
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {/* {user && user ? (
                        <Alert color="success">
                          Usuário Registrado com Sucesso
                        </Alert>
                      ) : null} */}

                      {error && error ? (
                        <Alert color="danger">{error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <Row>
                          <Col md="6">
                            <Label className="form-label">Nome</Label>
                            <Input
                              name="nome"
                              type="text"
                              placeholder="Informe seu Nome"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.nome
                                //.replace(/\s/g, '') //Remove espaços
                                .replace(/\d/g, '') //Remove números
                                || ""}
                              invalid={validation.touched.nome && validation.errors.nome ? true : false}
                            />
                            {validation.touched.nome && validation.errors.nome ? (
                              <FormFeedback type="invalid">{validation.errors.nome}</FormFeedback>
                            ) : null}
                          </Col>
                          <Col md="6">
                            <Label className="form-label">Sobrenome</Label>
                            <Input
                              name="sobrenome"
                              type="text"
                              placeholder="Informe seu Sobrenome"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.sobrenome || ""}
                              invalid={validation.touched.sobrenome && validation.errors.sobrenome ? true : false}
                            />
                            {validation.touched.sobrenome && validation.errors.sobrenome ? (
                              <FormFeedback type="invalid">{validation.errors.sobrenome}</FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </div>

                      <div className="mb-3">
                        <Row>
                          <Col md="12">
                            <Label className="form-label">Email</Label>
                            <Input
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Informe seu e-mail"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </div>

                      <div className="mb-3">
                        <Row>
                          <Col md="12">
                            <Label className="form-label">Telefone</Label>
                            <PhoneInput
                              id="telefoneNumber"
                              name="telefoneNumber"
                              placeholder="Informe seu Telefone"
                              defaultCountry="BR"
                              value={validation.values.telefoneNumber}
                              onChange={(value) => {
                                validation.setFieldValue('telefoneNumber', value);
                                if (typeof value === 'string' && value.trim() !== '') {
                                  const phoneNumber = parsePhoneNumberFromString(value, "BR");
                                  if (phoneNumber && phoneNumber.formatNational) {
                                    validation.setFieldValue('codigo_pais', `${phoneNumber.countryCallingCode}`);
                                    const formattedNational = phoneNumber.formatNational().split(' ');
                                    if (formattedNational.length === 2) {
                                      validation.setFieldValue('ddd', formattedNational[0].replace(/[\(\)]/g, ''));
                                      validation.setFieldValue('telefone', formattedNational[1].replace(/-/g, ''));
                                    } else {
                                      // Trate o caso em que não foi possível dividir o número nacional
                                      validation.setFieldValue('ddd', '');
                                      validation.setFieldValue('telefone', '');
                                    }
                                  } else {
                                    // Limpe os campos, já que o valor não é válido
                                    validation.setFieldValue('codigo_pais', '');
                                    validation.setFieldValue('ddd', '');
                                    validation.setFieldValue('telefone', '');
                                  }
                                } else {
                                  // Limpe os campos, já que o valor não é válido
                                  validation.setFieldValue('codigo_pais', '');
                                  validation.setFieldValue('ddd', '');
                                  validation.setFieldValue('telefone', '');
                                }
                              }}
                              onBlur={validation.handleBlur}
                              className={`d-flex flex-wrap form-control ${validation.touched.telefoneNumber && validation.errors.telefoneNumber ? 'is-invalid' : ''
                                }`}
                            />
                            {validation.touched.telefoneNumber && validation.errors.telefoneNumber ? (
                              <FormFeedback type="invalid">{validation.errors.telefoneNumber}</FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </div>

                      <div className="mb-3">
                        <Row>
                          <Col md="6">
                            <Label className="form-label">Senha</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="senha"
                                type={passwordShow ? "text" : "password"}
                                placeholder="Cadastre sua Senha"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.senha || ""}
                                invalid={
                                  validation.touched.senha && validation.errors.senha ? true : false
                                }
                              />
                              <button
                                onClick={() => setPasswordShow(!passwordShow)}
                                style={buttonStyle}
                                className="btn"
                                type="button"
                              >
                                <i id="eyeIcon" className={`fa fa-eye ${passwordShow ? "" : "fa-eye-slash"}`}></i>
                              </button>

                              {validation.touched.senha && validation.errors.senha ? (
                                <FormFeedback type="invalid">{validation.errors.senha}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md="6">
                            <Label className="form-label">Confirme a Senha</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="checkSenha"
                                type={passwordShow ? "text" : "password"}
                                placeholder="Confirme sua Senha"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.checkSenha || ""}
                                invalid={
                                  validation.touched.checkSenha && validation.errors.checkSenha ? true : false
                                }
                              />
                              <button
                                onClick={() => setPasswordShow(!passwordShow)}
                                style={buttonStyle}
                                className="btn"
                                type="button"
                              >
                                <i id="eyeIcon" className={`fa fa-eye ${passwordShow ? "" : "fa-eye-slash"}`}></i>
                              </button>

                              {validation.touched.checkSenha && validation.errors.checkSenha ? (
                                <FormFeedback type="invalid">{validation.errors.checkSenha}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Lembrar minha conta
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className={`btn btn-primary btn-block ${isLoadingBtn ? 'loadingBtn' : ''}`}
                          type="submit"
                        >
                          {isLoadingBtn ? 'Criando Conta' : 'Criar Conta'}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          Ao se cadastrar, você aceita nossos{" "}
                          <Link to="https://evolue.app.br/termos-de-uso-ptbr" className="text-primary">
                            termos de uso.
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Já tem uma conta?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Faça login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Evolue Co. {" "}
                  <i className="mdi mdi-heart text-danger" /> Orgulhosamente feito remote first
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;

import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import {
    Card,
    CardBody,
    Col,
    Container,
    CardTitle,
    NavItem,
    NavLink,
    Row,
} from "reactstrap";
import PayWall from "components/PayWall/PayWall";
import classnames from "classnames";
import { TaxesContext } from "contexts/imposto-context/imposto-context";
import { LayoutContext } from "contexts/layout-context/layout-contex";
import { AuthContext } from "contexts/auth-context/auth-context";
import { NonDetailed } from "./taxes-components/non-detailed";
import { formatCurrency } from "helpers/formatCurrency";

const TaxesResume = () => {

    document.title = "Impostos Devidos | Evolue";

    const { userStatus } = useContext(AuthContext)

    const userStatusPaywall = ['PLANO_MASTER']

    if (!userStatus || !userStatusPaywall.includes(userStatus.replace(/["']/g, ""))) return (<PayWall nivel={["Master"]} />);

    const {
        pendences,
        getPendences,
        getAliquota,
        aliquota,
        heroInformation, setHeroInformation
    } = useContext(TaxesContext);

    const { isMobile } = useContext(LayoutContext)

    const [logoClass, setLogoClass] = useState('avatar-sm rounded')
    const [fontSize, setFontSize] = useState('font-size-14')
    const [str, setStr] = useState(18)
    const [detail, setDetail] = useState(false)

    useEffect(() => {
        if (isMobile) {
            setLogoClass('avatar-xs rounded');
            setFontSize('font-size-12');
            setStr(9)
        } else {
            setLogoClass('avatar-sm rounded');
            setFontSize('font-size-14');
            setStr(18)
        }
    }, [isMobile])

    const [assetType, setAssetType] = useState('ACAO');
    const [activeTab, setActiveTab] = useState('acoes');


    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);

            switch (tab) {
                case "acoes":
                    setAssetType('ACAO');
                    break;

                case "fiis":
                    setAssetType('FII');
                    break;

                case "opcoes":
                    setAssetType('OPCAO');
                    break;

                case "Tesouro":
                    // Lógica para quando o tab for "Tesouro"
                    break;

                default:
                    setAssetType('ACAO');
                    break;
            }
        }
    };

    useEffect(() => {
        getPendences(assetType);
        getAliquota(assetType, 'SWING_TRADE')
    }, [assetType])

    const [totalLucro, setTotalLucro] = useState(null)
    const [totalImposto, setTotalImposto] = useState(null)

    useEffect(() => {
        const lucro = heroInformation.totalLucro ?? 0
        const imposto = heroInformation.totalImposto ?? 0;
        setTotalLucro(Number(lucro));
        setTotalImposto(Number(imposto));
    }, [pendences, heroInformation]);


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="mt-2">
                    <CardTitle className="h4">Impostos Devidos</CardTitle>

                    <div className="d-flex border-bottom mb-3">
                        <Col lg={10}>
                            <p className="card-title-desc">
                                Este módulo calcula automaticamente os impostos devidos de acordo com as vendas e operações de opção cadastradas.
                                Caso já tenha pago o imposto devido e o registro ainda esteja constando como pendente de pagamento, basta atualizá-lo aqui.
                            </p>
                        </Col>
                    </div>

                    <Row>
                        <Col xl="12">
                            <Row>
                                <Col sm="4">
                                    <Card className="mini-stats-wid">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="me-3 align-self-center">
                                                    <i className="mdi mdi-cash-usd-outline h2 text-warning mb-0" />
                                                </div>
                                                <div className="flex-grow-1">
                                                    <p className="text-muted mb-2">Lucro</p>
                                                    <h5 className="mb-0">
                                                        {(totalLucro
                                                            ? formatCurrency(
                                                                (totalLucro), 'pt-BR', 'BRL') : 'R$ 0,00')}
                                                    </h5>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm="4">
                                    <Card className="mini-stats-wid">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="me-3 align-self-center">
                                                    <i className="mdi mdi-sack-percent h2 text-danger mb-0" />
                                                </div>
                                                <div className="flex-grow-1">
                                                    <div className="d-flex">
                                                        <p className="text-muted mb-2">Imposto Devido</p>
                                                    </div>

                                                    <h5 className="mb-0">
                                                        {(totalImposto
                                                            ? formatCurrency(
                                                                (totalImposto), 'pt-BR', 'BRL') : 'R$ 0,00')}
                                                    </h5>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm="4">
                                    <Card className="mini-stats-wid">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="me-3 align-self-center">
                                                    <i className="mdi mdi-brightness-percent h2 text-info mb-0" />
                                                </div>
                                                <div className="flex-grow-1">
                                                    <p className="text-muted mb-2">Alíquota</p>
                                                    <h5 className="mb-0">
                                                        {`${(aliquota * 100)}%` || '? %'}
                                                    </h5>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                    <Card>
                        <CardBody>
                            <div className="d-flex align-items-center">
                                <h5 className="mb-0 card-title flex-grow-1 ">Registros</h5>
                            </div>
                            <Container fluid>
                                <ul className="nav nav-tabs nav-tabs-custom navbar-expand-lg flex-nowrap nav-justified custom-scrollbar mt-2"
                                    style={{
                                        whiteSpace: "normal",
                                        overflowX: "auto",
                                        overflowY: "hidden"
                                    }}>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active: activeTab === "acoes",
                                            })}
                                            onClick={() => {
                                                toggleTab("acoes");
                                            }}
                                        >
                                            Ações
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active: activeTab === "fiis",
                                            })}
                                            onClick={() => {
                                                toggleTab("fiis");
                                            }}
                                        >
                                            Fundos Imobiliários
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active: activeTab === "opcoes",
                                            })}
                                            onClick={() => {
                                                toggleTab("opcoes");
                                            }}
                                        >
                                            Opções
                                        </NavLink>
                                    </NavItem>
                                </ul>
                            </Container>
                            <div>

                                {!detail && pendences &&
                                    <NonDetailed pendences={pendences} fontSize={fontSize} />
                                }

                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    );
};

TaxesResume.propTypes = {
    match: PropTypes.any,
};

export default withRouter(TaxesResume);

import { AssetsContext } from "contexts/assets-context/assets-context";
import React, { useContext, useState, useEffect } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";
import { calculateSumByYear, groupDividendsByYear, organizeDataForChart, separateDividendsByPayment } from "../Helper/helperProventos";

export default function DividendChart() {

    const {
        proventos
    } = useContext(AssetsContext);

    const [data, setData] = useState(null);

    useEffect(() => {
        // Função para realizar cálculos e definir dados quando proventos mudar
        const processData = () => {
            if (proventos) {
                const groupedByYear = groupDividendsByYear(proventos);
                const separatedByPayment = separateDividendsByPayment(groupedByYear);
                const organizedData = organizeDataForChart(separatedByPayment);
                setData(organizedData);
            }
        };

        processData(); // Chama a função inicialmente
    }, [proventos]);

    return (
        <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
                <BarChart
                    data={data}
                    margin={{
                        top: 30,
                        right: 30,
                        left: -10,
                        bottom: 10
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="DIVIDENDO" stackId="a" fill="#82ca9d" />
                    <Bar dataKey="JSCP" stackId="a" fill="#8884d8" />
                    <Bar dataKey="RENDIMENTO" stackId="a" fill="#3ea5e8" />
                    <Bar dataKey="PROVISIONADO" fill="#e7a825" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

import React from 'react';
import Endividamento from './Components/endividamento';
import Fundamentals from './Components/fundamentals';
import Margens from './Components/margens';
import Resultados from './Components/resultados';
import Valuation from './Components/valuation';

const FundamentalsTab = () => {

  return (
    <React.Fragment>
      <Fundamentals />
      <Resultados />
      <Valuation />
      <Margens />
      <Endividamento />
    </React.Fragment>
  );
}

export default FundamentalsTab;
import React, { useContext, useState, useEffect } from "react";
import api from '../../../services/api';
import { useMemo } from "react";
import {
    Col,
    Row,
    Card,
    CardBody,
    NavItem,
    NavLink,
    DropdownToggle,
    DropdownMenu,
    Modal,
    ModalHeader,
    Form,
    Input,
    Label,
    Nav,
    TabContent,
    TabPane,
    Button,
    Dropdown,
    DropdownItem,
    InputGroup,
    UncontrolledAlert,
    Table
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import { WalletContext } from "contexts/wallet-context/wallet-context";
import { formatPrice, unformatPrice, formatCurrency } from "helpers/formatCurrency";
import { LocaleContext } from "contexts/locales-context/locales-context";
import { handleBrazilStringDate, handleISODateString, separarDataEHora } from "helpers/formatDateTime";
import { SearchContext } from "contexts/search-context/search-context";
import { LayoutContext } from "contexts/layout-context/layout-contex";

const CadastroModal = ({ isOpen, toggle, editValues, setEditValues }) => {

    const {
        registerAcao, registerFii, selectStock, selectProventos
    } = useContext(WalletContext);

    const { isMobile } = useContext(LayoutContext)

    const mobileStyle = isMobile && 'font-size-11'

    const { localeOptions, setLocaleOptions } = useContext(LocaleContext);

    const {
        corretoras,
        ativos,
        handleInputChange,
        handleInputAtivosChange
    } = useContext(SearchContext);


    const [activeTab, setActiveTab] = useState(editValues && editValues.tipoAcao || "COMPRA");
    const [selectedTipoOperacao, setSelectedTipoOperacao] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [isOpenDropdown, setIsOpenDropdown] = useState(false)
    const [isOpenBrokerDropdown, setIsOpenBrokerDropdown] = useState(false)

    const [inputs, setInputs] = useState({
        total: 0, //verificar isso
    })

    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            /* if (editValues) {
                setEditValues({
                    ...editValues,
                    tipoAcao: tab,
                });
            } */
        }
    };

    const handleTipoOperacaoChange = (event) => {
        const { value } = event.target;
        setSelectedTipoOperacao(value);

        /* if (editValues) {
            setEditValues({
                ...editValues,
                tipoOperacao: value,
            });
        } */
    };

    const [disableCategory, setDisableCategory] = useState(false);

    // Formik
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: (editValues?.id && editValues.id) || null,
            classe: (editValues?.classe && editValues.classe) || 'ACAO',
            tipoAcao: (editValues?.tipoAcao && editValues.tipoAcao) || "COMPRA",
            tipoOperacao: (editValues?.tipoOperacao && editValues.tipoOperacao) || "",
            stock_exchange: (editValues?.stock_exchange && editValues.stock_exchange) || "BOVESPA",
            broker: (editValues?.broker && editValues.broker) || "",
            simbolo: (editValues?.simbolo && editValues.simbolo) || "",
            premio: (editValues?.premio && editValues.premio) || '', // não tem no fii
            assetRef: [],
            preço: (editValues?.preço && editValues.preço) || '',
            quantidade: (editValues?.quantidade && editValues.quantidade) || 100,
            corretagem: (editValues?.corretagem && editValues.corretagem) || '',
            data: (editValues?.data && handleISODateString(separarDataEHora(editValues.data).data) || ""),
            hora: (editValues?.data && separarDataEHora(editValues.data).hora || "")
        },
        validationSchema: Yup.object({
            /* jobId: Yup.string().matches(
              /[0-9\.\-\s+\/()]+/,
              "Please Enter Valid Job Id"
            ).required("Please Enter Your Job Id"),*/
            simbolo: Yup.string().required("Selecione um ativo"),
            broker: Yup.string().required('Informe a corretora').test('broker-validation', 'Informe a corretora', (value) => {
                return value !== 'Corretora' && value !== '';
            }),
            classe: Yup.string().required("Selecione a classe do ativo"),
            /* tipoOperacao: Yup.string().when('classe', {
                is: 'ACAO',
                then: Yup.string().required("Selecione o modo como a ação foi negociada"),
                otherwise: Yup.string(),
            }), */
            tipoOperacao: Yup.string().required("Selecione o modo como a ação foi negociada"),
            quantidade: Yup.number().required("Informe a quantidade").test('is-positive', 'A quantidade deve ser maior que 0', value => value > 0),
            preço: Yup.string().required("Informe o preço"),
            premio: Yup.string().when('tipoOperacao', {
                is: 'OPCAO',
                then: Yup.string().required('Informe o prêmio recebido pela opção'),
                otherwise: Yup.string(),
            }),
            corretagem: Yup.string().required("Informe o valor da corretagem"),
            data: Yup.string().required('Insira a data da negociação').test('date-validation', 'Data inválida', (value) => {
                return value !== 'dd-MM-yyyy' && value !== '';
            }),
            hora: Yup.string().required('Insira a hora da negociação').test('time-validation', 'hora inválida', (value) => {
                return value !== 'HH:mm:ss' && value !== '';
            })
        }),
        onSubmit: (values) => {
            switch (values.classe) {
                case "ACAO":
                    setIsLoading(true);
                    const register = {
                        broker: values.broker,
                        corretagem: unformatPrice(values.corretagem),
                        data: (`${handleBrazilStringDate(values.data)} ${values.hora}`),
                        preço: unformatPrice(values.preço),
                        quantidade: values.quantidade,
                        simbolo: values.simbolo,
                        tipoAcao: values.tipoAcao,
                        tipoOperacao: values.tipoOperacao,
                    };
                    if (values.tipoOperacao === "OPCAO") {
                        register.premio = unformatPrice(values.premio)
                    }
                    if (values.tipoOperacao === "SWING_TRADE") {
                        register.premio = 0
                    }
                    if (editValues && editValues?.id) {
                        register.id = values.id;
                    }
                    registerAcao(register, editValues && editValues?.id ? 'put' : 'post')
                        .then((result) => {
                            setIsLoading(false);
                            if (result.success) {
                                // Caso de sucesso - faça o que for necessário aqui
                                //toast.success('Ação registrada com sucesso:', result.data);
                                validation.resetForm();
                                /* selectStock(); 
                                selectProventos(); */
                                toggle();
                            }
                        })
                        .catch((err) => {
                            setIsLoading(false);
                        });
                    break;
                case "FII":
                    setIsLoading(true);
                    const fiiItens = {
                        broker: values.broker,
                        corretagem: unformatPrice(values.corretagem),
                        data: (`${handleBrazilStringDate(values.data)} ${values.hora}`),
                        preço: unformatPrice(values.preço),
                        quantidade: values.quantidade,
                        simbolo: values.simbolo,
                        tipoAcao: values.tipoAcao,
                        tipoOperacao: values.tipoOperacao
                    };
                    if (editValues && editValues?.id) {
                        fiiItens.id = values.id;
                    }
                    registerFii(fiiItens, editValues && editValues?.id ? 'put' : 'post')
                        .then((result) => {
                            setIsLoading(false);
                            if (result.success) {
                                validation.resetForm();
                                toggle();
                            }
                        })
                        .catch((err) => {
                            setIsLoading(false);
                        });
                    break;
                case "Opção":
                    logicaC();
                    break;
                default:
                    console.error("Situação inválida");
                    break;
            }

            //toggle();
        },
    });

    const toggleDropdown = () => {
        setIsOpenDropdown(!isOpenDropdown);
    };

    const toggleBrokerDropdown = () => {
        setIsOpenBrokerDropdown(!isOpenBrokerDropdown);
    };

    useEffect(() => {
        const numberPrice = unformatPrice(validation.values.preço)
        const total = validation.values.quantidade * numberPrice;
        setInputs({ ...inputs, total });
    }, [validation.values.quantidade, validation.values.preço]);

    useEffect(() => {
        if (validation.values.simbolo) {
            setDisableCategory(true)
        }
    }, [validation.values.simbolo])

    useEffect(() => {
        if (editValues && editValues.tipoAcao) {
            setActiveTab(editValues.tipoAcao);
        }

        if (editValues && editValues.tipoOperacao) {
            setSelectedTipoOperacao(editValues.tipoOperacao);
        }

        else { setSelectedTipoOperacao(''); }

    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} id="modal-registro" centered={true}>
            <ModalHeader toggle={toggle} tag="h4">
                <i className="mdi mdi-wallet me-1" /> {!!editValues ? "Editar Registro" : "Adicionar Registro"}
            </ModalHeader>
            {/* <ModalBody>  */}
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Col className="col-12">
                    <Card style={{ marginBottom: 0 }}>
                        <CardBody>
                            {validation.values.simbolo && <ModalDetails params={editValues ? editValues : validation.values.assetRef} isMobile={isMobile} />}
                            <div className="mt-1">

                                <Nav pills className="bg-light rounded mb-3" role="tablist">
                                    <NavItem className="col-6">
                                        <NavLink
                                            className={classnames({
                                                active: activeTab === "COMPRA",
                                            })}
                                            value="COMPRA"
                                            onClick={() => {
                                                toggleTab("COMPRA");
                                                validation.setFieldValue('tipoAcao', 'COMPRA');
                                            }}
                                        >
                                            {editValues && editValues?.id
                                                ? validation.values.tipoAcao === "VENDA"
                                                    ? "Mudar Para Compra"
                                                    : "Editar Compra"
                                                : "Registrar Compra"}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="col-6">
                                        <NavLink
                                            className={classnames({
                                                active: activeTab === "VENDA",
                                            })}
                                            value="VENDA"
                                            onClick={() => {
                                                toggleTab("VENDA");
                                                validation.setFieldValue('tipoAcao', 'VENDA');
                                            }}
                                        >
                                            {editValues && editValues?.id
                                                ? validation.values.tipoAcao === "COMPRA"
                                                    ? "Mudar Para Venda"
                                                    : "Editar Venda"
                                                : "Registrar Venda"}
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <>
                                    <Row className={`${mobileStyle}`}>
                                        {/* <Col>
                                            <div className="form-check mb-3">
                                                <Input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="tipoOperacao"
                                                    id="DAY_TRADE"
                                                    value="DAY_TRADE"
                                                    onChange={(event) => {
                                                        handleTipoOperacaoChange(event);
                                                        validation.setFieldValue('tipoOperacao', 'DAY_TRADE');
                                                    }}
                                                    onClick={(event) => {
                                                        handleTipoOperacaoChange(event);
                                                        validation.setFieldValue('tipoOperacao', 'DAY_TRADE');
                                                    }}
                                                    checked={selectedTipoOperacao === "DAY_TRADE"}
                                                    invalid={
                                                        validation.touched.tipoOperacao && validation.errors.tipoOperacao ? true : false
                                                    }
                                                />
                                                <label className="form-check-label" htmlFor="DAY_TRADE">
                                                    Day Trade
                                                </label>
                                            </div>
                                        </Col> */}
                                        <Col>
                                            <div className="form-check mb-3">
                                                <Input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="tipoOperacao"
                                                    id="SWING_TRADE"
                                                    value="SWING_TRADE"
                                                    onChange={(event) => {
                                                        handleTipoOperacaoChange(event);
                                                        validation.setFieldValue('tipoOperacao', 'SWING_TRADE');
                                                    }}
                                                    onClick={(event) => {
                                                        handleTipoOperacaoChange(event);
                                                        validation.setFieldValue('tipoOperacao', 'SWING_TRADE');
                                                    }}
                                                    checked={selectedTipoOperacao === "SWING_TRADE"}
                                                    invalid={
                                                        validation.touched.tipoOperacao && validation.errors.tipoOperacao ? true : false
                                                    }
                                                />
                                                <label className="form-check-label" htmlFor="SWING_TRADE">
                                                    Swing Trade
                                                </label>
                                            </div>
                                        </Col>
                                        {
                                            validation.values.classe === 'ACAO' &&
                                            <Col>
                                                <div className="form-check mb-3">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="tipoOperacao"
                                                        id="OPCAO"
                                                        value="OPCAO"
                                                        onChange={(event) => {
                                                            handleTipoOperacaoChange(event);
                                                            validation.setFieldValue('tipoOperacao', 'OPCAO');
                                                        }}
                                                        onClick={(event) => {
                                                            handleTipoOperacaoChange(event);
                                                            validation.setFieldValue('tipoOperacao', 'OPCAO');
                                                        }}
                                                        checked={selectedTipoOperacao === "OPCAO"}
                                                        invalid={
                                                            validation.touched.tipoOperacao && validation.errors.tipoOperacao ? true : false
                                                        }
                                                    />
                                                    <label className="form-check-label" htmlFor="OPCAO">
                                                        Exercício de Opção
                                                    </label>
                                                </div>
                                            </Col>
                                        }
                                        {validation.values.tipoOperacao === 'OPCAO' &&
                                            <InputGroup className="mb-3">
                                                <Label className={`input-group-text ${mobileStyle}`}>Prêmio</Label>
                                                <Input
                                                    name="premio"
                                                    className={`form-control ${mobileStyle}`}
                                                    placeholder="Prêmio recebido pela opção"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={
                                                        formatPrice(
                                                            validation.values.premio
                                                        )
                                                        || ''
                                                    }
                                                    invalid={
                                                        validation.touched.premio && validation.errors.premio ? true : false
                                                    } />
                                                <Label className={`input-group-text ${mobileStyle}`}>{localeOptions.symbol}</Label>
                                            </InputGroup>
                                        }
                                    </Row>

                                    <InputGroup className="mb-3">
                                        <Label className={`input-group-text ${mobileStyle}`}>Categoria</Label>
                                        <Input
                                            name="classe"
                                            disabled={disableCategory}
                                            className={`form-select ${mobileStyle}`}
                                            style={{ maxWidth: "25%" }}
                                            type="select"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                                validation.values.classe || ""
                                            }
                                            invalid={
                                                validation.touched.classe && validation.errors.classe
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <option value="ACAO">
                                                Ações
                                            </option>
                                            <option value="FII">Fii</option>
                                            <option value="OPCAO">Opções</option>
                                        </Input>
                                        <Input
                                            className={`form-select ${mobileStyle}`}
                                            disabled={disableCategory}
                                            type="button"
                                            name="simbolo"
                                            onClick={toggleDropdown}
                                            onChange={validation.handleChange}
                                            placeholder="Selecine o ativo"
                                            value={validation.values.simbolo || "Selecine o ativo"}
                                            invalid={
                                                validation.touched.simbolo && validation.errors.simbolo ? true : false
                                            }
                                        />
                                        <Dropdown isOpen={isOpenDropdown} toggle={toggleDropdown}>
                                            <DropdownToggle caret={false} style={{ display: 'none' }} />
                                            <DropdownMenu style={{ width: '100%', padding: '10px' }}>
                                                <Input
                                                    type="text"
                                                    placeholder="Digite para buscar..."
                                                    onChange={handleInputAtivosChange}
                                                />
                                                {ativos.length > 0 ? (
                                                    ativos.map((item) => (
                                                        <DropdownItem
                                                            key={item.symbol}
                                                            onClick={() => {
                                                                validation.setFieldValue('simbolo', item.symbol);
                                                                validation.setFieldValue('assetRef', item)
                                                                validation.setFieldValue('classe', item.classe)
                                                                if (item.classe === 'FII') {
                                                                    validation.setFieldValue('tipoOperacao', '')
                                                                }
                                                                setDisableCategory(true)
                                                            }}
                                                            className="ms-0 ps-0"
                                                        >
                                                            <table style={{ maxWidth: '100%' }} className="table table-hover table-borderless align-middle m-0 p-0">
                                                                <tbody>
                                                                    <tr className="mt-1">
                                                                        <td scope="row" className="m-0 p-0">
                                                                            <div className="d-flex flex-wrap align-items-center">
                                                                                <div className="avatar-xs me-3">
                                                                                    <span className="avatar-title rounded-circle bg-light">
                                                                                        <img src={`${item.image || null}`} alt="" className="avatar-title rounded-circle bg-light" />
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-6" style={{ maxWidth: '50%' }}>
                                                                                    <span className="fw-bold mb-0">{item.symbol}</span>
                                                                                    <div className="text-muted"
                                                                                        style={
                                                                                            {
                                                                                                maxWidth: '100%',
                                                                                                whiteSpace: 'nowrap',
                                                                                                overflow: 'hidden',
                                                                                                textOverflow: 'ellipsis',
                                                                                            }
                                                                                        }>
                                                                                        {item.nome_longo &&
                                                                                            item.nome_longo.substring(0, 23 - 3) +
                                                                                            (item.nome_longo.length >= 23 ? ' ...' : '')
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td className="text-end m-0 p-0">
                                                                            <div className="text-muted">
                                                                                {item.exchange}{': '}{item.classe}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                        </DropdownItem>
                                                    ))
                                                ) : (
                                                    <div style={{ paddingTop: '10px' }}>
                                                        <p className="placeholder-glow">
                                                            <span className="placeholder" style={{ width: '25%' }}></span>
                                                            <span className="placeholder col-12"></span>
                                                        </p>
                                                    </div>
                                                )}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </InputGroup>

                                    <InputGroup className="mb-3">
                                        <Label className={`input-group-text ${mobileStyle}`}>Quantidade</Label>
                                        <Input
                                            name="quantidade"
                                            className={`form-control ${mobileStyle}`}
                                            placeholder="Informe a quantidade"
                                            type="number"
                                            min="1"
                                            onChange={(e) => { validation.handleChange(e) }}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.quantidade || 0}
                                            invalid={
                                                validation.touched.quantidade && validation.errors.quantidade ? true : false
                                            } />
                                        <Label className={`input-group-text ${mobileStyle}`}>Preço</Label>
                                        <Input
                                            name="preço"
                                            autoComplete='off'
                                            className={`form-control ${mobileStyle}`}
                                            placeholder="Preço"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                                formatPrice(
                                                    validation.values.preço
                                                )
                                                || ''
                                            }
                                            invalid={
                                                validation.touched.preço && validation.errors.preço ? true : false
                                            } />
                                        <Label className={`input-group-text ${mobileStyle}`}>{localeOptions.symbol}</Label>
                                    </InputGroup>

                                    <div>
                                        <div>
                                            <InputGroup className="mb-3">
                                                <Label className={`input-group-text ${mobileStyle}`}>Corretora</Label>
                                                <Input
                                                    className={`form-control ${mobileStyle}`}
                                                    style={{ maxWidth: '82%' }}
                                                    type="button"
                                                    name="broker"
                                                    onClick={toggleBrokerDropdown}
                                                    onChange={validation.handleChange}
                                                    placeholder="Corretora"
                                                    value={validation.values.broker || "Corretora"}
                                                    invalid={
                                                        validation.touched.broker && validation.errors.broker ? true : false
                                                    }
                                                />
                                                <Dropdown isOpen={isOpenBrokerDropdown} toggle={toggleBrokerDropdown}>
                                                    <DropdownToggle caret={false} style={{ display: 'none' }} />
                                                    <DropdownMenu style={{ width: '100%', padding: '10px' }}>
                                                        <Input
                                                            type="text"
                                                            placeholder="Digite para buscar..."
                                                            onChange={handleInputChange}
                                                        />
                                                        {corretoras.length > 0 ? (
                                                            corretoras.map((item) => (
                                                                <DropdownItem
                                                                    key={item.id}
                                                                    onClick={() => validation.setFieldValue('broker', item.nome)}
                                                                >
                                                                    {item.nome}
                                                                </DropdownItem>
                                                            ))
                                                        ) : (
                                                            <div style={{ paddingTop: '10px' }}>
                                                                <p className="placeholder-glow">
                                                                    <span className="placeholder" style={{ width: '25%' }}></span>
                                                                    <span className="placeholder col-12"></span>
                                                                </p>
                                                            </div>
                                                        )}
                                                    </DropdownMenu>
                                                </Dropdown>
                                                <Label className={`input-group-text ${mobileStyle}`}>{isMobile ? 'Corret.' : 'Corretagem'}</Label>
                                                <Input
                                                    style={{ maxWidth: '18%' }}
                                                    name="corretagem"
                                                    autoComplete='off'
                                                    className={`form-control ${mobileStyle}`}
                                                    placeholder="0,00"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={
                                                        formatPrice(
                                                            validation.values.corretagem
                                                        )
                                                        || ''
                                                    }
                                                    invalid={
                                                        validation.touched.corretagem && validation.errors.corretagem ? true : false
                                                    } />
                                                <Label className={`input-group-text ${mobileStyle}`}>{localeOptions.symbol}</Label>
                                            </InputGroup>

                                            <InputGroup className="mb-3">
                                                <Label className={`input-group-text ${mobileStyle}`}>Data</Label>
                                                <Input
                                                    name="data"
                                                    className={`form-control ${mobileStyle}`}
                                                    type="date"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.data || ''}
                                                    invalid={
                                                        validation.touched.data && validation.errors.data ? true : false
                                                    }
                                                    step="1" />
                                                <Label className={`input-group-text ${mobileStyle}`}>Hora</Label>
                                                <Input
                                                    name="hora"
                                                    className={`form-control ${mobileStyle}`}
                                                    type="time"
                                                    step="1"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.hora || ''}
                                                    invalid={
                                                        validation.touched.hora && validation.errors.hora ? true : false
                                                    }
                                                />
                                            </InputGroup>
                                        </div>
                                        {validation.touched.hora && validation.errors.hora &&
                                            <UncontrolledAlert
                                                color="warning"
                                                className="alert-dismissible fade show"
                                                role="alert"
                                            >
                                                <i className="mdi mdi-alert-outline me-2"></i>Inserir data e hora é essencial
                                                para a apuração do imposto devido no módulo de impostos
                                            </UncontrolledAlert>
                                        }
                                        <Row>
                                            <Col>
                                                <div className="mt-6">
                                                    <h5>Total:</h5>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="mt-6 text-end">
                                                    <h5>{formatCurrency((inputs.total ? inputs.total : 0), localeOptions.locale, localeOptions.currency)}</h5>
                                                    <p />
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="text-center">
                                            <Button
                                                type="submit"
                                                color={activeTab === 'COMPRA' ? "success" : "danger"}
                                                className="w-md"
                                            >
                                                {!isLoading && `${editValues && editValues?.id ? "Editar" : "Registrar"} ${activeTab === 'COMPRA' ? "Compra" : "Venda"}`}
                                                {isLoading &&
                                                    <>
                                                        {editValues && editValues?.id ? "Editando" : "Registrando"} <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                                    </>
                                                }
                                            </Button>
                                        </div>
                                        {/* <CardBody> */}
                                        <p />
                                        {/* </CardBody> */}
                                    </div>
                                </>

                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Form>
            {/* </ModalBody> */}
        </Modal >
    )
}

const ModalDetails = ({ params, isMobile }) => {
    return (
        <Table className="table align-middle table-nowrap mt-0">
            <tbody>
                <tr>
                    <th scope="row" style={{ paddingTop: '0px' }}>
                        <div>
                            <img src={params?.image} alt="" className={`${isMobile ? 'avatar-xs' : 'avatar-sm'} rounded`} />
                        </div>
                    </th>
                    <td>
                        <div>
                            <h5 className={`text-truncate ${isMobile ? 'font-size-12' : 'font-size-14'}`}>{params?.simbolo || params?.symbol}</h5>
                            <p className={`text-muted mb-0 ${isMobile ? 'font-size-11' : ''}`} style={{
                                maxWidth: "70%", // Defina o tamanho máximo desejado em pixels
                                whiteSpace: "wrap", // Impede que o texto quebre em várias linhas
                                overflow: "hidden", // Esconde o texto excedente
                                textOverflow: "ellipsis", // Adiciona "..." quando o texto é truncado
                                lineHeight: "1"
                            }}>{params?.nome_longo}</p>
                        </div>
                    </td>
                    <td className="text-end">
                        {params?.stock_exchange || params?.exchange}
                    </td>
                    {/* <td className="text-end">
                        <div className="btn btn-light me-1" > // onClick={() => {validation.resetForm}}
                            <i className="mdi mdi-refresh"></i>
                        </div>
                    </td> */}
                </tr>
            </tbody>
        </Table>
    )
}

export default CadastroModal;
import { AssetsContext } from "contexts/assets-context/assets-context";
import { formatCurrency } from "helpers/formatCurrency";
import { formatarNumeroComUnidades } from "helpers/formatNumbers";
import React, { useContext } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getClassByValue } from "../Helper/helper-fundamentals";

const Resultados = () => {

    const {
        acao
    } = useContext(AssetsContext);

    const normalizedData = [
        {
            name: 'Lucro Líquido',
            item: acao.lucroLiquidoDozeMeses,
            balizador: '',
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: ''
        },
        {
            name: 'Valor de Mercado',
            item: acao.valor_mercado,
            balizador: '',
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: ''
        },
        {
            name: 'EBIT',
            item: acao.ebit,
            balizador: '',
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: ''
        },
        {
            name: 'EBITDA',
            item: acao.ebitda,
            balizador: '',
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: ''
        }
    ]

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="d-flex">
                        <h4 className="card-title mb-4 flex-grow-1">Resultados | Últimos 12 Meses</h4>
                    </div>
                    <div className="mt-0">
                        <Row>
                            {normalizedData.map((item, index) => (
                                <Col key={index} className="mt-2">
                                    <div className="mt-sm-0 mb-2">
                                        <div className="me-2">
                                            <p className={`font-size-11 text-muted mb-2`}>
                                                {/* <i className={`mdi mdi-circle align-middle font-size-10 pe-1 ${getClassByValue(item.item, item.balizador, item.min, item.melhor)}`} /> */}
                                                {item.name}
                                            </p>
                                            {/* <h5 className={`mb-0 `}>
                                                {`${Number(item.item).toFixed(2)}${item.class === 'percent' ? '%' : ''}`}
                                            </h5> */}
                                            <h5>{`${'R$ '}`}{formatarNumeroComUnidades(Number(item.item))}</h5>
                                            <p>{formatCurrency(Number(item.item), 'pt-BR', 'BRL')}</p>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default Resultados;
import React, { useContext, useEffect } from "react";
import {
  Col,
  Table,
} from "reactstrap";
//Simple bar
import SimpleBar from "simplebar-react"
import { AssetsContext } from "contexts/assets-context/assets-context";
import { formatDataBarra, handleBrazilStringDate } from "helpers/formatDateTime";

const Bonificacoes = () => {

  const {
    bonificacoes, getBonificacoes, selectedTicker
  } = useContext(AssetsContext);

  useEffect(() => {
    getBonificacoes();
  }, [selectedTicker])

  function calcularPorcentagemDaString(string) {
    const partes = string.split(':');

    if (partes.length === 2) {
      const primeiraQuantidade = parseFloat(partes[0]);
      const segundaQuantidade = parseFloat(partes[1]);

      if (!isNaN(primeiraQuantidade) && !isNaN(segundaQuantidade) && segundaQuantidade !== 0) {
        const porcentagem = (primeiraQuantidade / segundaQuantidade) * 100;
        return porcentagem.toFixed(2) + '%';
      }
    }

    return 'Formato inválido';
  }

  return (
    <React.Fragment>
      <h4 className="card-title mb-4">Bonificações</h4>
      <SimpleBar style={{ maxHeight: "150px", minHeight: "150px" }}>
        {bonificacoes?.length ?
          <div className="table-responsive">
            <Table className="table table-nowrap align-middle mb-0">
              <thead>
                <tr>
                  <th scope="col">Tipo</th>
                  <th scope="col">Anúncio</th>
                  <th scope="col">Data Com</th>
                  <th scope="col">Fator</th>
                </tr>
              </thead>
              <tbody>
                {bonificacoes.map((registro, key) => (
                  <tr key={key}>

                    <td>
                      <div className="text-success">
                        {registro.tipo}
                      </div>
                    </td>

                    <td>
                      <div>
                        {registro.data_aprovacao ? formatDataBarra(handleBrazilStringDate(registro.data_aprovacao)) : '--'}
                      </div>
                    </td>

                    <td>
                      <div>
                        {registro.data_inicio ? formatDataBarra(handleBrazilStringDate(registro.data_inicio)) : '--'}
                      </div>
                    </td>

                    <td>
                      <div className="font-size-14 mb-0">
                        {registro.rateio ? calcularPorcentagemDaString(registro.rateio) : '--'}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          :
          <React.Fragment>
            <div className="text-center">
              <Col lg="12">
                <p className="mt-5">Não há registros à exibir.</p>
              </Col>
            </div>
          </React.Fragment>
        }
      </SimpleBar>
    </React.Fragment>
  );
}

export default Bonificacoes;
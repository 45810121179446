import React from 'react';
import { Container } from 'reactstrap';

import AssetsData from './FavoritesAssetsGrid/FavoritesAssetsData';

const AssetsGrid = () => {
    document.title = "Favorites | Evolue";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* <Breadcrumbs title="Análise de Ativos" breadcrumbItem="Grid de Ativos" /> */}

                    <AssetsData />
                </Container>
            </div>
        </React.Fragment>
    );
}

export default AssetsGrid;
import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Modal, Card, CardBody } from 'reactstrap';
import CadastroModal from "components/Modals/cadastro-modal/cadastro-modal";
import { AssetsContext } from 'contexts/assets-context/assets-context';
import { formatCurrency } from 'helpers/formatCurrency';
import '../../../common/Styles/overflow-webkit.css'
import './dataComStyle.css'
import { formatDataBarra } from 'helpers/formatDateTime';

const Section = () => {

    const {
        acao, dataCom, postFavorite, deleteFavorite, getIsFavorite, isFavorite
    } = useContext(AssetsContext);

    const [editValues, setEditValues] = useState(null)
    const [preInsertValues, setPreInsertValues] = useState(null)
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const [varyingModal, setVaryingModal] = useState(false);
    function tog_varyingModal() {
        setVaryingModal(!varyingModal);
    }

    useEffect(() => {
        if (acao) {
            setPreInsertValues({
                image: acao.image,
                nome: acao.name,
                classe: acao.classe,
                nome_longo: acao.nome_longo,
                nomeCompania: acao.nome_longo,
                symbol: acao.symbol,
                simbolo: acao.symbol,
                stock_exchange: acao.exchange
            });
            getIsFavorite(acao.symbol)
        }
    }, [acao])


    const handleCalcByAssetPrice = (param) => {

        if (Number(param) > Number(acao.cotacao * 1.1)) {
            return 'text-success';
        }

        else if (Number(param) >=
            Number(acao.cotacao * 0.9) &&
            Number(param) <=
            Number(acao.cotacao * 1.1)) {
            return 'text-warning';
        }

        else {
            return 'text-danger'
        }
    }

    const handleCalcByBalizador = (param, balizador, qualitativo) => {
        if (Number(param) < Number(balizador)) {
            return 'text-success';
        } else if (Number(param) >= Number(balizador * 0.9) && Number(param) <= Number(balizador * 1.1)) {
            return 'text-warning';
        } else {
            return 'text-danger';
        }
    }

    const handleCalcByYield = (param) => {

        const handleParam = param / 100;

        const dividendYieldExpected = 0.06

        if (Number(handleParam) > Number(dividendYieldExpected * 1)) {
            return 'text-success';
        }

        else if (Number(handleParam) >=
            Number(dividendYieldExpected * 0.9) &&
            Number(handleParam) <=
            Number(dividendYieldExpected * 1)) {
            return 'text-warning';
        }

        else {
            return 'text-danger'
        }
    }


    const [font, setFont] = useState('font-size-14');
    const [minWidth, setMinWidth] = useState('100%');
    const [str, setStf] = useState(50);
    const [mobile, setMobile] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setMinWidth('180%');
                setFont('font-size-12')
                setStf(18)
                setMobile(true)
            } else {
                setMinWidth('100%');
                setFont('font-size-14')
                setStf(50)
                setMobile(false)
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const divStyle = {
        minWidth: minWidth, // Definir a largura mínima dinamicamente
        /* Outras propriedades de estilo aqui, se necessário */
    };

    return (
        <React.Fragment>
            <Row className="mb-4">
                <Col lg={12} className="mb-2">
                    <div className="table-responsive custom-scrollbar">
                        <div className="d-flex align-items-center " /* style={divStyle} */>
                            <Col
                                style={mobile ? { flex: '0 0 70%', maxWidth: '70%' } : { flex: '0 0 29%', maxWidth: '29%' }}
                            >
                                <div className="d-flex">
                                    <div className="me-1 data-com-on">
                                        <Link to={'#!'} onClick={() => {
                                            if (dataCom && dataCom.length > 0) {
                                                tog_varyingModal()
                                            }
                                            if (acao?.status_negociacao) {
                                                tog_varyingModal()
                                            }
                                        }}>
                                            {dataCom && dataCom.length > 0 && <div className="rounded-circle border-pulse border-green"></div>}
                                            {acao?.status_negociacao === "ENCERRADO" && <div className="rounded-circle border-pulse border-red"></div>}
                                            <img
                                                src={acao && acao.image}
                                                alt=""
                                                className="avatar-md rounded-circle img-thumbnail"
                                                style={{ minHeight: '4.5rem', minWidth: '4.5rem' }}
                                            />

                                        </Link>
                                    </div>
                                    <div className="flex-grow-1 align-self-center">
                                        <div className={` ${font}`}>
                                            <Col
                                                className="d-flex justify-content-start align-items-center"
                                                style={mobile ? { flex: '0 0 30%', maxWidth: '30%' } : { flex: '0 0 12.5%', maxWidth: '12.5%' }}
                                            >
                                                <h5 className={`mb-0`}>{acao && acao.symbol}</h5>
                                                <div style={{ paddingLeft: '5px' }}>{' '}</div>
                                                {acao && !isFavorite &&
                                                    <button className="btn btn-light" style={{ position: 'relative' }}
                                                        onClick={() => {
                                                            postFavorite(acao?.symbol);
                                                            getIsFavorite(acao.symbol);
                                                        }}>
                                                        <i className="bx bx-heart" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}></i>
                                                    </button>
                                                }
                                                {acao && isFavorite &&
                                                    <button className="btn btn-light" style={{ position: 'relative' }}
                                                        onClick={() => {
                                                            deleteFavorite(acao?.symbol);
                                                            getIsFavorite(acao.symbol);
                                                        }}>
                                                        <i className="mdi mdi-heart text-danger" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}></i>
                                                    </button>
                                                }
                                            </Col>
                                            <p
                                                className={`mb-0`}
                                            >
                                                {acao && acao.nome_longo &&
                                                    acao.nome_longo.substring(0, str - 3) +
                                                    (acao.nome_longo.length >= str ? ' ...' : '')
                                                }
                                            </p>
                                            {mobile &&
                                                <h5 className={`mb-0 font-size-18`}>
                                                    {acao ? (
                                                        `${formatCurrency(Number(acao.cotacao), 'pt-BR', 'BRL')} `
                                                    ) : (
                                                        ''
                                                    )}

                                                    {acao && acao.percentual_variacao !== null ? (
                                                        <span className={`badge badge-soft-${Number(acao.percentual_variacao) > 0 ? 'success' : 'danger'} font-size-12`}>
                                                            {`${Number(acao.percentual_variacao) > 0 ? '+' : ''} ${acao.percentual_variacao}%`}
                                                        </span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </h5>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            {!mobile && <Col className="align-self-center">
                                <div className="text-lg-center mt-0 mt-lg-0">
                                    <Row>
                                        <Col style={{ width: '2.4rem' }}>
                                            <div className="d-flex flex-column align-items-center">
                                                <p className="text-muted text-truncate mb-2">
                                                    Cotação
                                                </p>

                                                <h5 className={`mb-0 ${font}`}>
                                                    {acao && acao.percentual_variacao !== null ? (
                                                        Number(acao.percentual_variacao) > 0 ? (
                                                            <i className="mdi mdi-chevron-up ms-1 text-success" />
                                                        ) : (
                                                            Number(acao.percentual_variacao) === 0 ? (
                                                                <></>
                                                            ) : (
                                                                <i className="mdi mdi-chevron-down ms-1 text-danger" />
                                                            )
                                                        )
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {acao ? (
                                                        `${formatCurrency(Number(acao.cotacao), 'pt-BR', 'BRL')} `
                                                    ) : (
                                                        ''
                                                    )}

                                                    {acao && acao.percentual_variacao !== null ? (
                                                        <span className={`badge badge-soft-${Number(acao.percentual_variacao) > 0 ? 'success' : 'danger'} font-size-12`}>
                                                            {`${Number(acao.percentual_variacao) > 0 ? '+' : ''} ${acao.percentual_variacao}%`}
                                                        </span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </h5>
                                            </div>
                                        </Col>
                                        {acao && acao.classe === "ACAO" &&
                                            <Col style={{ width: '2.4rem' }}>
                                                <div className="d-flex flex-column align-items-center">
                                                    <p className="text-muted text-truncate mb-2">
                                                        Preço Justo
                                                    </p>
                                                    <h5 className={`mb-0 ${font}`}>
                                                        <i
                                                            className={`mdi mdi-circle align-middle me-1 
                                                    ${acao && acao.preco_teto_metodo_graham && handleCalcByAssetPrice(acao.preco_teto_metodo_graham)}`}
                                                        />{" "}
                                                        {acao && formatCurrency(Number(acao.preco_teto_metodo_graham), 'pt-BR', 'BRL')}
                                                    </h5>
                                                </div>
                                            </Col>
                                        }
                                        {acao && acao.classe === "FII" &&
                                            <Col style={{ width: '2.4rem' }}>
                                                <div className="d-flex flex-column align-items-center">
                                                    <p className="text-muted text-truncate mb-2">
                                                        VPC
                                                    </p>
                                                    <h5 className={`mb-0 ${font}`}>
                                                        <i
                                                            className={`mdi mdi-circle align-middle me-1 
                                                    ${acao && acao.valor_patrimonial_por_acao && handleCalcByAssetPrice(acao.valor_patrimonial_por_acao)}`}
                                                        />{" "}
                                                        {Number(acao && acao.valor_patrimonial_por_acao).toFixed(2)}
                                                    </h5>
                                                </div>
                                            </Col>
                                        }
                                        <Col style={{ width: '2.4rem' }}>
                                            <div className="d-flex flex-column align-items-center">
                                                <p className="text-muted text-truncate mb-2">
                                                    Preço Teto
                                                </p>
                                                <h5 className={`mb-0 ${font}`}>
                                                    <i
                                                        className={`mdi mdi-circle align-middle me-1 
                                                    ${acao && acao.preco_teto && handleCalcByAssetPrice(acao.preco_teto)}`}
                                                    />{" "}
                                                    {acao && formatCurrency(Number(acao.preco_teto), 'pt-BR', 'BRL')}
                                                </h5>
                                            </div>
                                        </Col>
                                        {acao && acao.classe === "FII" &&
                                            <Col style={{ width: '2.4rem' }}>
                                                <div className="d-flex flex-column align-items-center">
                                                    <p className="text-muted text-truncate mb-2">
                                                        P/VP
                                                    </p>
                                                    <h5 className={`mb-0 ${font}`}>
                                                        <i
                                                            className={`mdi mdi-circle align-middle me-1 
                                                    ${acao && acao.preco_valor_patrimonial_acao && handleCalcByBalizador(acao.preco_valor_patrimonial_acao, 1)}`}
                                                        />{" "}
                                                        {acao && Number(acao.preco_valor_patrimonial_acao).toFixed(2)}
                                                    </h5>
                                                </div>
                                            </Col>
                                        }
                                        {/* <Col style={{ width: '2.4rem' }}>
                                            <div className="d-flex flex-column align-items-center">
                                                <p className="text-muted text-truncate mb-2">
                                                    Dividend Yield
                                                </p>
                                                <h5 className={`mb-0 ${font}`}>
                                                    <i
                                                        className={`mdi mdi-circle align-middle me-1 
                                                    ${acao && acao.dividend_yeld && handleCalcByYield(acao.dividend_yeld)}`}
                                                    />{" "}
                                                    {`${acao && Number(acao.dividend_yeld).toFixed(2)}%`}
                                                </h5>
                                            </div>
                                        </Col> */}
                                        <Col style={{ width: '2.4rem' }}>
                                            <div className="d-flex flex-column align-items-center">
                                                <p className="text-muted text-truncate mb-2">
                                                    Yield Médio
                                                </p>
                                                <h5 className={`mb-0 ${font}`}>
                                                    <i
                                                        className={`mdi mdi-circle align-middle me-1 
                                                    ${acao && acao.yeld_medio && handleCalcByYield(acao.yeld_medio)}`}
                                                    />{" "}
                                                    {`${acao && Number(acao.yeld_medio).toFixed(2)}%`}
                                                </h5>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>}

                            <Col
                                className="d-flex justify-content-end"
                                style={mobile ? { flex: '0 0 30%', maxWidth: '30%' } : { flex: '0 0 12.5%', maxWidth: '12.5%' }}
                            >
                                {/* <Link to="#" className="btn btn-primary"><i className="bx bx-plus align-middle"></i> Registro</Link> */}
                                <button onClick={() => { toggle(); setEditValues(preInsertValues) }} className="btn btn-primary">+ Registro</button>
                            </Col>
                        </div>
                    </div>
                </Col>

                {mobile &&
                    <Col>
                        <div /* className="table-responsive custom-scrollbar" */>
                            <div className="d-flex align-items-center">
                                <Col className="align-self-center">
                                    <div className="text-lg-center mt-0 mt-lg-0">
                                        <Row>
                                            {acao && acao.classe === "ACAO" &&
                                                <Col style={{ width: '2.4rem' }}>
                                                    <div className="d-flex flex-column align-items-center">
                                                        <p className="text-muted text-truncate mb-2">
                                                            Preço Justo
                                                        </p>
                                                        <h5 className={`mb-0 ${font}`}>
                                                            <i
                                                                className={`mdi mdi-circle align-middle me-1 
                                                    ${acao && acao.preco_teto_metodo_graham && handleCalcByAssetPrice(acao.preco_teto_metodo_graham)}`}
                                                            />{" "}
                                                            {acao && formatCurrency(Number(acao.preco_teto_metodo_graham), 'pt-BR', 'BRL')}
                                                        </h5>
                                                    </div>
                                                </Col>
                                            }
                                            {acao && acao.classe === "FII" &&
                                                <Col style={{ width: '2.4rem' }}>
                                                    <div className="d-flex flex-column align-items-center">
                                                        <p className="text-muted text-truncate mb-2">
                                                            VPC
                                                        </p>
                                                        <h5 className={`mb-0 ${font}`}>
                                                            <i
                                                                className={`mdi mdi-circle align-middle me-1 
                                                    ${acao && acao.valor_patrimonial_por_acao && handleCalcByAssetPrice(acao.valor_patrimonial_por_acao)}`}
                                                            />{" "}
                                                            {Number(acao && acao.valor_patrimonial_por_acao).toFixed(2)}
                                                        </h5>
                                                    </div>
                                                </Col>
                                            }
                                            <Col style={{ width: '2.4rem' }}>
                                                <div className="d-flex flex-column align-items-center">
                                                    <p className="text-muted text-truncate mb-2">
                                                        Preço Teto
                                                    </p>
                                                    <h5 className={`mb-0 ${font}`}>
                                                        <i
                                                            className={`mdi mdi-circle align-middle me-1 
                                                    ${acao && acao.preco_teto && handleCalcByAssetPrice(acao.preco_teto)}`}
                                                        />{" "}
                                                        {acao && formatCurrency(Number(acao.preco_teto), 'pt-BR', 'BRL')}
                                                    </h5>
                                                </div>
                                            </Col>
                                            {acao && acao.classe === "FII" &&
                                                <Col style={{ width: '2.4rem' }}>
                                                    <div className="d-flex flex-column align-items-center">
                                                        <p className="text-muted text-truncate mb-2">
                                                            P/VP
                                                        </p>
                                                        <h5 className={`mb-0 ${font}`}>
                                                            <i
                                                                className={`mdi mdi-circle align-middle me-1 
                                                    ${acao && acao.preco_valor_patrimonial_acao && handleCalcByBalizador(acao.preco_valor_patrimonial_acao, 1)}`}
                                                            />{" "}
                                                            {acao && Number(acao.preco_valor_patrimonial_acao).toFixed(2)}
                                                        </h5>
                                                    </div>
                                                </Col>
                                            }
                                            {/* <Col style={{ width: '2.4rem' }}>
                                            <div className="d-flex flex-column align-items-center">
                                                <p className="text-muted text-truncate mb-2">
                                                    Dividend Yield
                                                </p>
                                                <h5 className={`mb-0 ${font}`}>
                                                    <i
                                                        className={`mdi mdi-circle align-middle me-1 
                                                    ${acao && acao.dividend_yeld && handleCalcByYield(acao.dividend_yeld)}`}
                                                    />{" "}
                                                    {`${acao && Number(acao.dividend_yeld).toFixed(2)}%`}
                                                </h5>
                                            </div>
                                        </Col> */}
                                            <Col style={{ width: '2.4rem' }}>
                                                <div className="d-flex flex-column align-items-center">
                                                    <p className="text-muted text-truncate mb-2">
                                                        Yield Médio
                                                    </p>
                                                    <h5 className={`mb-0 ${font}`}>
                                                        <i
                                                            className={`mdi mdi-circle align-middle me-1 
                                                    ${acao && acao.yeld_medio && handleCalcByYield(acao.yeld_medio)}`}
                                                        />{" "}
                                                        {`${acao && Number(acao.yeld_medio).toFixed(2)}%`}
                                                    </h5>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </div>
                        </div>

                    </Col>
                }
            </Row>
            <CadastroModal isOpen={isOpen} toggle={toggle} editValues={editValues} setEditValues={setEditValues} />

            <Modal
                isOpen={varyingModal}
                toggle={() => {
                    tog_varyingModal()
                }}
                centered
            >
                <div className={`modal-header bg-soft ${dataCom?.length > 0 ? 'bg-success' : 'bg-danger'}`}>
                    <h5 className="modal-title" id="exampleModalLabel">{dataCom?.length > 0 ? 'Data Com!' : 'Informação:'}</h5>
                </div>
                <div className={`modal-body bg-soft ${dataCom?.length > 0 ? 'bg-success' : 'bg-danger'}`}>
                    {dataCom?.map((item, index) => (
                        <Col className="mb-3" key={index}>
                            <CardBody>
                                <p className="mb-0">
                                    <i className="mdi mdi-circle align-middle font-size-10 me-2 text-success" />{" "}
                                    {`Compre até ${formatDataBarra(item.data_com)} para receber ${formatCurrency(item.valor, 'pt-BR', 'BRL')} por ${item.tipoAtivo === "ACAO" ? 'ação' : 'cota'} em ${item.data_pagamento ? formatDataBarra(item.data_pagamento) : 'data futura a definir'}.`}
                                </p>
                            </CardBody>
                        </Col>
                    ))}
                    {
                        acao?.status_negociacao === "ENCERRADO" &&
                        <CardBody>
                            <p className="mb-0">
                                Essa classe de ativo não está sendo negociado na bolsa atualmente.
                            </p>
                        </CardBody>
                    }
                </div>

            </Modal>
        </React.Fragment>
    );
}

export default Section;
import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Badge,
  Modal,
  ModalBody
} from "reactstrap";
//Simple bar
import SimpleBar from "simplebar-react"
import { Link, useParams } from "react-router-dom";
import { WalletContext } from "contexts/wallet-context/wallet-context";

import { formatData, formatDataBarra, formatDataYYYYMMDD } from "helpers/formatDateTime";
import { formatCurrency } from "helpers/formatCurrency";
import CadastroModal from "components/Modals/cadastro-modal/cadastro-modal";

import builtWallet from '../../../assets/images/builtWallet.svg'
import { AssetsContext } from "contexts/assets-context/assets-context";

const Extract = props => {

  //meta title
  document.title = "Lending | Skote - React Admin & Dashboard Template";

  const { country, exchange, type, slug } = useParams()

  const {
    extract,
    dividend,
    selectStock,
    selectedAsset,
    setSelectedAsset,
    selectProventos,
    assetType,
    setAssetType,
    selectFii,
    selectProventosFii,
    deleteRegisterById
  } = useContext(WalletContext);

  const { acao } = useContext(AssetsContext);

  const [editValues, setEditValues] = useState(null);
  const [preInsertValues, setPreInsertValues] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (acao) {
      setPreInsertValues({
        image: acao.image,
        nome: acao.name,
        classe: acao.classe,
        nome_longo: acao.nome_longo,
        nomeCompania: acao.nome_longo,
        symbol: acao.symbol,
        simbolo: acao.symbol,
        stock_exchange: acao.exchange
      })
    }
  }, [acao])


  const [isOpen, setIsOpen] = useState(false);
  const [toDeleteId, setToDeleteId] = useState(null);


  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const [show, setShow] = useState(false);

  const toggleDeleteModal = () => {
    setShow(!show);
  };

  const icons = {
    compra: <Badge className="bg-success font-size-10">Compra</Badge>,
    desdobramento: <Badge className="bg-info font-size-10">Desdobramento</Badge>,
    venda: <Badge className="bg-danger font-size-10">Venda</Badge>,
    grupamento: <Badge className="bg-info font-size-10">Grupamento</Badge>,
    dayTrade: <Badge className="bg-info font-size-10">Day Trade</Badge>,
    swingTrade: <Badge className="bg-warning font-size-10">Swing Trade</Badge>,
    opcao: <Badge className="bg-dark font-size-10">Opção</Badge>,
  }

  useEffect(() => {
    // Verificar se type é diferente de assetType para evitar chamadas desnecessárias
    if (type !== assetType) {
      setAssetType(type);
    }

    // Verificar se slug é diferente de selectedAsset para evitar chamadas desnecessárias
    if (slug !== selectedAsset) {
      setSelectedAsset(slug);
    }
  }, [type, assetType, slug, selectedAsset]);

  useEffect(() => {
    if (type === assetType && slug === selectedAsset) {
      if (assetType === 'acoes') {
        selectStock();
        selectProventos();
      } else if (assetType === 'fiis') {
        selectFii();
        selectProventosFii();
      }
    }
  }, [assetType, selectedAsset]);

  if (extract && extract.length === 0 || extract.dto.length === 0) {
    return (
      <React.Fragment>
        <div className="text-center">
          <Col lg="12">
            <Row className="justify-content-center mt-5">
              <Col sm="4">
                <div className="maintenance-img">
                  <img
                    src={builtWallet}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
            </Row>
            <p className="mt-5">Sem registros para esse ativo</p>
            <div className="flex-shrink-0 mb-4">
              <button onClick={() => { toggle(); setEditValues(preInsertValues) }} className="btn btn-primary btn-sm w-xs">Adicionar Registro</button>
            </div>
          </Col>
        </div>
        <CadastroModal isOpen={isOpen} toggle={toggle} editValues={editValues} setEditValues={setEditValues} />
      </React.Fragment>
    )
  }

  const sm = '3'

  return (
    <React.Fragment>

      <Row>
        <Col sm={sm}>
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex">
                <div className="me-3 align-self-center">
                  <i className="mdi mdi-chart-donut h2 text-warning mb-0" />
                </div>
                <div className="flex-grow-1">
                  <p className="text-muted mb-2">Total Investido</p>
                  <h5 className="mb-0">
                    {extract.totalPrecoMedio ? formatCurrency(Number(extract?.totalPrecoMedio), 'pt-BR', 'BRL') : 'R$ 0,00'}
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm={sm}>
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex">
                <div className="me-3 align-self-center">
                  <i className="mdi mdi-distribute-horizontal-center h2 text-primary mb-0" />
                </div>
                <div className="flex-grow-1">
                  <p className="text-muted mb-2">Total Consolidado</p>
                  <h5 className="mb-0">
                    {extract.totalCotacaoAtual ? formatCurrency(Number(extract?.totalCotacaoAtual), 'pt-BR', 'BRL') : 'R$ 0,00'}
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm={sm}>
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex">
                <div className="me-3 align-self-center">
                  <i className="mdi mdi-currency-usd-circle-outline h2 text-info mb-0" />
                </div>
                <div className="flex-grow-1">
                  <p className="text-muted mb-2">Proventos Recebidos</p>
                  <h5 className="mb-0">
                    {dividend.valor_total_dividendos_pagos ? formatCurrency(Number(dividend?.valor_total_dividendos_pagos), 'pt-BR', 'BRL') : 'R$ 0,00'}
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm={sm}>
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex">
                <div className="me-3 align-self-center">
                  <i className="mdi mdi-currency-usd-circle-outline h2 text-info mb-0" />
                </div>
                <div className="flex-grow-1">
                  <p className="text-muted mb-2">Proventos a Receber</p>
                  <h5 className="mb-0">
                    {dividend.valor_total_dividendos_a_pagar ? formatCurrency(Number(dividend.valor_total_dividendos_a_pagar), 'pt-BR', 'BRL') : 'R$ 0,00'}
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* Segundo Blogo */}
      <Row style={{ display: "flex" }}>
        <Col xl="8" style={{ minHeight: "300px", display: "flex", flexDirection: "column" }}>
          <Card style={{ height: "100%" }}>
            <CardBody>
              <div className="d-flex align-items-center">
                <h5 className="mb-4 card-title flex-grow-1 ">Extrato de Registros</h5>
                <div className="flex-shrink-0 mb-4">
                  <button onClick={() => { toggle(); setEditValues(preInsertValues) }} className="btn btn-primary btn-sm w-xs">Novo Registro</button>
                </div>
              </div>
              <SimpleBar style={{ maxHeight: "300px", minHeight: "300px" }}>
                <div className="table-responsive">
                  <Table className="table table-nowrap align-middle mb-0 table-sticky">
                    <thead>
                      <tr>
                        <th scope="col">Registro</th>
                        <th scope="col">Detalhes</th>
                        <th scope="col">Custos</th>
                        <th scope="col">Operação</th>
                        {/* <th scope="col">Imposto</th> */}
                        <th scope="col">Total</th>
                        <th scope="col">Editar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {extract.dto && extract.dto.map((extrato, key) => (
                        <tr key={extrato.id}>
                          <td scope="row">
                            <div className="d-flex align-items-center">
                              <div>
                                {extrato.tipoAcao === "COMPRA" && icons.compra}
                                {extrato.tipoAcao === "VENDA" && icons.venda}
                                {extrato.tipoAcao === "DESDOBRAMENTO" && icons.desdobramento}
                                {extrato.tipoAcao === "GRUPAMENTO" && icons.grupamento}
                              </div>
                            </div>
                            <div className="text-muted">
                              <span>{`${formatData(extrato.data).data}`}</span>
                            </div>
                            <div className="text-muted">
                              <span>{`${formatData(extrato.data).hora}`}</span>
                            </div>
                          </td>
                          <td scope="row">
                            <div className="font-size-14"> {`Quantia: `}
                              <span className="text-muted">{extrato.quantidade}</span>
                            </div>
                            <div className="font-size-14"> {`Preço: `}
                              <span className="text-muted">{formatCurrency(Number(extrato.preço), 'pt-BR', 'BRL')}</span>
                            </div>
                          </td>
                          <td scope="row">
                            <div className="font-size-14">
                              {
                                formatCurrency(
                                  Number(extrato.corretagem) +
                                  Number(extrato.emolumentos) +
                                  Number(extrato.tarifa), 'pt-BR', 'BRL')
                              }
                            </div>
                          </td>
                          {/* <td>
                              <div className="text-muted">{formatCurrency(extrato.corretagem, 'pt-BR', 'BRL')}</div>
                              <div className="text-muted">{formatCurrency(extrato.emolumentos, 'pt-BR', 'BRL')}</div>
                              <div className="text-muted">{formatCurrency(extrato.tarifa, 'pt-BR', 'BRL')}</div>
                            </td> */}
                          <td>
                            <h5 className="font-size-14 mb-1">
                              {extrato.tipoOperacao === "SWING_TRADE" && icons.swingTrade}
                              {extrato.tipoOperacao === "DAY_TRADE" && icons.dayTrade}
                              {extrato.tipoOperacao === "OPCAO" && icons.opcao}
                            </h5>
                          </td>
                          {/* <td>
                            <h5 className="font-size-14 mb-1">
                              {formatCurrency(extrato.impostoRetidoFonte, 'pt-BR', 'BRL')}
                            </h5>
                          </td> */}
                          <td>
                            <h5 className="font-size-14 mb-1">
                              {
                                formatCurrency(
                                  Number(extrato.preço) *
                                  Number(extrato.quantidade) +
                                  Number(extrato.corretagem) +
                                  Number(extrato.emolumentos) +
                                  Number(extrato.tarifa), 'pt-BR', 'BRL')
                              }
                            </h5>
                          </td>
                          <td style={{ width: "120px" }}>
                            <button
                              className="btn btn-primary btn-sm w-xs"
                              onClick={() => {
                                setEditValues(extrato)
                                toggle()
                              }}
                            >
                              Editar
                              {/* isMobile && <i className="bx bx-pencil"></i> */}
                            </button>
                            {' '}
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => { setToDeleteId(extrato.id), toggleDeleteModal() }}>
                              <i className="bx bx-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </SimpleBar>
            </CardBody>
          </Card>
        </Col>

        <Col xl="4" style={{ minHeight: "300px", display: "flex", flexDirection: "column" }}>
          <Card style={{ height: "100%" }}>
            <CardBody>
              <div className="d-flex align-items-center">
                <h5 className="mb-4 card-title flex-grow-1">Proventos Recebidos</h5>
              </div>
              <SimpleBar style={{ maxHeight: "300px", minHeight: "300px" }}>
                <div className="table-responsive">
                  <Table className="table table-nowrap align-middle mb-0">
                    {/* <thead>
                      <tr>
                        <th scope="col">{' '}</th>
                        <th scope="col">Data</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead> */}
                    <tbody>
                      {dividend.dividendos && dividend.dividendos.map((provento, key) => (
                        <tr key={key}>

                          <td>
                            <div className="font-size-22 text-success">
                              <i className="bx bx-up-arrow-circle" />
                            </div>
                          </td>

                          <td>
                            <div>
                              <h5 className="font-size-14 mb-1 text-start">Data</h5>
                              {formatDataBarra(provento.data_pagamento)}
                            </div>
                          </td>
                          <td>
                            <div className="font-size-14 mb-0 text-end">
                              <h5 className="font-size-14 mb-0"> {
                                provento.tipo === "DIVIDENDO"
                                  ? "Dividendo"
                                  : provento.tipo === "JSCP"
                                    ? "JSCP"
                                    : provento.tipo === "RENDIMENTO TRIBUTADO"
                                      ? "Rend. Trib."
                                      : provento.tipo
                              }</h5>
                              {formatCurrency(Number(provento.totalPago), 'pt-BR', 'BRL')}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </SimpleBar>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <CadastroModal isOpen={isOpen} setEditValues={setEditValues} toggle={toggle} editValues={
        acao ? {
          ...editValues,
          image: acao.image,
          nome_longo: acao.nome_longo,
          symbol: acao.symbol
        } : editValues
      } />


      <Modal size="sm" isOpen={show} toggle={toggleDeleteModal} centered={true}>
        <div className="modal-content">
          <ModalBody className="px-4 py-5 text-center">
            {/* <button type="button" onClick={onDeleteClick} className="btn-close position-absolute end-0 top-0 m-3"></button> */}
            <div className="avatar-sm mb-4 mx-auto">
              <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                <i className="mdi mdi-trash-can-outline"></i>
              </div>
            </div>
            <p className="text-muted font-size-16 mb-4">Tem certeza que deseja excluir esse registro?</p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button type="button" className="btn btn-danger" onClick={() => {
                try {
                  setIsDeleting(true)
                  deleteRegisterById(assetType, toDeleteId).then((result) => {
                    setIsDeleting(false);
                    toggleDeleteModal()
                  })
                    .catch((err) => {
                      setIsDeleting(false)
                      console.error('Erro inesperado:', err.message);
                    })

                } catch (error) {
                  console.error(error)
                }
              }}>
                {isDeleting ? (
                  <span>Deletando <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i></span>
                ) : (
                  <span>Deletar <i className="bx bx-delete"></i></span>
                )}

              </button>

              <button type="button" className="btn btn-secondary" onClick={() => { setToDeleteId(null), toggleDeleteModal() }}>Sair</button>
            </div>
          </ModalBody>
        </div>
      </Modal>

    </React.Fragment>
  );
};

export default Extract;

import React, { useState, useEffect, useContext } from 'react';
import { Card, CardBody, Col, Row } from "reactstrap";
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/grid';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/chart/candlestick';
import { AssetsContext } from 'contexts/assets-context/assets-context';
import { formatCurrency } from "helpers/formatCurrency";
import { LayoutContext } from 'contexts/layout-context/layout-contex';

const CandleChart = () => {

  const {
    acao,
    getCotacaoHistorica,
    cotacaoHistorica,
    selectedTicker
  } = useContext(AssetsContext)

  const { isMobile } = useContext(LayoutContext)

  const [chartStyle, setChartStyle] = useState({});

  useEffect(() => {
    isMobile ? setChartStyle({ marginTop: '-8%' }) : setChartStyle({})
  }, [isMobile])

  const [dataRange, setDataRange] = useState({
    inicial: null,
    final: null
  });

  const [timeToFilter, setTimeToFilter] = useState({
    ano: 0,
    mes: 1,
    dia: 0
  });

  useEffect(() => {
    const agora = new Date();
    const dataFinal = new Date(agora);

    dataFinal.setDate(dataFinal.getDate() - timeToFilter.dia);
    dataFinal.setMonth(dataFinal.getMonth() - timeToFilter.mes);
    dataFinal.setFullYear(dataFinal.getFullYear() - timeToFilter.ano);

    const formattedInicial = `${dataFinal.getFullYear()}-${(dataFinal.getMonth() + 1).toString().padStart(2, '0')}-${dataFinal.getDate().toString().padStart(2, '0')}`;
    const formattedFinal = `${agora.getFullYear()}-${(agora.getMonth() + 1).toString().padStart(2, '0')}-${agora.getDate().toString().padStart(2, '0')}`;

    setDataRange({
      inicial: formattedInicial,
      final: formattedFinal
    });
  }, [timeToFilter]);

  useEffect(() => {
    if (dataRange.inicial && dataRange.final) {
      getCotacaoHistorica(dataRange.inicial, dataRange.final);
    }
  }, [dataRange, selectedTicker]);

  const normalizedData = cotacaoHistorica && cotacaoHistorica.map(item => ({
    x: item.date,
    y: [item.open, item.close, item.low, item.high]
  }));


  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        animation: false,
        type: 'cross',
        lineStyle: {
          color: '#376df4',
          width: 2,
          opacity: 1
        }
      }
    },
    dataZoom: [
      {
        id: 'dataZoomX',
        type: 'slider',
        xAxisIndex: [0],
        filterMode: 'filter'
      },
      {
        id: 'dataZoomY',
        type: 'inside',
        xAxisIndex: [0],
        filterMode: 'empty'
      }
    ],
    grid: {
      left: '10%',
      right: '10%',
      bottom: 80
    },
    xAxis: {
      type: 'category',
      data: cotacaoHistorica && normalizedData.map(item => item.x),
      axisLine: {
        lineStyle: {
          color: '#8791af'
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgba(166, 176, 207, 0.1)"
        }
      },
    },
    yAxis: {
      scale: true,
      axisLine: {
        lineStyle: {
          color: '#8791af'
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgba(166, 176, 207, 0.1)"
        }
      },
    },
    series: [
      {
        type: 'candlestick',
        name: 'Dia',
        data: cotacaoHistorica && normalizedData.map(item => item.y),
        itemStyle: {
          color: '#0CF49B',
          color0: '#FD1050',
          borderColor: '#0CF49B',
          borderColor0: '#FD1050'
        }
      },
      /* {
        type: 'candlestick',
        name: 'Dia',
        data: cotacaoHistorica && normalizedData.map(item => item.y),
        itemStyle: {
          color: '#FD1050',
          color0: '#0CF49B',
          borderColor: '#FD1050',
          borderColor0: '#0CF49B'
        }
      }, */
    ],
  };

  const [activeButton, setActiveButton] = useState('1M'); // Defina o botão ativo inicial

  const handleButtonClick = (filter, label) => {
    setTimeToFilter(filter);
    setActiveButton(label);
  };

  return (
    <React.Fragment>
      <div className="d-flex flex-wrap">
        <h4 className="card-title me-2 mb-3">Cotações e Histórico</h4>
        <div className="ms-auto">
          <div className="toolbar d-flex flex-wrap gap-2 text-end">
            {/* <button
                    type="button"
                    className={`btn btn-light btn-sm ${activeButton === 'TOTAL' ? 'active' : ''}`}
                    onClick={() => handleButtonClick({ ano: 30, mes: 0, dia: 0 }, 'TOTAL')}
                  >
                    TOTAL
                  </button> */}
            <button
              type="button"
              className={`btn btn-light btn-sm ${activeButton === '5A' ? 'active' : ''}`}
              onClick={() => handleButtonClick({ ano: 5, mes: 0, dia: 0 }, '5A')}
            >
              5A
            </button>
            <button
              type="button"
              className={`btn btn-light btn-sm ${activeButton === '1A' ? 'active' : ''}`}
              onClick={() => handleButtonClick({ ano: 1, mes: 0, dia: 0 }, '1A')}
            >
              1A
            </button>
            <button
              type="button"
              className={`btn btn-light btn-sm ${activeButton === '6M' ? 'active' : ''}`}
              onClick={() => handleButtonClick({ ano: 0, mes: 6, dia: 0 }, '6M')}
            >
              6M
            </button>
            <button
              type="button"
              className={`btn btn-light btn-sm ${activeButton === '1M' ? 'active' : ''}`}
              onClick={() => handleButtonClick({ ano: 0, mes: 1, dia: 0 }, '1M')}
            >
              1M
            </button>
          </div>
        </div>
      </div>

      <div className="text-center mt-3">
        <Row className="mt-2">
          {/* <Col></Col> */}
          <Col >
            <div className="mt-sm-0">
              <p className="text-muted mb-1">Mínima 52 Semanas</p>
              <p className={'font-weight-bold font-size-15'}>{acao && formatCurrency(Number(acao.min_ultimas_52_sem), 'pt-BR', 'BRL')}</p>
            </div>
          </Col>
          <Col >
            <div className="mt-sm-0">
              <p className="text-muted mb-1">Máxima 52 Semanas</p>
              <p className={'font-weight-bold font-size-15'}>{acao && formatCurrency(Number(acao.max_ultimas_52_sem), 'pt-BR', 'BRL')}</p>
            </div>
          </Col>
          <Col >
            <div className="mt-sm-0">
              <p className="text-muted mb-1">Valorização: 12 Meses</p>
              <p className={'font-weight-bold font-size-15'}>
                {`${acao && acao.variacao_doze_meses && Number(acao.variacao_doze_meses).toFixed(2)}%`}
                <span className={`text-${acao && acao.variacao_doze_meses > 0 ? 'success' : 'danger'} font-size-16`}>
                  <i className={`mdi mdi-arrow-${acao && acao.variacao_doze_meses > 0 ? 'up' : 'down'} ms-1`}></i>
                </span>
              </p>
            </div>
          </Col>
        </Row>
      </div>


      <div style={chartStyle}>
        {cotacaoHistorica &&
          <ReactEcharts
            option={option}
            echarts={echarts}
            style={{ height: '360px' }}
          />
        }
      </div>
    </React.Fragment>
  );
};

export default CandleChart;

import React, { useState, createContext, useEffect } from "react";
//import { toast } from 'react-toastify';

export const PaginationContext = createContext();

const PaginationProvider = ({ children }) => {

    const [pagina, setPagina] = useState(1);

    return (
        <PaginationContext.Provider value={{
            pagina, setPagina
        }}>
            {children}
        </PaginationContext.Provider>
    );
};

export default PaginationProvider;
import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  CardBody,
  CardHeader,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
} from "reactstrap";
import classnames from "classnames";
import { AssetsContext } from "contexts/assets-context/assets-context";

//SimpleBar
import SimpleBar from "simplebar-react"
import PostDetail from "./PostDetail";


const Posts = () => {

  const {
    acao,
    fatosRelevantes,
    getFatosRelevantes,
    selectedTicker
  } = useContext(AssetsContext)

  const [activeTab, setActiveTab] = useState("1")
  const [modal_fullscreen, setmodal_fullscreen] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState('');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen);
    // removeBodyCss();
  }

  const [dataRange, setDataRange] = useState({
    inicial: null,
    final: null
  });

  const [timeToFilter, setTimeToFilter] = useState({
    ano: 0,
    mes: 3,
    dia: 0
  });

  useEffect(() => {
    const agora = new Date();
    const dataFinal = new Date(agora);

    dataFinal.setDate(dataFinal.getDate() - timeToFilter.dia);
    dataFinal.setMonth(dataFinal.getMonth() - timeToFilter.mes);
    dataFinal.setFullYear(dataFinal.getFullYear() - timeToFilter.ano);

    const formattedInicial = `${dataFinal.getFullYear()}-${(dataFinal.getMonth() + 1).toString().padStart(2, '0')}-${dataFinal.getDate().toString().padStart(2, '0')}`;
    const formattedFinal = `${agora.getFullYear()}-${(agora.getMonth() + 1).toString().padStart(2, '0')}-${agora.getDate().toString().padStart(2, '0')}`;

    setDataRange({
      inicial: formattedInicial,
      final: formattedFinal
    });
  }, [timeToFilter]);

  useEffect(() => {
    if (dataRange.inicial && dataRange.final && !fatosRelevantes) {
      getFatosRelevantes(dataRange.inicial, dataRange.final);
    }
  }, [dataRange, selectedTicker, acao, fatosRelevantes]);

  return (
    <React.Fragment>
      <CardHeader className="bg-transparent border-bottom">
        <div className="d-flex flex-wrap">
          <div className="me-2">
            <h5 className="card-title mt-1 mb-0">Info</h5>
          </div>
          <ul
            className="nav nav-tabs nav-tabs-custom card-header-tabs ms-auto"
            role="tablist"
          >
            <NavItem>
              <NavLink
                to="#"
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1")
                }}
              >
                Comunicados
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2")
                }}
              >
                Notícias
              </NavLink>
            </NavItem> */}
          </ul>
        </div>
      </CardHeader>

      <SimpleBar style={{ maxHeight: "350px" }}>
        <div>
          <TabContent activeTab={activeTab}>
            <TabPane className="show " tabId="1">
              <ul className="list-group list-group-flush">
                {fatosRelevantes && fatosRelevantes.map((item, index) => (
                  <li className="list-group-item py-3" key={index}>

                    <div className="d-flex"
                    /* onClick={() => {
                      tog_fullscreen();
                      setSelectedPdf(item.link)
                    }} */
                    >
                      <div className="me-3">
                        <img
                          src={acao && acao.image}
                          alt=""
                          className="avatar-xs h-auto d-block rounded cursor-pointer"
                        />
                      </div>
                      <div className="align-self-center overflow-hidden me-auto cursor-pointer">
                        <Link to={item.link} target='_blank' className="text-dark">
                          <div>
                            <h5 className="font-size-14 text-truncate"
                            >

                              {item.classe}

                            </h5>
                            <p className="mb-0">{item.info}</p>
                            <p className="text-muted mb-0">{item.data}</p>
                          </div>
                        </Link>
                      </div>

                    </div>
                  </li>

                ))}
              </ul>
            </TabPane>

            {/* <TabPane className="show" tabId="2">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item py-3">
                    <div className="d-flex">
                      <div className="me-3">
                        <img
                          src={"img6"}
                          alt=""
                          className="avatar-md h-auto d-block rounded"
                        />
                      </div>

                      <div className="align-self-center overflow-hidden me-auto">
                        <div>
                          <h5 className="font-size-14 text-truncate">
                            <Link to="#" className="text-dark">
                              Drawing a sketch
                            </Link>
                          </h5>
                          <p className="text-muted mb-0">02 Nov, 2020</p>
                        </div>
                      </div>

                      <UncontrolledDropdown className="ms-2">
                        <DropdownToggle
                          className="text-muted font-size-14"
                          tag="a"
                          color="white"
                          type="button"
                        >
                          <i className="mdi mdi-dots-horizontal"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <Link className="dropdown-item" to="#">
                            Action
                          </Link>
                          <Link className="dropdown-item" to="#">
                            Another action
                          </Link>
                          <Link className="dropdown-item" to="#">
                            Something else
                          </Link>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </li>

                  <li className="list-group-item py-3">
                    <div className="d-flex">
                      <div className="me-3">
                        <img
                          src={"img2"}
                          alt=""
                          className="avatar-md h-auto d-block rounded"
                        />
                      </div>

                      <div className="align-self-center overflow-hidden me-auto">
                        <div>
                          <h5 className="font-size-14 text-truncate">
                            <Link to="#" className="text-dark">
                              Beautiful Day with Friends
                            </Link>
                          </h5>
                          <p className="text-muted mb-0">10 Nov, 2020</p>
                        </div>
                      </div>

                      <UncontrolledDropdown className="ms-2">
                        <DropdownToggle
                          className="text-muted font-size-14"
                          tag="a"
                          color="white"
                          type="button"
                        >
                          <i className="mdi mdi-dots-horizontal"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <Link className="dropdown-item" to="#">
                            Action
                          </Link>
                          <Link className="dropdown-item" to="#">
                            Another action
                          </Link>
                          <Link className="dropdown-item" to="#">
                            Something else
                          </Link>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </li>

                  <li className="list-group-item py-3">
                    <div className="d-flex">
                      <div className="me-3">
                        <img
                          src={"img1"}
                          alt=""
                          className="avatar-md h-auto d-block rounded"
                        />
                      </div>

                      <div className="align-self-center overflow-hidden me-auto">
                        <div>
                          <h5 className="font-size-14 text-truncate">
                            <Link to="#" className="text-dark">
                              Riding bike on road
                            </Link>
                          </h5>
                          <p className="text-muted mb-0">20 Oct, 2020</p>
                        </div>
                      </div>

                      <UncontrolledDropdown className="ms-2">
                        <DropdownToggle
                          className="text-muted font-size-14"
                          tag="a"
                          color="white"
                          type="button"
                        >
                          <i className="mdi mdi-dots-horizontal"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <Link className="dropdown-item" to="#">
                            Action
                          </Link>
                          <Link className="dropdown-item" to="#">
                            Another action
                          </Link>
                          <Link className="dropdown-item" to="#">
                            Something else
                          </Link>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </li>

                  <li className="list-group-item py-3">
                    <div className="d-flex">
                      <div className="me-3">
                        <img
                          src={"img2"}
                          alt=""
                          className="avatar-md h-auto d-block rounded"
                        />
                      </div>

                      <div className="align-self-center overflow-hidden me-auto">
                        <div>
                          <h5 className="font-size-14 text-truncate">
                            <Link to="#" className="text-dark">
                              Project discussion with team
                            </Link>
                          </h5>
                          <p className="text-muted mb-0">24 Oct, 2020</p>
                        </div>
                      </div>

                      <UncontrolledDropdown className="ms-2">
                        <DropdownToggle
                          className="text-muted font-size-14"
                          tag="a"
                          color="white"
                          type="button"
                        >
                          <i className="mdi mdi-dots-horizontal"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <Link className="dropdown-item" to="#">
                            Action
                          </Link>
                          <Link className="dropdown-item" to="#">
                            Another action
                          </Link>
                          <Link className="dropdown-item" to="#">
                            Something else
                          </Link>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </li>
                </ul>
              </TabPane> */}
          </TabContent>
        </div>
      </SimpleBar>

      <Modal
        size="xl"
        isOpen={modal_fullscreen}
        toggle={() => {
          tog_fullscreen();
        }}
        className="modal-fullscreen"
      >
        <div className="modal-header">
          <h5
            className="modal-title mt-0"
            id="exampleModalFullscreenLabel"
          >
            Fullscreen Modal
          </h5>
          <button
            onClick={() => {
              setmodal_fullscreen(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <PostDetail selected={selectedPdf} />

        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_fullscreen();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary "
          >
            Save changes
          </button>
        </div>
      </Modal>

    </React.Fragment>
  )
}

export default Posts

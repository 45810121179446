import React, { useState, createContext, useEffect } from "react";
//import { toast } from 'react-toastify';

export const AdminContext = createContext();

const AdminProvider = ({ children }) => {

    const [showDevComponents, setShowDevComponents] = useState(false);


    return (
        <AdminContext.Provider value={{
            showDevComponents, setShowDevComponents
        }}>
            {children}
        </AdminContext.Provider>
    );
};

export default AdminProvider;
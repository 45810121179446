import { AssetsContext } from "contexts/assets-context/assets-context";
import React, { useContext } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getClassByValue } from "../Helper/helper-fundamentals";

const Valuation = () => {

    const {
        acao
    } = useContext(AssetsContext);

    const normalizedData = [
        {
            name: 'Yield',
            item: acao.dividend_yeld,
            class: 'percent',
            balizador: 6,
            link: '',
            info: 'O Dividend Yield é o indicador que verifica a performance da organização mediante os proventos que foram pagos aos acionistas da empresa ao longo dos últimos 12 meses do ano.',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'mais',
        },
        {
            name: 'P/L',
            item: acao.preco_lucro,
            class: 'number',
            balizador: 10,
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'range',
            min: 0.01
        },
        {
            name: 'P/VP',
            item: acao.preco_valor_patrimonial_acao,
            class: 'number',
            balizador: 1.5,
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'menos'
        },
        {
            name: 'P/EBIT',
            item: acao.preco_ebit,
            class: 'number',
            balizador: 1.5,
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'range',
            min: 0.01
        },
        {
            name: 'P/EBITDA',
            item: acao.preco_ebitda,
            class: 'number',
            balizador: 1.5,
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'range',
            min: 0.01
        },
        {
            name: 'VPA',
            item: acao.valor_patrimonial_por_acao,
            class: 'number',
            balizador: Number(acao.cotacao),
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'mais'
        },
        {
            name: 'LPA',
            item: acao.lucro_por_acao,
            class: 'number',
            balizador: null, //Não deve haver máximo
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'range',
            min: Number(acao.cotacao) * 0.2
        }
    ]

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="d-flex">
                        <h4 className="card-title mb-4 flex-grow-1">Indicadores de Valuation</h4>
                    </div>
                    <div className="mt-0">
                        <Row>
                            {normalizedData.map((item, index) => (
                                <Col key={index} className="mt-2">
                                    <div className="mt-sm-0 mb-2">
                                        <div className="me-2">
                                            <p className={`font-size-11 text-muted mb-2`}>
                                                <i className={`mdi mdi-circle align-middle font-size-10 pe-1 ${getClassByValue(item.item, item.balizador, item.min, item.melhor)}`} />
                                                {item.name}
                                            </p>
                                            <h5 className={`mb-0 `}>
                                                {`${Number(item.item).toFixed(2)}${item.class === 'percent' ? '%' : ''}`}
                                            </h5>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default Valuation;
import React, { createContext, useState } from 'react';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {

    const [token, setToken] = useState(localStorage.getItem('@user') || null);
    const [refresh, setRefresh] = useState(localStorage.getItem('@refreshToken') || null);
    const [userStatus, setUserStatus] = useState(localStorage.getItem('@status') || null);

    return (
        <AuthContext.Provider value={{ token, setToken, refresh, setRefresh, userStatus, setUserStatus }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
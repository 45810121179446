import { AssetsContext } from "contexts/assets-context/assets-context";
import React, { useContext } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getClassByValue } from "../Helper/helper-fundamentals";

const Endividamento = () => {

    const {
        acao
    } = useContext(AssetsContext);

    const normalizedData = [
        {
            name: 'Dív. Líquida/PL',
            item: acao.divida_liquida_patrimonio_liquido,
            class: 'number',
            balizador: 1.5,
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'menos',
        },
        {
            name: 'Dív. Líquida/Ebitda',
            item: acao.divida_liquida_ebitda,
            class: 'number',
            balizador: 2,
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'menos',
        },
        {
            name: 'Dív. Líquida/Ebit',
            item: acao.divida_liquida_ebit,
            class: 'number',
            balizador: 1.5,
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'menos',
        },
        {
            name: 'PL/Ativos',
            item: acao.patrimonio_liquido_ativos,
            class: 'number',
            balizador: 1,
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'menos',
        },
        {
            name: 'Passivos/Ativos',
            item: acao.passivos_ativos,
            class: 'number',
            balizador: 1,
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'menos',
        },
        {
            name: 'Liq. Corrente',
            item: acao.liquidez_corrente,
            class: 'number',
            balizador: 1,
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'mais'
        }
    ]

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="d-flex">
                        <h4 className="card-title mb-4 flex-grow-1">Endividamento</h4>
                    </div>
                    <div className="mt-0">
                        <Row>
                            {normalizedData.map((item, index) => (
                                <Col key={index} className="mt-2">
                                    <div className="mt-sm-0 mb-2">
                                        <div className="me-2">
                                            <p className={`font-size-11 text-muted mb-2`}>
                                                <i className={`mdi mdi-circle align-middle font-size-10 pe-1 ${getClassByValue(item.item, item.balizador, item.min, item.melhor)}`} />
                                                {item.name}
                                            </p>
                                            <h5 className={`mb-0 `}>
                                                {`${Number(item.item).toFixed(2)}${item.class === 'percent' ? '%' : ''}`}
                                            </h5>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default Endividamento;
import React, { useState, useEffect, useContext } from 'react';
import ReactEcharts from "echarts-for-react";
import { Card, CardBody } from 'reactstrap';
import { formatDataYYYYMMDD } from "helpers/formatDateTime";
import getChartColorsArray from "../../../../components/Common/ChartsDynamicColor";
import { AssetsContext } from 'contexts/assets-context/assets-context';
import { formatarNumeroComUnidadesLetraUnica } from 'helpers/formatNumbers';

const LineBar = ({ seriesData, dataColors }) => {
    const spineareaChartColors = getChartColorsArray(dataColors);

    const options = {
        grid: {
            /* zlevel: 0,
            x: 80,
            x2: 50,
            y: 30,
            y2: 30, */
            borderWidth: 0,
            backgroundColor: "rgba(0,0,0,0)",
            borderColor: "rgba(0,0,0,0)",
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
                crossStyle: {
                    color: "#999",
                },
            },
        },
        toolbox: {
            orient: "center",
            left: 0,
            top: 20,
            feature: {
                dataView: { show: false, readOnly: false, title: "Data View" },
                magicType: { show: false, type: ["line", "bar"], title: { line: "For line chart", bar: "For bar chart" } },
                restore: { show: false, title: "restore" },
                saveAsImage: { show: false, title: "Download Image" },
            },
        },
        color: spineareaChartColors,
        legend: {
            data: ["Lucro Líquido", "Proventos", "Payout"],
            textStyle: {
                color: ["#8791af"],
            },
        },
        xAxis: [
            {
                type: "category",
                data: seriesData.label,
                axisPointer: {
                    type: "shadow",
                },
                axisLine: {
                    lineStyle: {
                        color: "#8791af",
                    },
                },
            },
        ],
        yAxis: [
            {
                type: "value",
                /* name: "Lucro Líquido", */
                axisLine: {
                    lineStyle: {
                        color: '#8791af'
                    },
                },
                splitLine: {
                    lineStyle: {
                        color: "rgba(166, 176, 207, 0.1)"
                    }
                },
                axisLabel: {
                    formatter: function (value) {
                        return formatarNumeroComUnidadesLetraUnica(value);
                    }
                }
            },
            {
                type: "value",
                /* name: "Payout", */
                axisLine: {
                    lineStyle: {
                        color: '#8791af'
                    },
                },
                splitLine: {
                    lineStyle: {
                        color: "rgba(166, 176, 207, 0.1)"
                    }
                },
                axisLabel: {
                    formatter: '{value}%'
                }
            },
        ],
        series: [
            {
                name: 'Lucro Líquido',
                type: 'bar',
                data: seriesData.lucro_liquido,
            },
            {
                name: 'Proventos',
                type: 'bar',
                data: seriesData.proventos,
            },
            {
                name: 'Payout',
                type: 'line',
                yAxisIndex: 1,
                data: seriesData.payout,
                label: {
                    show: true,
                    position: 'top',
                    formatter: '{c}%',
                    fontWeight: 'bold'
                },
                markLine: {
                    data: [{ type: 'average', name: 'Avg' }],
                    label: {
                        show: true,
                        position: 'middle',
                        formatter: '{c}%'
                    },

                }
            },
        ],
        textStyle: {
            color: ["#74788d"],
        },
    }


    return (
        <React.Fragment>
            <ReactEcharts style={{ height: "350px" }} option={options} />
        </React.Fragment>
    );
};

const PayoutChart = () => {

    const { payout } = useContext(AssetsContext);

    const [chartData, setChartData] = useState({
        lucro_liquido: [],
        proventos: [],
        payout: [],
        label: [],
    });

    useEffect(() => {
        if (payout) {
            const formattedData = {
                lucro_liquido: payout.map(item => item.lucro_liquido),
                proventos: payout.map(item => item.proventos),
                payout: payout.map(item => Number(item.payout).toFixed(2)),
                label: payout.map(item => String(formatDataYYYYMMDD(item.data).getFullYear())),
            };
    
            // Criar um array de índices para ordenação
            const sortedIndices = formattedData.label.map((_, index) => index).sort((a, b) => {
                const dateA = new Date(formattedData.label[a]);
                const dateB = new Date(formattedData.label[b]);
                return dateA - dateB;
            });
    
            // Ordenar os arrays com base nos índices ordenados
            formattedData.lucro_liquido = sortedIndices.map(index => formattedData.lucro_liquido[index]);
            formattedData.proventos = sortedIndices.map(index => formattedData.proventos[index]);
            formattedData.payout = sortedIndices.map(index => formattedData.payout[index]);
            formattedData.label = sortedIndices.map(index => formattedData.label[index]);
    
            setChartData(formattedData);
        }
    }, [payout]);

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="d-sm-flex flex-wrap">
                        <h4 className="card-title mb-4">Lucro, Proventos e Payout</h4>

                    </div>
                    <LineBar seriesData={chartData} dataColors='["--bs-primary", "--bs-success", "--bs-warning", "--bs-info"]' dir="ltr" />
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default PayoutChart;
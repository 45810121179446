import React, { useContext, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Container,
} from "reactstrap";
import classnames from "classnames";
import OptionList from "./option-list";
import OptionModal from "components/Modals/option-modal/option-modal";
import { OptionContext } from "contexts/options-context/options-context";


const WalletActivities = () => {

  const { selectOption } = useContext(OptionContext)

  const [activeTab, setActiveTab] = useState('put');
  const [modal, setModal] = useState(false);

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  useEffect(() => {
    selectOption(activeTab.toUpperCase())
  }, [activeTab])

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);

      switch (tab) {
        case "put":
          setActiveTab('put');
          break;

        case "call":
          setActiveTab('call');
          break;
        default:
          setActiveTab('put');
          break;
      }
    }
  };

  return (
    <>
      <Card>
        <CardBody>
          <div className="d-flex align-items-center">
            <h5 className="mb-0 card-title flex-grow-1 ">Opções Registradas</h5>
            <div className="flex-shrink-0">
              <Link to="#!" onClick={() => setModal(true)} className="btn btn-primary me-1 ">{'+ Registro'}</Link>
              {/* <Link to="#!" className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
              <UncontrolledDropdown className="dropdown d-inline-block me-1 ">
                <DropdownToggle type="menu" className="btn btn-success" id="dropdownMenuButton1">
                  <i className="mdi mdi-dots-vertical"></i></DropdownToggle>
                <DropdownMenu>
                  <li><DropdownItem href="#">Action</DropdownItem></li>
                  <li><DropdownItem href="#">Another action</DropdownItem></li>
                  <li><DropdownItem href="#">Something else here</DropdownItem></li>
                </DropdownMenu>
              </UncontrolledDropdown> */}
            </div>
          </div>
          <Container fluid>
            <ul className="nav nav-tabs nav-tabs-custom navbar-expand-lg flex-nowrap nav-justified custom-scrollbar mt-2"
              style={{
                whiteSpace: "normal",
                overflowX: "auto",
                overflowY: "hidden"
              }}>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "put",
                  })}
                  onClick={() => {
                    toggleTab("put");
                  }}
                >
                  PUT
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "call",
                  })}
                  onClick={() => {
                    toggleTab("call");
                  }}
                >
                  CALL
                </NavLink>
              </NavItem>
              {/* <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "renda-fixa",
                })}
                onClick={() => {
                  toggleTab("renda-fixa");
                }}
              >
                Renda Fixa
              </NavLink>
            </NavItem> */}
            </ul>
          </Container>
          <div className="mt-0">
            <OptionList />
          </div>
        </CardBody>
      </Card>
      {/* <OptionList /> */}

      <OptionModal isOpen={modal} toggle={toggle} />

    </>
  );
};

export default WalletActivities;

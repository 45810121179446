import React, { useContext, useState } from "react";
import { AssetsContext } from "contexts/assets-context/assets-context";
import { SearchContext } from "contexts/search-context/search-context";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from "reactstrap";
import { Link } from "react-router-dom";


const SearchBar = props => {

    const {
        ativos,
        handleInputAtivosChange
      } = useContext(SearchContext);
    
      const {
        setTickerType,
        setSelectedTicker,
        setActiveTab,
        mockParams
      } = useContext(AssetsContext);
    
      const [isOpenDropdown, setIsOpenDropdown] = useState(false)
    
      const toggleDropdown = () => {
        setIsOpenDropdown(!isOpenDropdown);
      };

    return (
        <React.Fragment>
            <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                    <Input
                        type="text"
                        autoComplete='off'
                        name="simbolo"
                        className="form-control"
                        placeholder={props.t("Search") + "..."}
                        onClick={toggleDropdown}
                        onChange={handleInputAtivosChange}
                    />
                    <span className="bx bx-search-alt" />
                </div>
            </form>
            <Dropdown className="dropdown-mega" isOpen={isOpenDropdown} toggle={toggleDropdown}>
                <DropdownToggle caret={false} style={{ display: 'none' }} />
                <DropdownMenu style={{ width: '100%', padding: '10px' }}>
                    {ativos.length > 0 ? (
                        ativos.map((item) => (
                            <DropdownItem
                                key={item.symbol}
                            >
                                <table style={{ maxWidth: '100%' }} className="table table-hover table-borderless align-middle m-0 p-0">
                                    <tbody>
                                        <tr className="mt-1">
                                            <td scope="row" className="m-0 p-0">
                                                <Link
                                                    onClick={() => {
                                                        setActiveTab('Resumo')
                                                        setSelectedTicker(item.symbol.toLowerCase());
                                                        setTickerType(item.classe && item.classe === 'ACAO' ? 'acoes' : 'fiis');
                                                    }}
                                                    to={`/${mockParams.route}/${mockParams.country}/${item.exchange ? item.exchange.toLowerCase() : mockParams.exchange}/${item.classe && item.classe === 'ACAO' ? 'acoes' : 'fiis'}/${item.symbol.toLowerCase()}`}
                                                    className="text-dark">

                                                    <div className="d-flex flex-wrap align-items-center">
                                                        <div className="avatar-xs me-3">
                                                            <span className="avatar-title rounded-circle bg-light">
                                                                <img src={`${item.image || null}`} alt="" className="avatar-title rounded-circle bg-light" />
                                                            </span>
                                                        </div>
                                                        <div className="col-6" style={{ maxWidth: '50%' }}>
                                                            <span className="fw-bold mb-0">{item.symbol}</span>
                                                            <div className="text-muted"
                                                                style={
                                                                    {
                                                                        maxWidth: '100%',
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                    }
                                                                }>
                                                                {item.nome_longo}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </td>

                                            <td className="text-end m-0 p-0">
                                                <div className="text-muted">
                                                    {item.exchange}{': '}{item.classe}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </DropdownItem>
                        ))
                    ) : (
                        <div style={{ paddingTop: '10px' }}>
                            <p className="placeholder-glow">
                                <span className="placeholder" style={{ width: '25%' }}></span>
                                <span className="placeholder col-12"></span>
                            </p>
                        </div>
                    )}
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    )
}

export default SearchBar;
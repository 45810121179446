import { AssetsContext } from "contexts/assets-context/assets-context";
import React, { useContext, useEffect } from "react";
import { Card, Col, Row } from "reactstrap";
import AcoesEmitidas from "./Components/acoes-emitidas";
import CompanyInfo from "./Components/company-info";
import DadosCompanhia from "./Components/dados-companhia";
import Setorizacao from "./Components/setorizacao";


const Companhia = () => {

  const {
    acao,
    assetInfo,
    getAssetInfo,
    tickerType
  } = useContext(AssetsContext);

  useEffect(() => {
    getAssetInfo()
  }, [])



  return (
    <React.Fragment>
      <Row>
        <Col xl="7">
          <Card>
            <CompanyInfo />
          </Card>
        </Col>
        <Col xl={5} lg={6}>
          <AcoesEmitidas shares={acao.numero_papeis} />
        </Col>
      </Row>

      {tickerType === 'acoes' && <Row>
        <Col xl="12">
          <Setorizacao />
        </Col>
      </Row>}

      <Row>
        <Col xl="12">
          <Card>
            <DadosCompanhia />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Companhia;
export const formatPrice = (price) => {
    if (typeof price !== 'string') {
        price = String(price);
    }
    const rawValue = price.replace(/\D/g, '');
    const formattedValue = rawValue.replace(/(\d{1,3})(\d{2})$/, '$1,$2').replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    return formattedValue;
}

export const unformatPrice = (price) => {
    if (typeof price !== 'string') {
        price = String(price);
    }

    const handleNumber = price.replace(/[,.]/g, '').replace(/(\d+)(\d{2})$/, '$1.$2')
    const numberValue = Number(handleNumber)

    return numberValue;
}

export const formatCurrency = (value, locale, currency, minFd, maxFd) => {

    const options = {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: minFd || 2,
        maximumFractionDigits: maxFd || 2,
    };

    try {
        return value.toLocaleString(locale, options);
    } catch (error) {
        console.error('Erro ao formatar:', error);
        return value;
    }
};
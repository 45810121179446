import { AssetsContext } from "contexts/assets-context/assets-context";
import React, { useContext } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getClassByValue } from "../Helper/helper-fundamentals";

const Margens = () => {

    const {
        acao
    } = useContext(AssetsContext);

    const normalizedData = [
        {
            name: 'Margem EBIT',
            item: acao.margem_ebit,
            class: 'percent',
            balizador: 30,
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'mais',
        },
        {
            name: 'Margem EBITDA',
            item: acao.margem_ebitda,
            class: 'percent',
            balizador: 40,
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'mais'
        },
        {
            name: 'ROE',
            item: acao.ROE,
            class: 'percent',
            balizador: 15,
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'mais'
        },
        {
            name: 'Margem Líquida',
            item: acao.margem_liquida,
            class: 'percent',
            balizador: 20,
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'mais'
        },
        {
            name: 'ROA',
            item: acao.ROA,
            class: 'percent',
            balizador: 10,
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'mais'
        },
        {
            name: 'Payout',
            item: acao.payout,
            class: 'percent',
            balizador: 100,
            link: '',
            info: '',
            resumeInfo: '',
            formula: '',
            grafico: '',
            melhor: 'range',
            min: 25
        }
    ]

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="d-flex">
                        <h4 className="card-title mb-4 flex-grow-1">Margens</h4>
                    </div>
                    <div className="mt-0">
                        <Row>
                            {normalizedData.map((item, index) => (
                                <Col key={index} className="mt-2">
                                    <div className="mt-sm-0 mb-2">
                                        <div className="me-2">
                                            <p className={`font-size-11 text-muted mb-2`}>
                                                <i className={`mdi mdi-circle align-middle font-size-10 pe-1 ${getClassByValue(item.item, item.balizador, item.min, item.melhor)}`} />
                                                {item.name}
                                            </p>
                                            <h5 className={`mb-0 `}>
                                                {`${Number(item.item).toFixed(2)}${item.class === 'percent' ? '%' : ''}`}
                                            </h5>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default Margens;
import React from "react";
import { Navigate } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

//Taxes
import TaxesResume from "../pages/Taxes/taxes-resume";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import PurchasePlan from "pages/Authentication/PurchasePlan";

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Login2 from "../pages/AuthenticationInner/Login2";
import Register1 from "../pages/AuthenticationInner/Register";
import Register2 from "../pages/AuthenticationInner/Register2";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2";
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail";
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2";
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification";
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2";
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification";
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2";

//Home
import Home from "../pages/Home/index";

//Acao
import AssetOverview from "pages/AssetOverview";

import Relatorio from "pages/Relatorio";
import RankinDetalhado from "pages/Relatorio/relatorio-completo"

//Wallet
import Wallet from "../pages/Wallet/wallet-wallet";

//Options
import OptionWallet from "pages/Option/option-wallet";

//Assets
import AssetsGrid from "pages/Assets";
import FavoritesAssetsGrid from "pages/Favorites/favorites"

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import Plans from "pages/Plans/Plans";

const authProtectedRoutes = [
  { path: "/", component: <Home /> },
  { path: "/assets/:country/:exchange/:type/:slug", component: <AssetOverview /> }, // "/asset/:country/:exchange/:asset/:ticker"
  { path: "/ranking", component: <Relatorio /> },
  { path: "/ranking/detail", component: <RankinDetalhado /> },

  //Wallet
  { path: "/wallet", component: <Wallet /> },

  //OptionWallet
  { path: "/options-market", component: <OptionWallet /> },

  //Taxes
  { path: "/taxes-resume", component: <TaxesResume /> },

  //Assets
  { path: "/assets", component: <AssetsGrid /> },
  { path: "/favorites", component: <FavoritesAssetsGrid /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/password-recover", component: <Recoverpw /> }, //usada para recuperar
  { path: "/plans", component: <Plans /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-login-2", component: <Login2 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/pages-register-2", component: <Register2 /> },

  { path: "/page-recoverpw-2", component: <Recoverpw2 /> },
  { path: "/pages-forgot-pwd", component: <ForgetPwd1 /> },
  { path: "/auth-recoverpw-2", component: <ForgetPwd2 /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
  { path: "/auth-lock-screen-2", component: <LockScreen2 /> },
  { path: "/page-confirm-mail", component: <ConfirmMail /> },
  { path: "/page-confirm-mail-2", component: <ConfirmMail2 /> },
  { path: "/auth-email-verification", component: <EmailVerification /> },
  { path: "/auth-email-verification-2", component: <EmailVerification2 /> },
  { path: "/auth-two-step-verification", component: <TwostepVerification /> },
  { path: "/auth-two-step-verification-2", component: <TwostepVerification2 /> },
];

const noActiveProtectedRoutes = [
  { path: "/purchase-plan", component: <PurchasePlan /> },
  { path: "/profile", component: <UserProfile /> },
];

export { authProtectedRoutes, noActiveProtectedRoutes, publicRoutes };

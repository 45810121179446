import React from 'react';
import axios from 'axios';
import toast from 'helpers/toast';
import { useNavigate } from 'react-router-dom';
import { apiConfig } from 'shared/config/env/api-env';

const throwErrorToastAndRedirectToLogin = () => {

  // Limpar os tokens, se necessário
  localStorage.removeItem('@user');
  localStorage.removeItem('@refreshToken');
  localStorage.removeItem('@status');
  toast.fail('Sessão expirada: faça login novamente')

  // Redirecionar para a página de login usando React Router
  window.location.href = '/login';

  return <></>
};

const throwErrorToastAndInactiveAndRedirectToPayment = () => {
  // Limpar os tokens, se necessário
  localStorage.removeItem('@status');
  localStorage.setItem('@status', 'INATIVO');
  toast.fail('Plano inexistente ou expirado: adquira um plano para continuar')
  window.location.href = '/plans';

  return <></>
};


const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const getToken = () => {
  const token = localStorage.getItem('@user');
  return token ? token : null;
};

const getRefreshToken = () => {
  const refreshToken = localStorage.getItem('@refreshToken');
  return refreshToken ? refreshToken : null;
};

const updateToken = async () => {
  const refreshToken = getRefreshToken();
  if (refreshToken) {
    try {
      const config = {
        headers: {
          'Refresh-Token': refreshToken,
        },
      };
      const response = await api.post('/user/refreshToken', null, config);

      const newToken = JSON.stringify(response.headers.authorization);
      localStorage.setItem('@user', JSON.parse(newToken));
      const newRefreshToken = JSON.stringify(response.headers["refresh-token"]);
      localStorage.setItem('@refreshToken', JSON.parse(newRefreshToken));

      let getUserStatus = JSON.stringify(response.headers['user']);
      localStorage.setItem('@status', JSON.parse(getUserStatus));

      return JSON.parse(newToken);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error('O refresh token é inválido:', error);
        throwErrorToastAndRedirectToLogin();
      } else {
        console.error('Erro ao atualizar o token:', error);
        throwErrorToastAndRedirectToLogin();
      }
    }
  }
  return null;
};

api.interceptors.request.use(
  async (config) => {
    const token = getToken();

    if (token && config.url !== '/user/refreshToken') {
      config.headers = { ...config.headers, Authorization: token };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && originalRequest.url === '/user/login') {
      return Promise.reject(error);
    }

    // Se a resposta retornar erro 401 (Unauthorized) e não tiver sido tentada uma nova solicitação (retried), tenta refresh
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const newToken = await updateToken();

      if (newToken) {
        // Reconfigura o header Authorization com o novo token atualizado
        originalRequest.headers.Authorization = newToken;

        // Reenvia a requisição original com o novo token
        return axios(originalRequest);
      }
    }

    if (error.response.status === 402) {
      throwErrorToastAndInactiveAndRedirectToPayment()
    }

    return Promise.reject(error);
  }
);

export default api;
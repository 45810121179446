import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import api from '../../services/api';

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

import './loadingBtn.css';

import withRouter from "components/Common/withRouter";

import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import profile from "assets/images/profile-img.png";
import logoImg from "../../assets/images/evolue-logo-menor.png";
import { AuthContext } from "contexts/auth-context/auth-context";
import { PlanContext } from "contexts/plans-context/plans-context";

const Login = props => {

  //meta title
  document.title = "Login | Evolue - Investir nunca foi tão fácil";

  const { setToken, setRefresh, setUserStatus } = useContext(AuthContext)
  const { selectedPlan } = useContext(PlanContext)

  const [isLoadingBtn, setIsLoadingBtn] = useState(false)
  const [passwordShow, setPasswordShow] = useState(false);
  const [error, setError] = useState('')

  const [buttonStyle, setButtonStyle] = useState({});

  const userLogin = async (values) => {
    setError('')
    setIsLoadingBtn(true)
    try {
      const response = await api.post('user/login', values);

      let getToken = JSON.stringify(response.headers.authorization);
      localStorage.setItem('@user', JSON.parse(getToken));
      setToken(getToken)

      let getRefresh = JSON.stringify(response.headers['refresh-token']);
      localStorage.setItem('@refreshToken', JSON.parse(getRefresh));
      setRefresh(getRefresh)

      let getUserStatus = JSON.stringify(response.headers['user']);
      localStorage.setItem('@status', JSON.parse(getUserStatus));
      setUserStatus(getUserStatus)

      const userStatusInHeaders = response.headers['user'];

      if (userStatusInHeaders && userStatusInHeaders === 'INATIVO') {

        setIsLoadingBtn(false)

        if (selectedPlan) {
          props.router.navigate('/purchase-plan');
        }

        else {
          props.router.navigate('/plans');
        }

      } else {
        setIsLoadingBtn(false)
        props.router.navigate('');
      }

    } catch (error) {

      setIsLoadingBtn(false)
      if (error.response && error.response.status) {
        switch (error.response.status) {
          case 400:
            setError("Sua requisição é inválida.");
            break;
          case 401:
            setError("Senha incorreta ou este não é seu e-mail cadastrado!");
            break;
          case 403:
            setError("Você não tem permissão para acessar");
            break;
          case 404:
            setError("Erro 404: Recurso não encontrado.");
            break;
          case 500:
            setError("Erro interno do servidor.");
            break;
          default:
            setError(`Contate o suporte. Erro desconhecido: ${error.response.status}`);
        }
      } else {
        setError("Contate o suporte. Erro desconhecido.");
      }
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Insira seu e-mail")
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Insira um endereço de e-mail válido"
        ),
      password: Yup.string()
        .required("Insira sua senha: sua senha contém no mínimo 8 caracteres e pelo menos uma letra maiúscula, uma minúscula e um número.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!])(?!.*\s).{8,}$/,
          "A senha deve conter no mínimo 8 caracteres, pelo menos uma letra maiúscula, uma minúscula, um caractere especial e um número."
        )
        .test("no-leading-trailing-spaces", "A senha não pode conter espaços no início ou no final", (value) => {
          // Verifica se a senha não contém espaços no início ou no final
          return !/^\s|\s$/.test(value);
        }),
    }),
    onSubmit: (values) => {
      const sendValues = {
        email: values.email.trim(),
        password: values.password.trim(),
      }
      userLogin(sendValues)
    }
  });

  useEffect(() => {
    const updatedStyle = {
      zIndex: 999,
      position: "absolute",
      top: 0,
      right: validation.touched.password && validation.errors.password ? 20 : 0,
      padding: "10px",
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
    };

    setButtonStyle(updatedStyle);
  }, [validation.touched.password, validation.errors.password]);

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Olá Investidor!</h5>
                        <p>Faça Login ou <Link to="/register" className="fw-medium text-primary">
                          {" "}
                          Cadastre-se{" "}
                        </Link>{" "} para acessar a Evolue.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="avatar-md profile-user-wid mb-4">
                      {/* <span className="avatar-title rounded-circle bg-light"> */}
                      <img
                        src={logoImg}
                        alt=""
                        className="avatar-title rounded-circle"
                        height="34"
                      />
                      {/* </span> */}
                    </div>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Insira seu e-mail"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <div className="float-end">
                          <Link to="/password-recover" className="text-muted">Esqueceu sua senha?</Link>
                        </div>
                        <Label className="form-label">Senha</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="password"
                            /* required                            
                            title="Sua senha contém no mínimo 8 caracteres e pelo menos uma letra maiúscula, uma minúscula e um número." */
                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                            value={validation.values.password || ""}
                            type={passwordShow ? "text" : "password"}
                            placeholder="Insira sua senha"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                          />
                          <button
                            onClick={() => setPasswordShow(!passwordShow)}
                            style={buttonStyle}
                            className="btn"
                            type="button"
                          >
                            <i id="eyeIcon" className={`fa fa-eye ${passwordShow ? "" : "fa-eye-slash"}`}></i>
                          </button>

                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Lembrar minha conta
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className={`btn btn-primary btn-block ${isLoadingBtn ? 'loadingBtn' : ''}`}
                          type="submit"
                        >
                          {isLoadingBtn ? 'Acessando' : 'Acessar'}
                        </button>
                      </div>

                      {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Acessar com</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-primary text-white border-primary"
                              onClick={e => {
                                e.preventDefault();
                                socialResponse("facebook");
                              }}
                            >
                              <i className="mdi mdi-facebook" />
                            </Link>
                          </li>
                          <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-danger text-white border-danger"
                              onClick={e => {
                                e.preventDefault();
                                socialResponse("google");
                              }}
                            >
                              <i className="mdi mdi-google" />
                            </Link>
                          </li>
                        </ul>
                      </div> */}

                      <div className="mt-4 text-center">
                        <Link to="/password-recover" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Esqueceu sua senha?
                        </Link>
                      </div>
                      <div className="mt-1 text-center">
                        <p>
                          Não tem uma conta?{" "}
                          <Link to="/register" className="fw-medium text-primary">
                            {" "}
                            Cadastre-se agora{" "}
                          </Link>{" "}
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  Não tem uma conta?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Cadastre-se agora{" "}
                  </Link>{" "}
                </p> */}
                <p>
                  © {new Date().getFullYear()} Evolue Co.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};

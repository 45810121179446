import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { findIndex, isEmpty } from "lodash";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import withRouter from "components/Common/withRouter";
import "assets/scss/datatables.scss";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { getWallet as onGetWallet } from "store/actions";
import WalletActivities from "./WalletWallet/walletActivities";
import WalletStats from "./WalletWallet/walletStats";
import WalletOverView from "./WalletWallet/walletOverView";

//redux & context
import { WalletContext } from "contexts/wallet-context/wallet-context";
import { useSelector, useDispatch } from "react-redux";
import { formatCurrency } from "helpers/formatCurrency";

const Wallet = () => {

  //meta title
  document.title = "Wallet ";

  const {
    acoes, fiis,
    getAcoesTotalDividend, getFiisTotalDividend, fiisDividendTotal, acoesDividendTotal
  } = useContext(WalletContext);

  const [totalInvestido, setTotalInvestido] = useState(null)
  const [consolidado, setConsolidado] = useState(null)
  const [isMenu, setIsMenu] = useState(false);
  const [totalProventosPagos, setTotalProventosPagos] = useState(0);

  useEffect(() => {
    if (acoes && fiis) {
      const totalAcoes = acoes.totalPrecoMedio ?? 0;
      const totalFiis = fiis.totalPrecoMedio ?? 0;
      const cotacaoAtualAcoes = acoes.totalCotacaoAtual ?? 0
      const cotacaoAtualFiis = fiis.totalCotacaoAtual ?? 0
      setTotalInvestido(Number(totalAcoes) + Number(totalFiis));
      setConsolidado(Number(cotacaoAtualAcoes) + Number(cotacaoAtualFiis));
    }
  }, [acoes, fiis]);

  useEffect(() => {
    getAcoesTotalDividend();
    getFiisTotalDividend();
  }, [])

  useEffect(() => {
    if (fiisDividendTotal && acoesDividendTotal) {
      setTotalProventosPagos(Number(fiisDividendTotal.valor_total_dividendos_pagos) + Number(acoesDividendTotal.valor_total_dividendos_pagos))
    }
  }, [fiisDividendTotal, acoesDividendTotal])

  const calculatePercentageDifference = (num1, num2) => {
    if (num1 === 0 || num2 === 0) return '0.00 %';

    const difference = ((num2 - num1) / num1) * 100;
    const sign = difference >= 0 ? '+' : '-';
    const formattedDifference = Math.abs(difference).toFixed(2);

    return `${sign} ${formattedDifference} %`;
  };

  const differencePercentage = calculatePercentageDifference(totalInvestido, consolidado);
  const badgeClass = differencePercentage.includes('-') ? 'badge bg-danger mb-2' : 'badge bg-success mb-2';

  const toggleMenu = () => {
    setIsMenu(!isMenu);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs title="Wallet" breadcrumbItem="Wallet" /> */}
          <Row>
            {/* <Col xl="4">
                <WalletStats
                  wallet={wallet}
                  isMenu={isMenu}
                  toggleMenu={toggleMenu}
                />
              </Col> */}
            {/* <Col xl="8"> */}
            <Col xl="12">
              <Row>
                <Col sm="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="me-3 align-self-center">
                          <i className="mdi mdi-chart-donut h2 text-warning mb-0" />
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-2">Total Investido</p>
                          <h5 className="mb-0">
                            {(totalInvestido
                              ? formatCurrency(
                                (totalInvestido), 'pt-BR', 'BRL') : 'R$ 0,00')}
                          </h5>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="me-3 align-self-center">
                          <i className="mdi mdi-distribute-horizontal-center h2 text-primary mb-0" />
                        </div>
                        <div className="flex-grow-1">
                          <div className="d-flex">
                            <p className="text-muted mb-2">Consolidado</p>
                            {totalInvestido && consolidado && <span className={`badge ${badgeClass} ms-2`}>
                              {differencePercentage}
                            </span>}
                          </div>

                          <h5 className="mb-0">
                            {(consolidado
                              ? formatCurrency(
                                (consolidado), 'pt-BR', 'BRL') : 'R$ 0,00')}
                          </h5>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="me-3 align-self-center">
                          <i className="mdi mdi-currency-usd-circle-outline h2 text-info mb-0" />
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-2">Proventos Recebidos</p>
                          <h5 className="mb-0">
                            {(fiisDividendTotal && acoesDividendTotal ?
                              formatCurrency(
                                (Number(totalProventosPagos)), 'pt-BR', 'BRL')
                              : 'R$ 0,00')
                            }
                          </h5>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* <WalletOverView wallet={wallet} /> */}
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <WalletActivities />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Wallet.propTypes = {
  wallet: PropTypes.any,
  onGetWallet: PropTypes.func,
};

export default withRouter(Wallet);

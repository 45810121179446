import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardBody,
  Table,
  Badge,
} from "reactstrap";
//Simple bar
import SimpleBar from "simplebar-react"
import { Link, useParams } from "react-router-dom";
import { formatCurrency } from "helpers/formatCurrency";
import { AssetsContext } from "contexts/assets-context/assets-context";
import { calculateSumByYear, groupDividendsByYear } from "../Helper/helperProventos";

const ProventosAnuais = () => {

  const {
    proventos,
  } = useContext(AssetsContext);

  const [sumByYear, setSumByYear] = useState(null);


  useEffect(() => {
    const calculateAndSetSumByYear = () => {
      const groupedByYear = groupDividendsByYear(proventos);
      const calculatedSumByYear = calculateSumByYear(groupedByYear);
      setSumByYear(calculatedSumByYear);
    };
    calculateAndSetSumByYear();
  }, [proventos]);

  const sortedSumByYear = sumByYear && sumByYear.slice().sort((a, b) => parseInt(b.year) - parseInt(a.year));

  return (
    <React.Fragment>
            <h4 className="card-title mb-4">Proventos Acumulados</h4>
            <SimpleBar style={{ maxHeight: "55%", minHeight: "55%" }}>
              <div className="table-responsive">
                <Table className="table table-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      <th scope="col">{' '}</th>
                      <th scope="col">Ano</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedSumByYear && sortedSumByYear.map((provento, key) => (
                      <tr key={key}>

                        <td>
                          <div className="font-size-22 text-success">
                            <i className="bx bx-up-arrow-circle" />
                          </div>
                        </td>

                        <td>
                          <div>
                            {provento.year}
                          </div>
                        </td>
                        <td>
                          <div className="font-size-14 mb-0">
                            {formatCurrency(provento.totalSum, 'pt-BR', 'BRL', 4, 4)}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </SimpleBar>
    </React.Fragment>
  );
}

export default ProventosAnuais;
import React, { useContext, useEffect, useState } from "react";
import {
    Col,
    Container,
    PaginationItem,
    PaginationLink,
    Row,
    Card,
    CardBody,
    Form
} from "reactstrap";

import withRouter from "components/Common/withRouter";
import { map } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import Cards
import CardProject from "./card-project";

import { getProjects as onGetProjects } from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { RankingContext } from "contexts/ranking-context/ranking-context";

const Relatorio = props => {

    //meta title
    document.title = "Projects Grid | Skote - React Admin & Dashboard Template";

    const { ranking, getRankingByAssetType } = useContext(RankingContext)

    const [tipoAtivo, setTipoAtivo] = useState('ACAO')

    useEffect(() => {
        //    if (!ranking) {
        getRankingByAssetType(tipoAtivo);
        //    }
    }, [tipoAtivo]);

    function generateProjects(data) {
        if (!data) {
            return [];
        }

        const projectsWithGoodRating = data.ranking.filter(rankingItem => rankingItem.avaliacao_ranking === 'bom');

        const projects = projectsWithGoodRating.map(rankingItem => {
            const referenciaField = rankingItem.item_referencia;
            const tipoReferencia = rankingItem.tipo_referencia;

            return {
                id: rankingItem.item_detalhamento,
                name: rankingItem.nome,
                tab_total: rankingItem.nome_referencia,
                avaliacao_ranking: rankingItem.avaliacao_ranking,
                description: '',
                item_referencia: referenciaField,
                data: rankingItem.ativos.map(item => ({
                    image: item.image,
                    title: item.symbol,
                    price: item.cotacao,
                    tipo_referencia: tipoReferencia,
                    referencia: item[referenciaField]
                }))
            };
        });

        return projects;
    }

    const projects = generateProjects(ranking);



    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [totalPage] = useState(5);

    useEffect(() => {
        dispatch(onGetProjects());
    }, [dispatch]);

    const handlePageClick = page => {
        setPage(page);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Rankings" breadcrumbItem="Todos os Rankings" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Form>
                                        <Row className="d-flex justify-content-end">
                                            <Col lg={8}>
                                                <div className="position-relative h-100 hstack gap-3">
                                                    <div
                                                        onClick={() => { setTipoAtivo('ACAO') }}
                                                        className={`btn btn-${tipoAtivo === 'ACAO' ? 'primary' : 'secondary'} h-100 w-100`}
                                                    >
                                                        <i className="bx bx-filter-alt align-middle"></i>{' '}
                                                        Ações
                                                    </div>
                                                    <div
                                                        onClick={() => { setTipoAtivo('FII') }}
                                                        className={`btn btn-${tipoAtivo === 'FII' ? 'primary' : 'secondary'} h-100 w-100`}
                                                    >
                                                        <i className="bx bx-filter-alt align-middle"></i>{' '}
                                                        Fundos Imobiliários
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        {/* Import Cards */}
                        <CardProject projects={projects} />
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(Relatorio);

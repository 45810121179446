import React, { useEffect } from 'react';

function Discord() {
  useEffect(() => {
    // Crie um elemento de script
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/@widgetbot/crate@3';
    script.async = true;
    script.defer = true;

    // Configure o Crate passando as opções
    script.innerHTML = `
      new Crate({
        server: '1164600640035631154',
        channel: '1164982907367079936'
      });
    `;

    // Adicione o script ao corpo do documento
    document.body.appendChild(script);

    // Certifique-se de remover o script quando o componente for desmontado
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* Conteúdo do widget será renderizado aqui */}
    </div>
  );
}

export default Discord;

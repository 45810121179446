import React, { createContext, useContext, useState } from 'react';
import api from '../../services/api';
import toast from "helpers/toast";

export const TaxesContext = createContext();

const TaxesProvider = ({ children }) => {

    const [pendences, setPendences] = useState(null);
    const [aliquota, setAliquota] = useState(null);
    const [heroInformation, setHeroInformation] = useState({
        totalImposto: 0,
        totalLucro: 0,
    })

    const getPendences = async (assetType) => {
        try {
            const response = await api.get(`/imposto?tipo_operacao=${assetType}`);

            if (response.data === null) { return }

            setPendences(response.data);

        } catch (err) {
            switch (err.response ? err.response.status : null) {
                case 400:
                    console.error('Erro 400 - Requisição inválida');
                    break;
                case 401:
                    console.error('Erro 401 - Não autorizado');
                    break;
                case 402:
                    console.error('Erro 402 - Pagamento necessário');
                    break;
                default:
                    console.error('Erro desconhecido: ' + err);
                    break;
            }
        }
    };

    const putTax = async (id) => {
        try {
            await api.put(`/imposto`, id);
            toast.success('Imposto registrado como pago. Não esqueça de efetivamente pagar sua Darf')

        } catch (error) {
            toast.fail('Erro ao atualizar imposto' + error)
        }
    };

    const getAliquota = async (operacao, modalidade) => {

        try {
            const response = await api.get(`/imposto/percentual?operacao=${operacao}&tipoOperacao=${modalidade}`);
            setAliquota(response.data);
        } catch (error) {
            switch (err.response ? err.response.status : null) {
                case 400:
                    console.error('Erro 400 - Requisição inválida');
                    break;
                case 401:
                    console.error('Erro 401 - Não autorizado');
                    break;
                case 402:
                    console.error('Erro 402 - Pagamento necessário');
                    //setUserStatus('INATIVO')
                    break;
                default:
                    console.error('Erro desconhecido: ' + err);
                    break;
            }
        }
    }

    return (
        <TaxesContext.Provider value={{
            pendences,
            putTax,
            getPendences,
            getAliquota,
            aliquota, 
            setAliquota,
            heroInformation, setHeroInformation
        }}>
            {children}
        </TaxesContext.Provider>
    );
};

export default TaxesProvider;
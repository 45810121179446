import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { map } from "lodash";
import {
  Card,
  CardBody,
  Col,
  Table,
  Tooltip
} from "reactstrap";

import "./card-custom.css"
import { formatCurrency } from "helpers/formatCurrency";
import { AssetsContext } from "contexts/assets-context/assets-context";

const CardProject = ({ projects }) => {

  const {
    setTickerType,
    setSelectedTicker,
    setActiveTab,
    reference, setReference
  } = useContext(AssetsContext)

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const standardImage = "https://s3-symbol-logo.tradingview.com/b3-on-nm--600.png"

  const mockParams = {
    country: "br",
    exchange: "bovespa",
    classe: "acoes",
    route: "assets"
  }

  return (
    <React.Fragment>
      {map(projects, (project, key) => (
        <Col xl="4" sm="6" key={key}>
          <Card>
            <CardBody>
              <div className="d-flex">


                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="text-truncate font-size-15 text-dark">
                    {project.name}
                  </h5>
                  <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} className="text-muted mb-4">{project.description}</p>


                </div>
              </div>
              <div className="table-responsive">
                <Table className="table table-nowrap table-hover align-middle mb-0 p-0" style={{ userSelect: 'none' }}>
                  <thead>
                    <tr>
                      <th className="text-left p-0 pb-2" scope="col">Papel</th>
                      <th className="custom-text-right p-0 pb-2" scope="col">Preço</th>
                      <th className="custom-text-right p-0 pb-2" scope="col">

                        {project.tab_total} <i id="exampleTooltip" style={{ cursor: 'pointer' }} className="bx bx-help-circle text-primary custom-text-right" />
                        {/* <Tooltip placement="top" isOpen={tooltipOpen} target="exampleTooltip" toggle={toggle}>
                          Este é um exemplo de Tooltip.
                        </Tooltip> */}

                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {project.data.map((asset, key) => (
                      <tr key={key}>
                        <th className="p-0 pt-2 pb-2" scope="row">
                          <div className="d-flex align-items-center">
                            <div className="avatar-xs me-3">
                              <span className="avatar-title rounded-circle bg-light">
                                <img src={`${asset.image ? asset.image : standardImage}`} alt="" className="avatar-title rounded-circle bg-light" />
                              </span>
                            </div>
                            <Link
                              onClick={() => {
                                setActiveTab('Resumo')
                                setSelectedTicker(asset.title.toLowerCase());
                                setTickerType(mockParams.classe);
                              }}
                              to={`/${mockParams.route}/${mockParams.country}/${asset.exchange ? asset.exchange.toLowerCase() : mockParams.exchange}/${mockParams.classe}/${asset.title.toLowerCase()}`}
                              className="text-dark"
                            >
                              <span>{asset.title}</span>
                            </Link>
                          </div>
                        </th>
                        <td className="p-0 pt-2 pb-2">
                          <div className="text-muted custom-text-right">{asset.price && formatCurrency(Number(asset.price), 'pt-BR', 'BRL')}</div>
                        </td>
                        <td className="p-0 pt-2 pb-2">
                          <div className="text-muted custom-text-right">
                            {asset.tipo_referencia === 'monetario'
                              ? formatCurrency(Number(asset.referencia), 'pt-BR', 'BRL')
                              : asset.tipo_referencia === 'percentual'
                                ? Number(asset.referencia).toFixed(2) + ' %'
                                : asset.tipo_referencia === 'indice'
                                  ? Number(asset.referencia).toFixed(2)
                                  : ''}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
            <div className="px-4 py-3 border-top">
              <td style={{ width: "120px" }}>
                <Link
                  to="/ranking/detail"
                  className="btn btn-primary btn-sm w-xs"
                  onClick={() => {
                    setReference(project);
                  }}
                >
                  Ver Todas
                </Link>
              </td>
            </div>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

CardProject.propTypes = {
  projects: PropTypes.array,
};

export default CardProject;

export const groupDividendsByYear = dividends => {
    return dividends.reduce((groupedDividends, dividend) => {
        const year = dividend.data_com.split('-')[2];

        groupedDividends[year] = groupedDividends[year] || [];
        groupedDividends[year].push(dividend);

        return groupedDividends;
    }, {});
};

export const separateDividendsByPayment = groupedByYear => {
    const result = {};

    for (const year in groupedByYear) {
        result[year] = {
            provisionado: {
                JSCP: [],
                DIVIDENDO: [],
                RENDIMENTO: []
            },
            pago: {
                JSCP: [],
                DIVIDENDO: [],
                RENDIMENTO: []
            }
        };

        groupedByYear[year].forEach(dividend => {
            const paymentDateParts = dividend.data_pagamento ? dividend.data_pagamento.split('-') : null;

            const currentDate = new Date();
            const paymentDate = paymentDateParts ? new Date(
                parseInt(paymentDateParts[2]),
                parseInt(paymentDateParts[1]) - 1,
                parseInt(paymentDateParts[0])
            ) : null;

            const paymentCategory = paymentDate === null || currentDate < paymentDate ? 'provisionado' : 'pago';

            if (!result[year][paymentCategory][dividend.tipo_pagamento]) {
                result[year][paymentCategory][dividend.tipo_pagamento] = [];
            }

            result[year][paymentCategory][dividend.tipo_pagamento].push(dividend);
        });
    }

    return result;
};


export const calculateSumByYear = groupedByYear => {
    const sumByYear = [];

    for (const year in groupedByYear) {
        const sum = groupedByYear[year].reduce((acc, dividend) => {
            if (dividend.valorDesdobramentoGrupamento !== null && dividend.valorDesdobramentoGrupamento !== dividend.valor) {
                return acc + dividend.valorDesdobramentoGrupamento;
            } else {
                return acc + dividend.valor;
            }
        }, 0);

        sumByYear.push({
            year: parseInt(year),
            totalSum: sum
        });
    }

    return sumByYear.sort((a, b) => a.year - b.year);
};

const calculateTotalValue = dividends => {
    return dividends.reduce((total, dividend) => {
        if (dividend.valorDesdobramentoGrupamento !== null && dividend.valorDesdobramentoGrupamento !== dividend.valor) {
            return total + dividend.valorDesdobramentoGrupamento;
        } else {
            return total + dividend.valor;
        }
    }, 0);
};

export const organizeDataForChart = separatedByPayment => {
    const chartData = [];

    for (const year in separatedByPayment) {
        const provisionadoJSCP = calculateTotalValue(separatedByPayment[year].provisionado.JSCP);
        const provisionadoDIVIDENDO = calculateTotalValue(separatedByPayment[year].provisionado.DIVIDENDO);
        const provisionadoRENDIMENTO = calculateTotalValue(separatedByPayment[year].provisionado.RENDIMENTO);
        const pagoJSCP = calculateTotalValue(separatedByPayment[year].pago.JSCP);
        const pagoDIVIDENDO = calculateTotalValue(separatedByPayment[year].pago.DIVIDENDO);
        const pagoRENDIMENTO = calculateTotalValue(separatedByPayment[year].pago.RENDIMENTO);

        chartData.push({
            name: year,
            PROVISIONADO: provisionadoJSCP + provisionadoDIVIDENDO + provisionadoRENDIMENTO,
            JSCP: pagoJSCP,
            DIVIDENDO: pagoDIVIDENDO,
            RENDIMENTO: pagoRENDIMENTO
        });
    }

    return chartData;
};
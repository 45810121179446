import React, { useEffect, useContext } from "react";
import CardPricing from "pages/Utility/card-pricing";
import { Container, Row, Col } from "reactstrap";
import { PlanContext } from "contexts/plans-context/plans-context";

const PayWall = ({nivel}) => {

    const { pricings, getPaymentPlan } = useContext(PlanContext);

    useEffect(() => {
        getPaymentPlan()
    }, [])

    const filteredPricings = pricings.filter(pricing =>
        nivel.some(nivelItem => pricing.title.includes(nivelItem))
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <div className="text-center mb-5">
                                <h4>Plano Premium</h4>
                                <p className="text-muted">
                                    Este é um recurso  premium da Evolue. Escolha um plano que contenha este recurso.
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        {filteredPricings.map((item, key) => (
                            <CardPricing item={item} key={"_pricing_" + key} />
                        ))}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default PayWall;
import { formatCurrency } from "helpers/formatCurrency";
import React, { useState, createContext, useEffect } from "react";
import toast from "helpers/toast";
import api from '../../services/api';
import fiiImage from '../../assets/images/fii-image.png'

export const AssetsContext = createContext();

const AssetsProvider = ({ children }) => {

    const [assets, setAssets] = useState(null)
    const [favorites, setFavorites] = useState(null)

    const [activeTab, setActiveTab] = useState("Resumo");

    const [acao, setAcao] = useState(null);
    const [fii, setFii] = useState(null);
    const [cotacaoHistorica, setCotacaoHistorica] = useState(null)
    const [fatosRelevantes, setFatosRelevantes] = useState(null)
    const [dataCom, setDataCom] = useState(null)
    const [stock, setStock] = useState(null);
    const [tickerType, setTickerType] = useState('acoes');
    const [assetFilter, setAssetFilter] = useState('ACAO')

    const [fundamentalData, setFundamentalData] = useState(null);

    const [selectedTicker, setSelectedTicker] = useState('');
    const [extract, setExtract] = useState([]);
    const [proventos, setProventos] = useState([]);
    const [patrimonialBalance, setPatrimonialBalance] = useState(null);
    const [financialResult, setFinancialResult] = useState(null)
    const [cashFlow, setCashFlow] = useState(null)
    const [payout, setPayout] = useState(null)
    const [assetInfo, setAssetInfo] = useState(null)
    const [detailedRanking, setDetailedRanking] = useState(null)
    const [grupamentosDesdobramentos, setGrupamentosDesdobramentos] = useState([])
    const [bonificacoes, setBonificacoes] = useState([])

    const [reference, setReference] = useState(null);

    const mockParams = {
        country: "br",
        exchange: "bovespa",
        classe: "acoes",
        route: "assets"
    }

    const getAcaoByTicker = async () => {
        try {
            const response = await api.get(`/ativos/${`${tickerType}?ticker=${selectedTicker.toUpperCase()}`}`);

            if (response.data === null) {
                return;
            }

            setAcao(response.data);

        } catch (err) {
            toast.fail('erro ao buscar ações: ' + err)
        }
    };

    const getProventosByTicker = async (dataInicial, dataFinal) => {
        try {
            const response = await api.get(`/ativos/dividendos?dataInicial=${dataInicial}&ticker=${selectedTicker.toUpperCase()}`)

            if (response.data === null) { return }

            setProventos(response.data);

        } catch {
            console.error('erro aos buscar açoes' + err)
        }
    }

    const getGrupamentosDesdobramentos = async (dataInicial, dataFinal) => {
        try {
            const response = await api.get(`/ativos/buscar-grupamentos-desdobramentos?ticker=${selectedTicker.toUpperCase()}`)

            if (response.data === null) { return }

            setGrupamentosDesdobramentos(response.data);

        } catch {
            console.error('erro aos buscar açoes' + err)
        }
    }

    const getBonificacoes = async (dataInicial, dataFinal) => {
        try {
            const response = await api.get(`/ativos/buscar-bonificacoes?ticker=${selectedTicker.toUpperCase()}`)

            if (response.data === null) { return }

            setBonificacoes(response.data);

        } catch {
            console.error('erro aos buscar açoes' + err)
        }
    }

    const getCotacaoHistorica = async (dataInicial, dataFinal) => {
        try {
            const response = await api.get(`/ativos/buscar-cotacoes-historicas?dataFinal=${dataFinal}&dataInicial=${dataInicial}&ticker=${selectedTicker.toUpperCase()}`)

            if (response.data === null) { return }

            setCotacaoHistorica(response.data);

        } catch {
            console.error('erro aos buscar açoes' + err)
        }
    }

    const getFatosRelevantes = async (dataInicial, dataFinal) => {
        try {
            const response = await api.get(`/ativos/buscar-fatos-relevantes?dataFinal=${dataFinal}&dataInicial=${dataInicial}&ticker=${selectedTicker.toUpperCase()}`)

            if (response.data === null) { return }

            setFatosRelevantes(response.data);

        } catch {
            console.error('erro aos buscar açoes' + err)
        }
    }

    const getAllAssetsByType = async (order, pagina, param, tipo) => {

        let tickerTypeFilter = tickerType === 'acoes' ? 'ACAO' : tickerType === 'fiis' ? 'FII' : null

        try {
            const response = await api.get(`/ativos/buscar-todos-ticker?ordenacao=${order}&pagina=${pagina}&parametro=${param}&tipoAtivo=${tipo || tickerTypeFilter}`)

            if (response.data === null) { return }

            setAssets(response.data);
        } catch {
            console.error('erro aos buscar açoes' + err)
        }
    }

    const getDetailedRanking = async (tipo, ranking) => {
        try {
            const response = await api.get(`/ativos/detalha-ranking?tipoAtivo=${tipo}&tipoRanking=${ranking.toUpperCase()}`)

            if (response.data === null) { return }

            setDetailedRanking(response.data);
        } catch {
            console.error('erro aos buscar açoes' + err)
        }
    }

    const getAssetInfo = async () => {

        let tickerTypeFilter = tickerType === 'acoes' ? 'ACAO' : tickerType === 'fiis' ? 'FII' : null

        try {
            const response = await api.get(`/ativos/buscar-dados-empresa?ticker=${selectedTicker.toUpperCase()}&tipoAtivo=${tickerTypeFilter}`)

            if (response.data === null) { return }

            setAssetInfo(response.data);
        } catch {
            console.error('erro aos buscar açoes' + err)
        }
    }

    const getDataCom = async (param) => {
        try {
            const response = await api.get(`/ativos/dividendos-data-com?ticker=${selectedTicker.toUpperCase()}&tipo_ativo=${param}`)

            if (response.data === null) { return }

            setDataCom(response.data);
        } catch {
            console.error('erro aos buscar açoes' + err)
        }
    }

    const getPatrimonialBalance = async (dataInicial, dataFinal, tipo) => {
        try {
            const response = await api.get(`/ativos/buscar-balanco-patrimonial?dataFinal=${dataFinal}&dataInicial=${dataInicial}&ticker=${selectedTicker.toUpperCase()}&tipo-balanco=${tipo}`)

            if (response.data === null) { return }

            setPatrimonialBalance(response.data);

        } catch {
            console.error('erro aos buscar açoes' + err)
        }
    }

    const getCashFlow = async (dataInicial, dataFinal, tipo) => {
        try {
            const response = await api.get(`/ativos/buscar-fluxo-caixa?dataFinal=${dataFinal}&dataInicial=${dataInicial}&ticker=${selectedTicker.toUpperCase()}&tipo-balanco=${tipo}`)

            if (response.data === null) { return }

            setCashFlow(response.data);

        } catch {
            console.error('erro aos buscar açoes' + err)
        }
    }

    const getFinancialResult = async (dataInicial, dataFinal, tipo) => {
        try {
            const response = await api.get(`/ativos/buscar-dados-resultado-financeiro?dataFinal=${dataFinal}&dataInicial=${dataInicial}&ticker=${selectedTicker.toUpperCase()}&tipo-balanco=${tipo}`)

            if (response.data === null) { return }

            setFinancialResult(response.data);

        } catch {
            console.error('erro aos buscar açoes' + err)
        }
    }

    const getPayout = async (dataInicial, dataFinal) => {
        try {
            const response = await api.get(`/ativos/buscar-payout?dataFinal=${dataFinal}&dataInicial=${dataInicial}&ticker=${selectedTicker.toUpperCase()}`)

            if (response.data === null) { return }

            setPayout(response.data);

        } catch {
            console.error('erro aos buscar payout' + err)
        }
    }

    const [isFavorite, setIsFavorite] = useState(false)

    const getFavoritesByType = async (order, pagina, param, tipo) => {

        let tickerTypeFilter = tickerType === 'acoes' ? 'ACAO' : tickerType === 'fiis' ? 'FII' : null

        try {
            const response = await api.get(`/favoritos?ordenacao=${order}&pagina=${pagina}&parametro=${param}&tipoAtivo=${tipo || tickerTypeFilter}`);
            setFavorites(response.data);
        } catch {
            console.error('erro ao buscar favoritas' + err);
        }
    }

    const getIsFavorite = async (ticker) => {
        try {

            const response = await api.get(`/favoritos/booleanFavorito?ticker=${ticker.toUpperCase()}`)
            setIsFavorite(response.data)

        } catch (err) {
            console.error('erro ao buscar favorita' + err)
        }
    }

    const postFavorite = async (ticker) => {
        try {
            await api.post(`/favoritos?ticker=${ticker.toUpperCase()}`)
            getIsFavorite(ticker)
        } catch (err) {
            console.error('erro ao cadastrar favorita' + err)
        }
    }

    const deleteFavorite = async (ticker) => {
        try {
            await api.delete(`/favoritos?ticker=${ticker.toUpperCase()}`)
            getIsFavorite(ticker)
        } catch (err) {
            console.error('erro ao deletar favorita' + err)
        }
    }

    return (
        <AssetsContext.Provider value={{
            activeTab, setActiveTab,
            acao,
            setAcao,
            getAcaoByTicker,
            tickerType, setTickerType,
            assetFilter, setAssetFilter,
            selectedTicker, setSelectedTicker,
            getProventosByTicker,
            proventos, setProventos,
            getAllAssetsByType,
            assets, setAssets,
            getCotacaoHistorica,
            cotacaoHistorica,
            fatosRelevantes,
            getFatosRelevantes,
            getDataCom,
            dataCom,
            mockParams,
            getPatrimonialBalance,
            patrimonialBalance,
            getFinancialResult, getCashFlow,
            financialResult, cashFlow,
            getPayout, setPayout, payout,
            assetInfo, setAssetInfo, getAssetInfo,
            detailedRanking, setDetailedRanking, getDetailedRanking,
            reference, setReference,
            grupamentosDesdobramentos, setGrupamentosDesdobramentos, getGrupamentosDesdobramentos,
            bonificacoes, getBonificacoes,
            postFavorite, deleteFavorite, getIsFavorite, isFavorite, favorites, getFavoritesByType
        }}>
            {children}
        </AssetsContext.Provider>
    );
};

export default AssetsProvider;
import React, { useContext, useState, useEffect } from "react";
import { AssetsContext } from "contexts/assets-context/assets-context";
import { SearchContext } from "contexts/search-context/search-context";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from "reactstrap";

const MobileSearchBar = (props) => {
    const { ativos, handleInputAtivosChange } = useContext(SearchContext);
    const {
        setTickerType,
        setSelectedTicker,
        setActiveTab,
        mockParams
    } = useContext(AssetsContext);

    const [isOpenDropdown, setIsOpenDropdown] = useState(false);
    const [search, setSearch] = useState(false);

    const toggleDropdown = () => {
        setIsOpenDropdown(!isOpenDropdown);
    };

    const toggleSearch = () => {
        setSearch(!search);
    };

    const [show, setShow] = useState('');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 992) {
                setShow('d-none')
            } else {
                setShow('')
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <Dropdown isOpen={search} toggle={toggleSearch}>
                <DropdownToggle
                    type="button"
                    tag="button"
                    className={`btn header-item noti-icon ${show}`}
                    id="page-header-search-dropdown"
                >
                    <i className="mdi mdi-magnify" />
                </DropdownToggle>
                <DropdownMenu
                    className={`dropdown-menu-lg dropdown-menu-end p-0 ${search ? "show" : ""
                        }`}
                    aria-labelledby="page-header-search-dropdown"
                >
                    <form className="p-3">
                        <div className="form-group m-0">
                            <div className="position-relative">
                                <div className="search-input-container">
                                    <Input
                                        type="text"
                                        autoComplete="off"
                                        className="form-control"
                                        placeholder={props.t("Search") + "..."}
                                        aria-label="Recipient's username"
                                        onChange={handleInputAtivosChange}
                                        onClick={toggleDropdown}
                                    />
                                    <span
                                        className="bx bx-search-alt search-icon"
                                        style={{
                                            position: "absolute",
                                            zIndex: 10,
                                            fontSize: "16px",
                                            lineHeight: "38px",
                                            right: "13px",
                                            top: 0,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="mt-2" style={{ maxHeight: "350px", overflowY: 'auto' }}>
                                {ativos.length > 0 ? (
                                    ativos.map((item) => (
                                        <div className="mt-2" key={item.symbol}>
                                            <table
                                                style={{ maxWidth: "100%" }}
                                                className="table table-hover table-borderless align-middle m-0 p-0"
                                            >
                                                <tbody>
                                                    <tr className="mt-1">
                                                        <td scope="row" className="m-0 p-0">
                                                            <Link
                                                                onClick={() => {
                                                                    setActiveTab("Resumo");
                                                                    setSelectedTicker(
                                                                        item.symbol.toLowerCase()
                                                                    );
                                                                    setTickerType(
                                                                        item.classe &&
                                                                            item.classe === "ACAO"
                                                                            ? "acoes"
                                                                            : "fiis"
                                                                    );
                                                                    toggleSearch()
                                                                }}
                                                                to={`/${mockParams.route}/${mockParams.country}/${item.exchange ? item.exchange.toLowerCase() : mockParams.exchange}/${item.classe && item.classe === 'ACAO' ? 'acoes' : 'fiis'}/${item.symbol.toLowerCase()}`}
                                                                className="text-dark"
                                                            >
                                                                <div className="d-flex flex-wrap align-items-center">
                                                                    <div className="avatar-xs me-3">
                                                                        <span className="avatar-title rounded-circle bg-light">
                                                                            <img
                                                                                src={`${item.image || null}`}
                                                                                alt=""
                                                                                className="avatar-title rounded-circle bg-light"
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                    <div
                                                                        className="col-6"
                                                                        style={{ maxWidth: "50%" }}
                                                                    >
                                                                        <span className="fw-bold mb-0">
                                                                            {item.symbol}
                                                                        </span>
                                                                        <div
                                                                            className="text-muted"
                                                                            style={{
                                                                                maxWidth: "100%",
                                                                                whiteSpace: "nowrap",
                                                                                overflow: "hidden",
                                                                                textOverflow: "ellipsis",
                                                                            }}
                                                                        >
                                                                            {item.nome_longo &&
                                                                                item.nome_longo.substring(0, 14 - 3) +
                                                                                (item.nome_longo.length >= 14 ? ' ...' : '')
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </td>
                                                        <td className="text-end m-0 p-0">
                                                            <div className="text-muted">
                                                                {item.exchange}
                                                                {": "}
                                                                {item.classe}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    ))
                                ) : (
                                    <div style={{ paddingTop: "10px" }}>
                                        <p className="placeholder-glow">
                                            <span className="placeholder" style={{ width: "25%" }}></span>
                                            <span className="placeholder col-12"></span>
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                </DropdownMenu>
            </Dropdown>
        </>
    );
};

export default MobileSearchBar;

import React, { useState, useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Nav, NavLink, NavItem, Col } from 'reactstrap';

import classnames from "classnames";

import { AssetsContext } from 'contexts/assets-context/assets-context';

//Import Components
import '../../common/Styles/overflow-webkit.css'
import Section from './Section/Section';
import Extract from './Extrato/Extract';
import Proventos from './Proventos/proventos';
import Companhia from './Companhia/companhia';
import Resumo from './Resumo/resumo';
import FundamentalsTab from './Fundamentos/FundamentalsTab';
import Finances from './Finances/Finances';
import { formatCurrency } from 'helpers/formatCurrency';
import CadastroModal from 'components/Modals/cadastro-modal/cadastro-modal';


const AssetOverview = () => {
  

  const { country, exchange, type, slug } = useParams()

  const {
    acao,
    getDataCom,
    getAcaoByTicker,
    tickerType, setTickerType,
    selectedTicker, setSelectedTicker,
    activeTab, setActiveTab
  } = useContext(AssetsContext);

  document.title = `${slug.toUpperCase()} | Evolue`

  useEffect(() => {
    // Verificar se type é diferente de tickerType para evitar chamadas desnecessárias
    if (type !== tickerType) {
      setTickerType(type);
    }

    // Verificar se slug é diferente de selectedTicker para evitar chamadas desnecessárias
    if (slug !== selectedTicker) {
      setSelectedTicker(slug);
    }
  }, [type, tickerType, slug, selectedTicker]);

  useEffect(() => {
    if (type === tickerType && slug === selectedTicker) {
      if (tickerType === 'acoes') {
        getAcaoByTicker()
        getDataCom('ACAO')
      } else if (tickerType === 'fiis') {
        getAcaoByTicker()
        getDataCom('FII')
      }
    }
  }, [tickerType, selectedTicker]);

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const [editValues, setEditValues] = useState(null)
  const [preInsertValues, setPreInsertValues] = useState(null)
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (acao) {
      setPreInsertValues({
        image: acao.image,
        nome: acao.name,
        classe: acao.classe,
        nome_longo: acao.nome_longo,
        nomeCompania: acao.nome_longo,
        symbol: acao.symbol,
        simbolo: acao.symbol,
        stock_exchange: acao.exchange
      })
    }
  }, [acao])


  const [showDiv, setShowDiv] = useState(false);
  const sectionRef = useRef(null);
  const divRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const section = sectionRef.current;
      if (section) {
        const sectionTop = section.getBoundingClientRect().top;

        if (sectionTop <= 0) {
          setShowDiv(true);
        } else {
          setShowDiv(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <React.Fragment>

      <div className="page-content">

        {showDiv && <div ref={divRef} id="topbar-scroll" className='d-flex position-fixed' style={{
          top: '70px',
          maxHeight: '3rem'
        }}>
          <div className="d-flex align-items-center p-3">
            <img
              src={acao && acao.image}
              alt=""
              className="avatar-sm rounded-circle img-thumbnail me-2"
              style={{ maxHeight: '2.5rem', maxWidth: '2.5rem' }}
            />
            <div>
              <p className={`m-0 p-0 font-size-11`}>{acao && acao.symbol}</p>
              <h5 className={`mb-0 font-size-13`}>
                {acao ? (
                  `${formatCurrency(Number(acao.cotacao), 'pt-BR', 'BRL')} `
                ) : (
                  ''
                )}

                {acao && acao.percentual_variacao !== null ? (
                  <span className={`badge badge-soft-${Number(acao.percentual_variacao) > 0 ? 'success' : 'danger'} font-size-10`}>
                    {`${Number(acao.percentual_variacao) > 0 ? '+' : ''} ${acao.percentual_variacao}%`}
                  </span>
                ) : (
                  ''
                )}
              </h5>
            </div>
          </div>
          <Col className='d-flex justify-content-end me-3'>
            <button onClick={() => { toggle(); setEditValues(preInsertValues) }} className="btn btn-primary btn-sm m-2">+ Registro</button>
          </Col>
        </div>}

        <Container fluid>
          <Nav tabs className="navbar-expand-lg flex-nowrap nav-justified custom-scrollbar" style={{
            marginBottom: "15px",
            marginTop: "-12px",
            whiteSpace: "normal",
            overflowX: "auto",
            overflowY: "hidden"
          }}>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "Resumo",
                })}
                onClick={() => {
                  toggleTab("Resumo");
                }}
              >
                <i className="bx bx-clipboard me-2"></i>
                Resumo
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "Empresa",
                })}
                onClick={() => {
                  toggleTab("Empresa");
                }}
              >
                <i className="bx bx-tone me-2"></i>
                Informações
              </NavLink>
            </NavItem>

            {tickerType === 'acoes' &&
              <>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "Fundamentos",
                    })}
                    onClick={() => {
                      toggleTab("Fundamentos");
                    }}
                  >
                    <i className="bx bx-poll me-2"></i>
                    Fundamentos
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "Finanças",
                    })}
                    onClick={() => {
                      toggleTab("Finanças");
                    }}
                  >
                    <i className="bx bx-spreadsheet me-2"></i>
                    Finanças
                  </NavLink>
                </NavItem>
              </>
            }

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "Dividendos",
                })}
                onClick={() => {
                  toggleTab("Dividendos");
                }}
              >
                <i className="bx bx-spreadsheet me-2"></i>
                Dividendos
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "Extrato",
                })}
                onClick={() => {
                  toggleTab("Extrato");
                }}
              >
                <i className="bx bx-tone me-2"></i>
                Extrato
              </NavLink>
            </NavItem>
          </Nav>

          <div ref={sectionRef}>
            <Section />
          </div>

          {activeTab === "Resumo" && <Resumo />}

          {activeTab === "Empresa" && <Companhia />}

          {activeTab === "Fundamentos" && <FundamentalsTab />}

          {activeTab === "Finanças" && <Finances />}

          {activeTab === "Dividendos" && <Proventos />}

          {activeTab === "Extrato" && <Extract />}

        </Container>

      </div>
      <CadastroModal isOpen={isOpen} toggle={toggle} editValues={editValues} setEditValues={setEditValues} />
    </React.Fragment>
  );
}

export default AssetOverview;
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

toastr.options = {
  positionClass: 'toast-top-center',
  closeButton: true,
};

function toast(message, type) {
  toastr[type](message);
}

// Função para exibir uma notificação de sucesso
toast.success = (message) => {
  toast(message, 'success');
};

// Função para exibir uma notificação de aviso
toast.warning = (message) => {
  toast(message, 'warning');
};

// Função para exibir uma notificação de falha
toast.fail = (message) => {
  toast(message, 'error');
};

export default toast;

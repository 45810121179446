import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Table,
} from "reactstrap";
//Simple bar
import SimpleBar from "simplebar-react";
import { formatCurrency } from "helpers/formatCurrency";
import { AssetsContext } from "contexts/assets-context/assets-context";
import { formatDataBarra, handleBrazilStringDate } from "helpers/formatDateTime";

const GrupamentosDesdobramentos = () => {

  const {
    grupamentosDesdobramentos, getGrupamentosDesdobramentos, selectedTicker
  } = useContext(AssetsContext);

  useEffect(() => {
    getGrupamentosDesdobramentos();
  }, [selectedTicker])

  return (
    <React.Fragment>
      <h4 className="card-title mb-4">Grupamentos e Desdobramentos</h4>
      <SimpleBar style={{ maxHeight: "150px", minHeight: "150px" }}>
        {grupamentosDesdobramentos && grupamentosDesdobramentos.length > 0 ?
          <div className="table-responsive">
            <Table className="table table-nowrap align-middle mb-0">
              <thead>
                <tr>
                  <th scope="col">Tipo</th>
                  <th scope="col">Anúncio</th>
                  <th scope="col">Data Com</th>
                  <th scope="col">Fator</th>
                </tr>
              </thead>
              <tbody>
                {grupamentosDesdobramentos.map((registro, key) => (
                  <tr key={key}>

                    <td>
                      <div className="text-success">
                        {registro.tipo}
                      </div>
                    </td>

                    <td>
                      <div>
                        {registro.data_aprovacao ? formatDataBarra(handleBrazilStringDate(registro.data_aprovacao)) : '--'}
                      </div>
                    </td>

                    <td>
                      <div>
                        {registro.data_inicio ? formatDataBarra(handleBrazilStringDate(registro.data_inicio)): '--'}
                      </div>
                    </td>

                    <td>
                      <div className="font-size-14 mb-0">
                        {registro.rateio ? registro.rateio.replace(':', ' para ') : '--'}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          :
          <React.Fragment>
            <div className="text-center">
              <Col lg="12">
                <p className="mt-5">Não há registros à exibir.</p>
              </Col>
            </div>
          </React.Fragment>
        }
      </SimpleBar>
    </React.Fragment>
  );
}

export default GrupamentosDesdobramentos;
export const formatData = (date) => {

    const padraoDataHora = /\b(\d{2}-\d{2}-\d{4})\s(\d{2}:\d{2}:\d{2})\b/;
    const resultado = date.match(padraoDataHora);

    if (resultado) {
        const data = resultado[1].replace(/(\d{2})-(\d{2})-(\d{4})/, "$1/$2/$3");
        const hora = resultado[2];
        return { data, hora };
    } else {
        return null;
    }
}

export function formatISODateTime(dateTimeString) {
    const dateObj = new Date(dateTimeString);

    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();

    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const seconds = String(dateObj.getSeconds()).padStart(2, '0');

    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
}

export const handleISODateString = (dateValue) => {
    const dataOriginal = dateValue;
    const partesData = dataOriginal.split('-');
    return `${partesData[2]}-${partesData[1]}-${partesData[0]}`;
}

export function formatDataYYYYMMDD(data) {
    if (data === null) {
        return "Data Indefinida";
    }

    const partesDaData = data.split("-");
    const ano = parseInt(partesDaData[2], 10);
    const mes = parseInt(partesDaData[1], 10) - 1; // Subtraindo 1 porque os meses começam de 0
    const dia = parseInt(partesDaData[0], 10);

    const formattedData = new Date(ano, mes, dia);
    return formattedData;
}

export const formatDataBarra = (data) => {
    if (data === null) {
        return "Data Indefinida";
    }
    const partes = data.split("-");
    return partes[0] + '.' + partes[1] + '.' + partes[2];
};

export const handleBrazilStringDate = (dateValue) => {
    const dataOriginal = dateValue;
    const partesData = dataOriginal.split('-');
    return `${partesData[2]}-${partesData[1]}-${partesData[0]}`;
}

export function separarDataEHora(registro) {
    const regex = /^(\d{2}-\d{2}-\d{4}) (\d{2}:\d{2}:\d{2})$/;
    const matches = registro.match(regex);

    if (matches) {
        const data = matches[1];
        const hora = matches[2];
        return { data, hora };
    } else {
        throw new Error("Formato de registro inválido.");
    }
}
import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import store from "./store";
import UserProvider from "contexts/user-context/user-context";
import WalletProvider from "contexts/wallet-context/wallet-context";
import OptionProvider from "contexts/options-context/options-context";
import AssetsProvider from "contexts/assets-context/assets-context";
import TaxesProvider from "contexts/imposto-context/imposto-context";
import LocaleProvider from "contexts/locales-context/locales-context";
import SearchProvider from "contexts/search-context/search-context";
import AdminProvider from "contexts/admin-context/admin-context";
import SidebarProvider from "contexts/sidebar-context/sidebar-context";
import RankingProvider from "contexts/ranking-context/ranking-context";
import PaginationProvider from "contexts/pagination-context/pagination-context";
import LayoutProvider from "contexts/layout-context/layout-contex";
import AuthProvider from "contexts/auth-context/auth-context";
import PlanProvider from "contexts/plans-context/plans-context";

const contexts = [
  { ContextProvider: BrowserRouter, props: {} },
  { ContextProvider: UserProvider, props: {} },
  { ContextProvider: AuthProvider, props: {} },
  { ContextProvider: WalletProvider, props: {} },
  { ContextProvider: OptionProvider, props: {} },
  { ContextProvider: AssetsProvider, props: {} },
  { ContextProvider: TaxesProvider, props: {} },
  { ContextProvider: LocaleProvider, props: {} },
  { ContextProvider: SearchProvider, props: {} },
  { ContextProvider: AdminProvider, props: {} },
  { ContextProvider: SidebarProvider, props: {} },
  { ContextProvider: RankingProvider, props: {} },
  { ContextProvider: PaginationProvider, props: {} },
  { ContextProvider: LayoutProvider, props: {} },
  { ContextProvider: PlanProvider, props: {} },
];

const ContextProviders = ({ children }) => {
  return contexts.reduceRight((acc, { ContextProvider, props }) => {
    return (
      <ContextProvider {...props}>
        {acc}
      </ContextProvider>
    );
  }, children);
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <Provider store={store}>
    <React.Fragment>
      <ContextProviders>
        <App />
      </ContextProviders>
    </React.Fragment>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
